import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import Tag from '../Tag/Tag';

type SpacingType = string | boolean | object | object[];

interface InnerProps {
    tag?: string;
    widths?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    padding?: SpacingType;
    margin?: SpacingType;
    style?: object;
    className?: string;
    children?: ReactNode | JSX.Element | JSX.Element[] | string | string[];
}

const Inner: FC<InnerProps> = (props) => {
    const { tag = 'div', className, widths, children, ...attributes } = props;

    const classes = ['fds-layout-grid__inner'];

    const InnerClassName = clsx(className, classes);

    return (
        <Tag tag={tag} {...attributes} className={InnerClassName}>
            {children}
        </Tag>
    );
};

export default Inner;
