import React, { FC, Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Tag, Grid, Inner, Cell, Button, Row, Col, Container } from '../';

import MobileNavBar from '../Navigation/MobileNavBar';

import { getWindowDimensions, isDefined } from '../../utils/Helpers';
import { useNavigate } from 'react-router-dom';

const isCurrentUrl =
    typeof window !== 'undefined' && isDefined(window.location) ? window.location.pathname : null;

interface NavItem {
    href?: string;
    label: string;
    aria?: string;
    id?: string;
}

type HideType = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

interface NavBarProps {
    tag?: string;
    className?: string;
    hide?: HideType[];
    navItems: NavItem[];
    transparent?: boolean;
}

const NavBar: FC<NavBarProps> = (props) => {
    const { tag = 'header', transparent = false, navItems, className, ...attributes } = props;
    const navigate = useNavigate();

    const [windowWidth, setWindowWidth] = useState(getWindowDimensions().width);
    const [menuOpened, setMenuOpened] = useState(false);

    const classes = [
        'om-fmc__navbar',
        { 'om-fmc__navbar--transparent': transparent },
        { 'w-100': transparent },
        { 'om-fmc__navbar--mobile-transparent': transparent },
        { 'om-fmc__navbar--mobile nav-background-color': windowWidth < 1024 },
        { opened: menuOpened },
    ];

    const NavBarClassName = clsx(classes, className);

    useEffect(() => {
        const handleResize = function handleResize() {
            const { width } = getWindowDimensions();
            setWindowWidth(width);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [windowWidth]);

    const onNavhandle = (e, href) => {
        e.preventDefault();
        e.stopPropagation();
        navigate(href);
    }

    return (
        <Fragment>
            <Tag tag={tag} className={NavBarClassName} {...attributes}>
                {windowWidth >= 1024 ? (
                    <Grid tag={'nav'}>
                        <Inner>
                            <Cell size={12}>
                                <Container>
                                    <Row justify={'center'}>
                                        <Col xs={12} lg={12} xl={10} padding={{ dir: 'y', amt: 1 }}>
                                            <Row>
                                                <Col
                                                    xs={2}
                                                    lg={2}
                                                    xl={2}
                                                    padding={[
                                                        { dir: 'l', amt: 0 },
                                                        { dir: 'r', amt: 2 },
                                                    ]}
                                                >
                                                    <Tag
                                                        tag={'a'}
                                                        href={'/'}
                                                        aria-label={'Link to Home Page'}
                                                    >
                                                        <Tag
                                                            tag={'img'}
                                                            className={'fds-img'}
                                                            src={'/assets/images/ford_cvc-logo.png'}
                                                            alt={'Logo'}
                                                        />
                                                    </Tag>
                                                </Col>
                                                <Col xs={'auto'}>
                                                    <Row style={{ height: '100%' }}>
                                                        {navItems.map(({ label, href, aria, id }) => {
                                                            const classes = [
                                                                'w-100 fds-align--center',
                                                                {
                                                                    'fmc-button--active':
                                                                        href === isCurrentUrl,
                                                                },
                                                            ];
                                                            return (
                                                                <Fragment key={Math.random()}>
                                                                    <Col
                                                                        xs={'variable'}
                                                                        className={
                                                                            'h-100 fds-flex--center fds-flex-direction--column'
                                                                        }
                                                                        margin={{
                                                                            dir: 'x',
                                                                            amt: 1,
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            tag={'a'}
                                                                            href={href}
                                                                            buttonClassName={clsx(
                                                                                classes,
                                                                            )}
                                                                            textButton
                                                                            ariaLabel={aria}
                                                                            onClick={e => onNavhandle(e, href)}
                                                                        >
                                                                            {label}
                                                                        </Button>
                                                                    </Col>
                                                                </Fragment>
                                                            );
                                                        })}
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Container>
                            </Cell>
                        </Inner>
                    </Grid>
                ) : (
                    <MobileNavBar
                        navItems={navItems}
                        menuOpened={menuOpened}
                        setMenuOpenCallBack={(toggle) => {
                            setMenuOpened(toggle);
                        }}
                    />
                )}
            </Tag>
        </Fragment>
    );
};

export default NavBar;
