
const handleFetchError = (error) => {
    console.error(error);
    return error;
}

const handleFetchResponse = (response) => {
    switch (response.status) {
        case 200:
            return response?.json();
        default:
            return {};
    }
}

export const objToQueryString = (obj: any, excludeNull = true) => {
    return Object.keys(obj)
        ?.filter(key => excludeNull ? !!obj?.[key] : true)
        ?.map((key: string) => `${key}=${obj?.[key]}`)
        ?.join('&')
}

export { handleFetchError, handleFetchResponse };