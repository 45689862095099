export const classes = {
    COMPONENT: 'js-fmc-tabs',
    HORIZONTAL: 'fmc-tabs--horizontal',
    VERTICAL: 'fmc-tabs--vertical',
    TAB_LIST_WRAPPER: 'fmc-tabs__tablist-wrapper',
    TAB_LIST: 'fmc-tabs__tablist',
    TAB: 'fmc-tabs__tab',
    TAB_BORDER: 'fmc-tabs__tab-border',
    TAB_PANELS: 'fmc-tabs__tabpanels',
    TAB_PANEL: 'fmc-tabs__tabpanel',
    HOVER: 'fmc-tabs__tab--hover',
    ACTIVE: 'fmc-tabs__tab--active',
    ACTIVE_PANEL: 'fmc-tabs__tabpanel--active',
    FOCUSED_PANEL: 'fmc-tabs__tabpanel--focus',
    FOCUSED: 'fmc-tabs__tab--focused',
};

export const attributes = {
    ARIA_SELECTED: 'aria-selected',
    ARIA_CONTROLS: 'aria-controls',
    TABINDEX: 'tabindex',
};

export const attributeTestValues = {
    TABLIST_ROLE: 'tablist',
    TAB_ROLE: 'tab',
    TABPANEL_ROLE: 'tabpanel',
    PANEL1_ID: 'Panel1',
    PANEL2_ID: 'Panel2',
    TAB_WIDTH: '60px',
};

export const keyCodes = {
    UP: 38,
    DOWN: 40,
    LEFT: 37,
    RIGHT: 39,
    TAB: 9,
    ENTER: 13,
    HOME: 36,
    END: 35,
};

export const customProperties = {
    TABLIST_PADDING_LEFT: 30,
};
