import FDSComponent from '../component/component';

import { classes, attributes, keyCodes } from './fmcTabs.const';

/**
 * Class constructor for FDSTabs FDS component.
 *
 * @param {HTMLElement} element The element that will be bootstrapped.
 */
export default class FMCTabs extends FDSComponent {
    /**
     * Bootstraps all FDSTabs components.
     * @returns {Array} Returns an array of all newly upgraded components.
     */
    static bootstrapComponents() {
        const components = [];

        Array.prototype.forEach.call(
            document.querySelectorAll(`.${classes.COMPONENT}`),
            (element) => {
                if (!FDSComponent.isElementBootstrapped(element)) {
                    components.push(new FMCTabs(element));
                }
            },
        );

        return components;
    }

    init() {
        super.init();

        // Initialize selectors
        this.selectedTabIndex = 0;
        this.tabListWrapperElement = this.fdsElement.querySelector(`.${classes.TAB_LIST_WRAPPER}`);
        this.tabListElement = this.fdsElement.querySelector(`.${classes.TAB_LIST}`);
        this.tabElements = this.fdsElement.querySelectorAll(`.${classes.TAB}`);
        this.tabBorderElement = this.fdsElement.querySelector(`.${classes.TAB_BORDER}`);
        this.tabPanelsElement = this.fdsElement.querySelector(`.${classes.TAB_PANELS}`);
        this.tabPanelElements = this.fdsElement.querySelectorAll(`.${classes.TAB_PANEL}`);

        if (this.tabElements.length > 0) {
            this.horizontal = true;
            this.vertical = this.fdsElement.classList.contains(classes.VERTICAL);

            [...this.tabElements].forEach((tab) => {
                tab.addEventListener(
                    'click',
                    (this.tabClickHandler = (event) => this.onTabClick(event)),
                );
                tab.addEventListener(
                    'keydown',
                    (this.tabKeydownHandler = (event) => this.onTabKeydown(event)),
                );
            });

            [...this.tabPanelElements].forEach((tabPanel) => {
                tabPanel.addEventListener(
                    'blur',
                    (this.tabPanelBlurHandler = (event) => this.onTabPanelBlur(event)),
                );
            });

            this.tabSelectedElement = [...this.tabElements].find(
                (tab) => tab.getAttribute(attributes.ARIA_SELECTED).toLowerCase() === 'true',
            );

            this.activateTab(this.tabSelectedElement);
            document.fonts.ready.then(() => {
                this.setSelectedTabBorder(this.tabSelectedElement);
            });
        }
    }

    findSelectedIndex(event) {
        this.selectedTabIndex = [...this.tabElements].findIndex((tab) => tab === event.target);
    }

    focusTab(tab) {
        tab.focus();
        tab.click();
        tab.scrollIntoView();
    }

    setSelectedTabBorder(tab) {
        const newWidth = `${tab.getBoundingClientRect().width}px`;
        this.tabBorderElementPos = this.tabBorderElement.offsetLeft;
        let newBorderPos;

        if (tab.offsetLeft === 0) {
            newBorderPos = '0px';
        } else {
            newBorderPos = `${this.tabBorderElementPos + tab.offsetLeft}px`;
        }

        this.tabListWrapperElement.style.setProperty(
            '--tab-selected-border-transform',
            `translateX(${newBorderPos})`,
        );
        this.tabListWrapperElement.style.setProperty('--tab-selected-border-width', newWidth);
        this.tabListWrapperElement.style.setProperty('--tab-selected-border-display', 'block');
    }

    activateTab(tab) {
        // Deactivate all other tabs
        this.deactivateTabs();

        // Set the tab as selected
        tab.setAttribute(attributes.ARIA_SELECTED, 'true');
        tab.setAttribute(attributes.TABINDEX, 0);
        tab.classList.add(classes.ACTIVE);

        // Get the value of aria-controls (which is an ID) to set the panel correctly
        const panelControl = tab.getAttribute(attributes.ARIA_CONTROLS);
        this.panelSelectedElement = this.fdsElement.querySelector(`#${panelControl}`);
        this.panelSelectedElement.classList.add(classes.ACTIVE_PANEL);
    }

    deactivateTabs() {
        [...this.tabElements].forEach((tab) => {
            tab.setAttribute(attributes.ARIA_SELECTED, 'false');
            tab.setAttribute(attributes.TABINDEX, -1);
            tab.classList.remove(classes.ACTIVE);
        });
        [...this.tabPanelElements].forEach((tabPanel) => {
            tabPanel.classList.remove(classes.ACTIVE_PANEL);
        });
    }

    onTabClick(event) {
        this.findSelectedIndex(event);
        this.activateTab(event.target, false);
        this.setSelectedTabBorder(event.target);
    }

    onTabPanelBlur(event) {
        event.target.classList.remove(classes.FOCUSED_PANEL);
    }

    onTabKeydown(event) {
        switch (event.keyCode) {
            case keyCodes.END:
                event.preventDefault();
                this.selectedTabIndex = this.tabElements.length - 1;
                break;
            case keyCodes.HOME:
                event.preventDefault();
                this.selectedTabIndex = 0;
                break;
            case keyCodes.RIGHT:
                event.preventDefault();
                if (this.selectedTabIndex === this.tabElements.length - 1) {
                    this.selectedTabIndex = 0;
                } else {
                    this.selectedTabIndex += 1;
                }
                break;
            case keyCodes.LEFT:
                event.preventDefault();
                if (this.selectedTabIndex === 0) {
                    this.selectedTabIndex = this.tabElements.length - 1;
                } else {
                    this.selectedTabIndex -= 1;
                }
                break;
            default:
                return;
        }

        this.focusTab(this.tabElements[this.selectedTabIndex]);
    }
}
