import { defaultDealerSearchProps } from ".";
import { ADD_FILTER, APPLY_FILTERS, CHANGE_SEARCH_PROPS, FETCH_DEALER_BY_CODE_SUCCESS, FETCH_DEALER_BY_NAME_SUCCESS, FETCH_DEALER_DETAILS_SUCCESS, FETCH_DEALER_FILTER_SUCCESS, FETCH_IP_INFO_SUCCESS, PAGE_CHANGE, REMOVE_FILTER, RESET_FILTERS, SET_CURRENT_DEALER } from "./actions";
import { DealerContextState } from "./types";

const dealerReducer = (state: DealerContextState, action: any) => {
    switch (action.type) {
        case FETCH_DEALER_BY_CODE_SUCCESS: 
            return {
                ...state,
                dealer: action?.dealer,
            }
        case FETCH_DEALER_BY_NAME_SUCCESS:
            return {
                ...state,
                dealers: [
                    ...action.data.data,
                ],
                dealersCount: action.data.count,
            };
        case FETCH_DEALER_DETAILS_SUCCESS: 
            return {
                ...state,
                dealers: action?.dealers,
                dealersCount: action?.total,
                currentPage: action?.page,
            }
        case FETCH_DEALER_FILTER_SUCCESS: {
            return {
                ...state,
                dealerFilters: action.dealerFilters,
                dealerFilterCodeMap: action.codeMap,
                dealerFilterDirMap: action.dirMap,
            }
        }
        case PAGE_CHANGE: {
            return {
                ...state,
                currentPage: action.page,
                isPristine: false,
            }
        }
        case CHANGE_SEARCH_PROPS: {
            return {
                ...state,
                searchProps: {
                    ...defaultDealerSearchProps,
                    ...action.searchProps,
                },
            }
        }
        case SET_CURRENT_DEALER: {
            return {
                ...state,
                dealer: action.dealer,
            }
        }
        case APPLY_FILTERS: {
            return {
                ...state,
                filters: action.filters,
            }
        }
        case ADD_FILTER: {
            return {
                ...state,
                filters: [
                    ...state.filters,
                    action.filter
                ]
            }
        }
        case REMOVE_FILTER: {
            return {
                ...state,
                filters: [
                    ...state.filters?.filter(filter => filter !== action.filter),
                ]
            }
        }
        case RESET_FILTERS: {
            return {
                ...state,
                filters: [],
            }
        }
        case FETCH_IP_INFO_SUCCESS: {
            return {
                ...state,
                ipInfo: action.ipInfo,
                timeZone: action.timeZone,
                currentLocation: action.location,
            }
        }
        default:
            return state;
    }
}

export default dealerReducer;