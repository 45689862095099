import React, { Fragment, useContext } from 'react';
import clsx from 'clsx'

import { WindowWidthContext } from '../../contexts/WindowWidthContext';

import {
    Button,
    Tag,
    Row,
    Col,
    Map,
    Marker
} from '../../components/components'
import { capitalize, capitalizeWords, formatPhoneNumber } from '../../components/utils/Helpers';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTE_DEALER_DETAIL_VIEW } from '../../router';
import { addEmitHelper } from 'typescript';

const DealerCard: React.FC = (props: any) => {
    const {
        id,
        name,
        street,
        address,
        city,
        state,
        zipcode,
        phone,
        isOpen,
        openHours,
        distance,
        latitude: lat,
        longitude: lng,
        active,
        onClickHandler,
        tag,
        className,
        onDealerFocusChange,
        customStyle = {},
    } = props

    const { windowWidth, setWindowWidth } = useContext(WindowWidthContext);
    const navigate = useNavigate();
    
    const classes = [
        'fds-dealer-card',
        'show-content',
        windowWidth >= 1024 && { 'fmc-divider--left fmc-divider--primary': active },
    ]
    
    const handleDelaerClick = () => {
        navigate(generatePath(ROUTE_DEALER_DETAIL_VIEW, { dealerId: id }));
    }

    const onDirectionsClick = () => {
        console.log(lat, lng)
        const url = `https://www.google.com/maps?saddr=&daddr=${lat},${lng}`;
        if (lat && lng) window.open(url, '_blank')
    }

    const handleMouseLeave = () => {
        //if (active === true) onDealerFocusChange?.(null);
    }

    const handleMouseOver = () => {
        if (active !== true && onDealerFocusChange) onDealerFocusChange(id);
    }

    const activeLocation: any = {
        lat,
        lng,
        title: name,
        description: `${Math.round(distance)} miles away`
    }

    const openMsg = isOpen ? `Open Today: ${openHours}` : 'Closed Today';
    return (
        <>
        <div onMouseEnter={handleMouseOver}>
            <Row
                style={!active && windowWidth >= 1024 ? { marginLeft: 3,  ...customStyle } : { borderLeftWidth: 3,  ...customStyle }}
                padding={
                    windowWidth >= 1024 && [
                        { dir: 'l', amt: 1 },
                        { dir: 'b', amt: 1 },
                    ]
                }
                margin={
                    windowWidth >= 1024 && [
                        { dir: 'b', amt: 2 },
                    ]
                }
                className={clsx(classes)}
                onClick={onClickHandler}
                { ...{ tag }}
            >
                <Col xs={12}>
                    <Tag tag={'p'} className={'fds-color--primary fmc-type--heading6'}>{capitalizeWords(name)}</Tag>
                </Col>
                {distance !== null && distance !== undefined ? (
                    <Col xs={12} padding={{ dir: 'y', amt: 1 }}>
                        <Tag tag={'span'} className={'fmc-type--content3 fds-color--gray3'}>{Math.round(distance)} miles away</Tag>
                    </Col>
                ) : <></>}
                <Col xs={12}>
                    <Tag tag={'p'} className={'fds-type--body1 fds-color--gray3 larger-font'}>{capitalizeWords(street)}</Tag>
                </Col>
                <Col xs={12}>
                    <Tag tag={'p'} className={'fds-type--body1 fds-color--gray3 larger-font'}>{capitalizeWords(city)}, {state}, {zipcode} </Tag>
                </Col>
                <Col xs={12}>
                    <Tag tag={'a'} className={'fds-type--body1 fds-weight--bold fds-color--gray3 larger-font'} href={`mailto:${phone}`}>{formatPhoneNumber(phone)}</Tag>
                </Col>
                <Col xs={12} padding={{ dir: 'y', amt: 1 }}>
                    <Tag tag={'p'} className={'fds-type--body1 fds-color--primary fds-weight--bold larger-font'}>{openMsg}</Tag><br />
                </Col>
                
                <Col
                    xs={12}
                    lg={7}
                    style={{ minHeight: 500 }}
                    hide={['md', 'lg', 'xl']}
                    className={'fds-m--b-2'}
                >

                    <Map zoom={5} markers={[activeLocation]} activeLocation={activeLocation} activeLocationInfo={activeLocation} />
                </Col>

                <Col xs={12} padding={{ dir: 'b', amt: 2 }}>
                    <Button
                        isOutlined
                        rightIcon
                        largerFont
                        onClick={onDirectionsClick}
                    >
                        Get Directions
                    </Button>
                </Col>
                <Col xs={12}>
                    <Button tag='a' target="_blank" href={generatePath(ROUTE_DEALER_DETAIL_VIEW, { dealerId: id })} rightIcon largerFont noShadow>
                        Dealer Information
                    </Button>
                </Col>
            </Row>
            </div>
        </>
    )
}

export default DealerCard;
