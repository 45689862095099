import React, { useEffect, useMemo } from 'react';
import { Col, Row, Tag, Tabs, Section } from '../../components/components';
import { formatPhoneNumber } from '../../components/utils/Helpers';
import DealerPartDetails from './DealerPartDetails';
import DealerSalesDetails from './DealerSalesDetails';
import DealerServiceDetails from './DealerServiceDetails';
import { _dealerSampleData } from '../DealerView/constant';
import { useNavigate, useParams } from 'react-router-dom';
import { useDealerInfo } from '../../provider/DealerProvider';
import Breadcrumb from '../../components/components/Breadcrumb';
import { ROUTE_DEALERS_STATE_LIST } from '../../router';
import DealerPhotoGallery from './DealerPhotoGallery';

const DealerDetailView: React.FC = (props: any) => {
    const dealer = _dealerSampleData[0];
    const { fetchDealerByCode, dealer: dealerInfo = {} } = useDealerInfo();
    const navigate = useNavigate();

    const { id, name, website, phone, street, city, state, zipcode, openToday, isOpen, openHours, additionalInfoComments, dealerDirectoryImageGalleryVO, ...rest } = dealerInfo as any || {};
    const { galleries = [] } = dealerDirectoryImageGalleryVO || {};

    const { dealerId } = useParams();
    useEffect(() => {
        if(dealerId) {
            fetchDealerByCode(dealerId)
        }
    }, [dealerId]);

    const onBCHandle = () => {
        navigate(ROUTE_DEALERS_STATE_LIST)
    }
    const openMsg = isOpen ? `Open Today: ${openHours}` : 'Closed Today';
    const images = galleries?.map(gallery => ({ data: gallery?.imageBase64, title: gallery?.imageName }));
    return (
        <Section style={{ minHeight: '80vh' }}>
            <Breadcrumb onBCHandle={onBCHandle} title={name} />
            <Row padding={{ dir: 'b', amt: 2 }}>
                <Col xs={12} padding={0}>
                    <Row>
                        <Col xs={12} padding={[{ dir: 't', amt: 1 }, { dir: 'b', amt: 1 }]}>
                            <Tag tag={'h6'} className={'fmc-type--heading3 fds-type--primary'}>
                                {name}
                            </Tag>
                        </Col>
                        <Col xs={12}>
                            <Tag tag={'p'} className={'fds-type--body1 fds-color--gray3'}>
                                {street}
                            </Tag>
                        </Col>
                        <Col xs={12}>
                            <Tag tag={'p'} className={'fds-type--body1 fds-color--gray3'}>
                                {city}, {state}, {zipcode}{' '}
                            </Tag>
                        </Col>
                        <Col xs={12}>
                            <Tag
                                tag={'a'}
                                className={'fds-type--body1 fds-weight--bold fds-color--gray3'}
                                href={`mailto:${phone}`}
                            >
                                {formatPhoneNumber(phone)}
                            </Tag>
                        </Col>
                        {website && (
                            <Col xs={12}>
                                <Tag tag={'a'} className={'fds-type--body1'} href={`//${website}`} target='_blank'>
                                    {website}
                                </Tag>
                            </Col>
                        )}
                        {openToday && (
                            <Col xs={12}>
                                <Tag
                                    tag={'p'}
                                    className={'fds-type--body1 fds-color--primary fds-weight--bold'}
                                >
                                    {openMsg}
                                </Tag>
                                <br />
                            </Col>
                        )}
                        {additionalInfoComments && (
                            <Col xs={12} padding={{dir: 't', amt: 2 }}>
                                <Tag
                                    tag={'p'}
                                    className={'fds-type--body1 fds-color--gray3'}
                                >
                                    <p dangerouslySetInnerHTML={{ __html: additionalInfoComments ?? ''}}></p>
                                </Tag>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
            <Row style={{ flexGrow: 1 }}>
                <Tabs
                    ariaLabel={'Result Detail'}
                    tabs={[
                        {
                            label: 'Service',
                            id: 'CVCConsumerSiteServiceTab',
                            aria: 'CVCConsumerSiteServiceContent',
                        },
                        {
                            label: 'Sales',
                            id: 'CVCConsumerSiteSalesTab',
                            aria: 'CVCConsumerSiteSalesContent',
                        },
                        {
                            label: 'Parts',
                            id: 'CVCConsumerSitePartTab',
                            aria: 'CVCConsumerSitePartContent',
                        },
                    ]}
                >
                    <div id="CVCConsumerSiteServiceTab"><DealerServiceDetails {...dealerInfo} /></div>
                    <div id="CVCConsumerSiteSalesTab"><DealerSalesDetails {...dealerInfo} /></div>
                    <div id="CVCConsumerSitePartTab"><DealerPartDetails {...dealerInfo} /></div>
                </Tabs>
            </Row>
            {galleries?.length > 0 && (
                <Row>
                    <Col xs={12}>
                        <Row>
                            <DealerPhotoGallery images={images} />
                        </Row>
                    </Col>
                </Row>
            )}
        </Section>
    );
};

export default DealerDetailView;