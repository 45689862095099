import React, { FC, ReactNode, Fragment } from 'react';
import clsx from 'clsx';
import { Tag } from '..';
import { Link } from "react-router-dom";

type SpacingType = string | number | boolean | object | object[];

interface ButtonProps {
    tag?: string;
    buttonClassName?: string;
    className?: string;
    href?: string;
    disableHref?: boolean;
    target?: string;
    fabText?: string;
    ariaLabel?: string;
    id?: string;
    style?: object;
    padding?: SpacingType;
    margin?: SpacingType;
    standardButton?: boolean;
    disabled?: boolean;
    fabButton?: boolean;
    textButton?: boolean;
    noShadow?: boolean;
    rightIcon?: boolean | string;
    leftIcon?: boolean | string;
    isOutlined?: boolean;
    dark?: boolean;
    onClick?: any;
    largerFont?: SpacingType;
    children?: ReactNode | JSX.Element | JSX.Element[] | string | string[];
}

type ClassNamesType = string[] | object[];

const Button: FC<ButtonProps> = (props) => {
    const {
        tag = 'button',
        disableHref = false,
        buttonClassName = '',
        className = '',
        textButton = false,
        fabButton = false,
        fabText,
        standardButton = !textButton && !fabButton,
        rightIcon = false,
        leftIcon = false,
        isOutlined = false,
        dark = false,
        noShadow = false,
        href,
        ariaLabel,
        children,
        largerFont,
        ...attributes
    } = props;

    const classes: ClassNamesType = [
        { 'fmc-button': standardButton },
        { 'fmc-fab': fabButton },
        { 'fmc-button--no-shadow': noShadow },
        { 'fmc-text-button': textButton },
        { 'fmc-button--outlined': isOutlined && standardButton },
        {
            'fmc-text-button--chevron-right':
                rightIcon && textButton && typeof rightIcon === 'boolean',
        },
        {
            'fmc-text-button--chevron-left':
                leftIcon && textButton && typeof leftIcon === 'boolean',
        },
        {
            'larger-font': largerFont,
        }
    ];

    const buttonWrapperClasses: ClassNamesType = [
        { 'fds-icon': rightIcon || leftIcon || fabButton },
        { 'fds-icon--offset-right': rightIcon },
        { 'fds-icon--offset-left': leftIcon },
        {
            'fds-font--ford-icons__chevron-right chevron-filled-right':
                rightIcon && standardButton && typeof rightIcon === 'boolean',
        },
        {
            'fds-font--ford-icons__chevron-left chevron-filled-left':
                leftIcon && standardButton && typeof leftIcon === 'boolean',
        },
        {
            'larger-font': largerFont,
        }
    ];

    const ButtonClassName = clsx(classes, buttonClassName);

    const ButtonWrapperClassName = clsx(
        buttonWrapperClasses,
        typeof rightIcon === 'string' ? rightIcon : '',
        typeof leftIcon === 'string' ? leftIcon : '',
    );

    const _ButtonAttributes = Object.assign({}, attributes, dark ? { 'data-dark': true } : {});
    const ButtonAttributes = Object.assign(
        {},
        _ButtonAttributes,
        ariaLabel ? { 'aria-label': ariaLabel } : {},
    );

    const RenderHref = ({ disable = false }) => {
        if (disable) {
            return (
                <a className={ButtonClassName}>
                    {leftIcon && !fabButton && <Tag tag={'span'} className={ButtonWrapperClassName} />}
                    {!fabButton && (
                        <Tag tag={'span'} className={clsx(className)}>
                            {children}
                        </Tag>
                    )}
                    {fabButton && (
                        <Tag tag={'span'} className={ButtonWrapperClassName}>
                            {children}
                            {fabButton && fabText && (
                                <Tag tag={'span'} className={'fmc-fab__text'}>
                                    {fabText}
                                </Tag>
                            )}
                        </Tag>
                    )}
                    {rightIcon && !fabButton && <Tag tag={'span'} className={ButtonWrapperClassName} />}
                </a>
            )
        }
        return (
            <Link to={href} className={ButtonClassName} {...ButtonAttributes}>
                {leftIcon && !fabButton && <Tag tag={'span'} className={ButtonWrapperClassName} />}
                {!fabButton && (
                    <Tag tag={'span'} className={clsx(className)}>
                        {children}
                    </Tag>
                )}
                {fabButton && (
                    <Tag tag={'span'} className={ButtonWrapperClassName}>
                        {children}
                        {fabButton && fabText && (
                            <Tag tag={'span'} className={'fmc-fab__text'}>
                                {fabText}
                            </Tag>
                        )}
                    </Tag>
                )}
                {rightIcon && !fabButton && <Tag tag={'span'} className={ButtonWrapperClassName} />}
            </Link>
        )
    }

    return (
        <Fragment>
            {
                tag === 'a' ? 
                <RenderHref disable={disableHref} />
                    :
                <Tag tag={tag} className={ButtonClassName} {...ButtonAttributes}>
                    {leftIcon && !fabButton && <Tag tag={'span'} className={ButtonWrapperClassName} />}
                    {!fabButton && (
                        <Tag tag={'span'} className={clsx(className)}>
                            {children}
                        </Tag>
                    )}
                    {fabButton && (
                        <Tag tag={'span'} className={ButtonWrapperClassName}>
                            {children}
                            {fabButton && fabText && (
                                <Tag tag={'span'} className={'fmc-fab__text'}>
                                    {fabText}
                                </Tag>
                            )}
                        </Tag>
                    )}
                    {rightIcon && !fabButton && <Tag tag={'span'} className={ButtonWrapperClassName} />}
                </Tag>
            }
        </Fragment>
    );
};

export default Button;
