
import React from 'react';
import { Col, Tag } from '../../components/components';

const DealerNoResultView = () => {
    return (
        <>
            <Col xs={12}>
                <Tag tag={'p'} className={'fds-type--body1 fds-color--gray3'}>
                    No results available. Please expand your search criterion.<br /><br />
                    Need assistance finding a dealer? Contact the Ford Pro Commercial Vehicle Center Program Headquarters at <Tag tag={'a'} href={'tel:18882764088'}>1-888-276-4088</Tag> or <Tag tag={'a'} href={'mailto:CVCHQ@FordProgramHQ.com'}>CVCHQ@FordProgramHQ.com</Tag>
                </Tag>
            </Col>
        </>
    )   
}

export default DealerNoResultView;
