import React, { Fragment } from 'react';
import clsx from 'clsx';
import Col from '../../Columns/Col';
import Row from '../../Columns/Row';
import Tag from '../../Tag/Tag';
import Section from '../Section';
import Button from '../../Buttons/Button';
import { commercialResources } from '../../../../constants/sectionConstants';

const CommercialResourceSection = ({ windowWidth }) => {
    return (
        <>
            <Section
                id={'CVCConsumerSiteResourceSection'}
                padding={windowWidth >= 1024 ? { dir: 't', amt: 4 } : { dir: 't', amt: 0 }}
            >
                <Row
                    justify={'center'}
                    className={windowWidth >= 1024 ? 'fmc-divider--top' : 'fds-align--center'}
                    padding={windowWidth >= 1024 ? { dir: 't', amt: 4 } : { dir: 't', amt: 1 }}
                >
                    <Col xs={12}>
                        <Tag tag={'h6'} className={'fmc-type--heading3 fds-color--primary fds-align--center'}>
                            Ford Pro Commercial Resources
                        </Tag>
                    </Col>
                </Row>
                <Row
                    padding={
                        windowWidth >= 1024
                            ? { dir: 'y', amt: 3 }
                            : [
                                  { dir: 'b', amt: 3 },
                                  { dir: 't', amt: 0 },
                              ]
                    }
                >
                    {commercialResources?.map(({ label, copy, cta }, idx, { length }) => {
                        const ClassName = [
                            {
                                'fmc-divider--right fmc-divider--right--hide-lg fds-xs:fds-align--center fds-md:fds-align--left':
                                    idx + 1 !== length,
                            },
                            {
                                'fmc-divider--bottom fmc-divider--bottom--hide-xl fmc-divider--bottom--hide-lg fmc-divider--bottom--show-sm fds-xs:fds-align--center fds-md:fds-align--left':
                                    idx < 3,
                            },
                        ];

                        return (
                            <Fragment key={Math.random()}>
                                <Col
                                    xs={12}
                                    md={6}
                                    lg={3}
                                    padding={
                                        windowWidth >= 1024
                                            ? 3
                                            : [
                                                  { dir: 't', amt: 4 },
                                                  { dir: 'b', amt: 0 },
                                              ]
                                    }
                                    className={clsx(ClassName)}
                                >
                                    <Row style={{ minHeight: 260 }} justify={'center'}>
                                        <Col xs={10} md={12} padding={0}>
                                            <Tag
                                                tag={windowWidth >= 1024 ? 'h6' : 'h5'}
                                                className={
                                                    windowWidth >= 1024
                                                        ? 'fds-color--primary fds-weight--bold fmc-type--heading6'
                                                        : 'fds-color--primary fds-weight--bold fmc-type--heading5 fds-align--center'
                                                }
                                                padding={{ dir: 'b', amt: 1 }}
                                            >
                                                {label}
                                            </Tag>
                                            <Tag
                                                tag={'p'}
                                                className={clsx('fds-color--primary fds-type--body1', { 'fds-align--center': windowWidth <= 1024 } )}
                                            >
                                                {copy}
                                            </Tag>
                                        </Col>
                                        <Col
                                            xs={10}
                                            md={12}
                                            padding={0}
                                            margin={
                                                windowWidth >= 1024
                                                    ? { dir: 't', amt: 'auto' }
                                                    : { dir: 't', amt: '0' }
                                            }
                                        >
                                            <Tag
                                                className={
                                                    windowWidth >= 1024
                                                        ? 'fds-flex--column cvc-resources-cta-wrap'
                                                        : 'fds-align--center'
                                                }
                                            >
                                                <Button
                                                    tag={'a'}
                                                    target='_blank'
                                                    textButton
                                                    rightIcon
                                                    className={'fds-color--primary'}
                                                    buttonClassName={'fds-color--primary'}
                                                    href={cta?.href}
                                                >
                                                    {cta?.label}
                                                </Button>
                                            </Tag>
                                        </Col>
                                    </Row>
                                </Col>
                            </Fragment>
                        );
                    })}
                </Row>
            </Section>
        </>
    )
}

export default CommercialResourceSection;
