import React from 'react';

import { Col, Row, Tag } from '../../components/components';
import ImageCarousel from '../../components/components/ImageCarousel';
import useWindowDimensions from '../../hooks/useWindowDimensions';

// const images = [
//     {
//         url: '/assets/images/gallery/1.png',
//         title: 'Title 1'
//     },
//     {
//         url: '/assets/images/gallery/2.png',
//         title: 'Title 2'
//     },
//     {
//         url: '/assets/images/gallery/3.png',
//         title: 'Title 3'
//     },
//     {
//         url: '/assets/images/gallery/4.png',
//         title: 'Title 4'
//     },
//     {
//         url: '/assets/images/gallery/5.png',
//         title: 'Title 5'
//     },
//     {
//         url: '/assets/images/gallery/6.png',
//         title: 'Title 6'
//     },
//     {
//         url: '/assets/images/gallery/7.png',
//         title: 'Title 7'
//     },
//     {
//         url: '/assets/images/gallery/8.png',
//         title: 'Title 8'
//     },
//     {
//         url: '/assets/images/gallery/9.png',
//         title: 'Title 8'
//     }
// ];

const getCount = (width) => {
    switch(true) {
        case width < 600:
            return 1;
        case width < 1024:
            return 2;
        default:
            return 4;
    }
}

const DealerPhotoGallery = ({ images }) => {
    const { width } = useWindowDimensions();

    const imagesPerSlide = getCount(width);
    return (
        <>
            <Col xs={12}>
                <Row padding={{ dir: 'b', amt: 2 }}>
                    <Tag
                        tag={'h6'}
                        className={'fds-color--primary fmc-type--heading6'}
                    >
                        Photo Gallery:
                    </Tag>
                </Row>
            </Col>
            <Col xs={12} padding={0}>
                <ImageCarousel images={images} imagesPerSlide={imagesPerSlide}  />
            </Col>
        </>
    )
}

export default DealerPhotoGallery;
