import Col from "../Columns/Col";
import Row from "../Columns/Row";
import Tag from "../Tag/Tag";

const Breadcrumb = (props) => {
    const { onBCHandle, title } = props;

    return (
        <Row padding={[{ dir: 'b', amt: 2 }, { dir: 'b', amt: 3 }]}>
            <Col xs={12}>
                <Tag tag='span' className={'fmc-type--body2 fds-color--gray3 cvc-crumb'} onClick={onBCHandle}>
                    Ford Pro Commercial Vehicle Centers by State
                </Tag>
                <Tag tag='span' className={'fmc-type--body2 fds-color--gray3'}>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                </Tag>
                <Tag tag='span' className={'fmc-type--body2 fds-color--gray3 cvc-crumb cvc-crumb-active'}>
                    {title}
                </Tag>
            </Col>
        </Row>
    )
}

export default Breadcrumb