import React, { memo } from 'react';
import { InfoWindow as GInfoWindow } from '@react-google-maps/api';
import Row from '../Columns/Row';
import Col from '../Columns/Col';
import Tag from '../Tag/Tag';
import { capitalizeWords } from '../../utils/Helpers';


interface LatLng {
    lat: number;
    lng: number;
}

interface InfoWindowProps {
    lat: number;
    lng: number;
    title: string;
    description: string;
}

const InfoWindow = (props: InfoWindowProps) => {
    const { description, title, lat, lng } = props;
    
    if (!lat || !lng || !title) return <></>;

    return (
        <GInfoWindow position={{ lat, lng }} options={{ pixelOffset: new google.maps.Size(0, -40) }}>
            <Row className='fmc-divider--left fmc-divider--primary'>
                <Col xs={12}>
                    <Tag tag={'p'} className={'fds-color--primary fmc-type--body2'}>{capitalizeWords(title)}</Tag>
                </Col>
                <Col xs={12}>
                    <Tag tag={'p'} className={'fds-color--primary fmc-type--body2'}>{description}</Tag>
                </Col>
            </Row>
        </GInfoWindow>
    )
}

const isEqual = (prevProps, nextProps) => {
    if (prevProps.lat !== nextProps.lat) return false;
    return true;
}

export default memo(InfoWindow, isEqual);
