import React, { FC, Fragment, useContext } from "react"
import { Helmet } from "react-helmet"
import { Section, Button, Tag, Input, Row, Col, Layout } from '../components/components'
import { useDealerInfo } from "../provider/DealerProvider"
import { WindowWidthContext } from '../contexts/WindowWidthContext';

const commercialAdvantages = [
    [
        "Over 650 locations nationwide",
        "Commercial Vehicle Experts in Sales, Finance, Parts and Service",
        "Priority service and support for commercial customers",
        "The right commercial vehicles and parts",
    ],
    [
        "Commercial finance and lease alternatives",
        "Parts and service discounts",
        "Extended service hours",
        "Rental and loaner services available",
    ]
]

const fordProProductsCheckboxes = [
    [
        "Vehicle",
        "Charging",
        "Software",
    ],
    [
        "Service",
        "Financing",
    ]
]

const IndexPage: FC = () => {
    const whyCommercialCenterRef = React.useRef();
    const findDealerRef = React.useRef();

    const { windowWidth, setWindowWidth } = useContext(WindowWidthContext);

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: 'en', 'data-brand': 'ford' }}>
            </Helmet>
            <Section
                    id="why-commercial-center"
                    className={
                        windowWidth < 1024 ? 'fds-p--x-0 fds-color__bg--white' : 'fds-color__bg--white'
                    }
                    innerAttributes={{ padding: { dir: 'y', amt: 0 } }}
                >
                    <Row className={windowWidth < 1024 ? 'fds-p--x-0' : ''}>
                        <Col
                            xs={12}
                            lg={6}
                            padding={{ dir: 'x', amt: 0 }}
                        >
                            <Tag
                                tag={'img'}
                                className={'fds-img'}
                                alt={'CVC Commercial Vehicle Center Image'}
                                style={
                                    windowWidth >= 1024
                                        ? {
                                              minHeight: 750,
                                              objectFit: 'cover',
                                          }
                                        : {
                                              maxHeight: 750,
                                              objectFit: 'fill',
                                          }
                                }
                                src={'/assets/images/cvc-commercial-vehicle-center.png'}
                            />
                        </Col>
                        <Col
                            xs={12}
                            lg={6}
                            className={'fds-color__bg--white'}
                            padding={
                                windowWidth >= 1024
                                    ? 4
                                    : [
                                          { dir: 'y', amt: 2 },
                                          { dir: 'x', amt: 3 },
                                      ]
                            }
                        >
                            <Tag
                                className={'h-100 fds-flex--column fds-flex__justify--center'}
                                padding={{ dir: 'y', amt: 3 }}
                                ref={whyCommercialCenterRef}
                            >
                                <Tag
                                    tag={'h6'}
                                    className={'fmc-type--heading6 fds-color--primary'}
                                    padding={{ dir: 'b', amt: 2 }}
                                >
                                    Why Commercial Vehicle Center?
                                </Tag>
                                <Tag
                                    tag={'h3'}
                                    className={'fmc-type--heading5 fds-color--primary'}
                                    padding={{ dir: 'b', amt: 2 }}
                                >
                                    Doing What It Takes to Support Your Business
                                </Tag>
                                <Tag
                                    tag={'p'}
                                    className={'fds-type--body1 fds-color--gray3'}
                                    padding={{ dir: 'b', amt: 2 }}
                                >
                                    No one provides commercial fleet services quite like your local Ford
                                    Pro™ Commercial Vehicle Center dealer. We are uniquely qualified to
                                    provide quick solutions, whether you have a few trucks or an entire
                                    fleet.
                                    <br />
                                    <br />
                                    <br />
                                    Plus, upfit incentives from Ford Commercial Connection can help you
                                    save money on commercial upfits for any vehicle in your fleet.
                                    Discover the Ford Pro™ advantages for yourself. Visit a Ford
                                    Commercial Vehicle Center dealer today and get the first-class
                                    treatment you and your business deserve.
                                </Tag>
                            </Tag>
                        </Col>
                    </Row>
            </Section>
            <Section light>
                <Row justify={'center'} padding={[{ dir: 't', amt: 3 }, { dir: 'b', amt: 2 }]}>
                    <Col xs={'auto'}>
                        <Tag tag={'h4'} className={'fmc-type--heading6 fds-weight--bold fds-align--center fds-color--primary'}>
                            Check Out Ford Pro™ Commercial Vehicle Center Advantages
                        </Tag>
                    </Col>
                </Row>
                <Row padding={{ dir: 'y', amt: 1 }} justify={'center'}>
                    <Col xs={10} padding={0}>
                        <Row justify={'center'}>
                            {
                                commercialAdvantages[0] && commercialAdvantages[0].map((copyArr, idx) => {
                                    return (
                                        <Fragment key={Math.random()}>
                                            <Col xs={12} lg={6}>
                                                <Row
                                                    padding={
                                                        windowWidth >= 1024
                                                            ? { dir: 'y', amt: 1 }
                                                            : [
                                                                  { dir: 't', amt: 1 },
                                                                  { dir: 'b', amt: 2 },
                                                              ]
                                                    }
                                                >
                                                    <Col xs={'variable'} tag={'span'} className={'fds-icon fds-icon--16 fds-font--ford-icons__check-filled fds-color--primary'} style={{ lineHeight: '2.2rem' }} />
                                                    <Col xs={'auto'} tag={'p'} className={'fds-color--gray3 fds-type--body1'}>
                                                        {commercialAdvantages[0][idx]}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={12} lg={6}>
                                                <Row
                                                    padding={
                                                        windowWidth >= 1024
                                                            ? { dir: 'y', amt: 1 }
                                                            : [
                                                                  { dir: 't', amt: 1 },
                                                                  { dir: 'b', amt: 2 },
                                                              ]
                                                    }    
                                                >
                                                    <Col xs={'variable'} tag={'span'} className={'fds-icon fds-icon--16 fds-font--ford-icons__check-filled fds-color--primary'} style={{ lineHeight: '2.2rem' }} />
                                                    <Col xs={'auto'} tag={'p'} className={'fds-color--gray3 fds-type--body1'}>
                                                        {commercialAdvantages[1][idx]}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Fragment>
                                    )
                                })
                            }
                        </Row>
                    </Col>
                </Row>
                {windowWidth >= 1024 && (
                    <Row justify={'center'} padding={{ dir: 'y', amt: 3 }}>
                        <Col xs={'variable'}>
                            <Tag tag={'p'} className={'fds-type--subtitle1 fds-color--primary'}>
                                We Don't Just Sell Vehicles. We Help Drive Business.
                            </Tag>
                        </Col>
                    </Row>
                )}
            </Section>
            <Section
                className={
                    windowWidth < 1024 ? 'fds-p--x-0 fds-color__bg--white' : 'fds-color__bg--white'
                }
                innerAttributes={{ padding: { dir: 'y', amt: 0 } }}
            >
                <Row reverse>
                    <Col
                        xs={12}
                        lg={6}
                        padding={{ dir: 'x', amt: 0 }}
                    >
                        <Tag
                            tag={'img'}
                            className={'fds-img'}
                            alt={'CVC Commercial Vehicle Center Image'}
                            style={
                                windowWidth >= 1024
                                    ? {
                                          minHeight: 750,
                                          objectFit: 'cover',
                                      }
                                    : {
                                          maxHeight: 750,
                                          objectFit: 'fill',
                                      }
                            }
                            src={'/assets/images/cvc-commercial-incentives.png'}
                        />
                    </Col>
                    <Col
                        xs={12}
                        lg={6}
                        className={'fds-color__bg--white'}
                        padding={
                            windowWidth >= 1024
                                ? 4
                                : [
                                      { dir: 'y', amt: 2 },
                                      { dir: 'x', amt: 3 },
                                  ]
                        }
                    >
                        <Tag className={'h-100 fds-flex--column fds-flex__justify--center'} padding={{ dir: 'y', amt: 3 }}>
                            <Tag
                                tag={'h5'}
                                className={'fmc-type--heading5 fds-color--primary fds-weight--medium'}
                                padding={{ dir: 'b', amt: 2 }}
                            >
                                Stay Connected for Generous Incentives and So Much More
                            </Tag>
                            <Tag
                                tag={'p'}
                                className={'fds-type--body1 fds-color--gray3'}
                                padding={{ dir: 'b', amt: 2 }}
                            >
                                As a commercial customer, you need vehicles and upfits that work as
                                hard as you do. You get that and more when you stay connected to
                                your Ford Pro™ Commercial Vehicle Center.
                                <br />
                                <br />
                                <br />
                                In addition to legendary Built Ford Tough reliability, get
                                business-focused incentives that go the extra mile. Simply click the
                                link below for info on our latest vehicles, important product
                                updates, news about offers and more.
                            </Tag>
                            <Row padding={[{ dir: 't', amt: 2 }]}>
                                <Col xs={12} padding={{ dir: 'l', amt: 0 }}>
                                    <Button tag='a' target="_blank" href="//fordpro.com/en-us/updates" rightIcon>
                                        Stay in the Know
                                    </Button>
                                </Col>
                            </Row>
                        </Tag>
                    </Col>
                </Row>
            </Section>
        </Fragment>
    )
}

export default IndexPage
