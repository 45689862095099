// eslint-disable-next-line no-unused-vars
import { classes, strings } from './component.const';

/**
 *
 * @param {HTMLElement} element The element that will be bootstrapped.
 */
export default class FDSComponent {
    /**
     * Check if an element is bootstrapped.
     * @param {HTMLElement} element The element to test if its already be bootstrapped.
     * @returns {boolean} Returns true if element is already bootstrapped
     */
    static isElementBootstrapped(element) {
        return element.classList.contains(classes.BOOTSTRAPPED);
    }

    /**
     * Constructor
     * @constructor
     * @param {HTMLElement} element The element of the FDS component.
     * @param {String} namespace The namespace of the FDS component, like `FDSComponent`.
     */
    constructor(element, namespace) {
        this.fdsElement = element;

        this.isIE11 = this.isIE11();

        if (this.fdsElement) {
            if (namespace) {
                this.fdsElement[namespace] = this;
            }
            this.init();
        }
    }

    /**
     * Initialize component
     */
    init() {
        this.fdsElement.classList.add(classes.BOOTSTRAPPED);
    }

    /**
     * Check if browser is IE11
     */
    isIE11() {
        const ua = window.navigator.userAgent;
        const trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            const rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }
        return false;
    }

    /**
     * Remove a child element from its parent.
     * @param {HTMLElement} childNode The element to remove.
     */

    removeChild(childNode) {
        if (childNode && childNode.parentNode) {
            childNode.remove();
        }
    }

    /**
     * Creates an HTML element and sets the given CSS classes and attributes.
     * @param {string} elTagName is a string that specifies the type of element to be created.
     * @param {Array} elClasses is an array of class names to set, e.g. ['fds-class-1', 'fds-class-2'].
     * @param {Object} elAttributes is an object with attributes to set, e.g. {width: 100}.
     * @returns {HTMLElement} The created element.
     */

    createElement(elTagName, elClasses, elAttributes) {
        const element = document.createElement(elTagName);
        if (elClasses && elClasses.length) {
            if (Array.isArray(elClasses)) {
                elClasses.forEach((elClass) => {
                    element.classList.add(elClass);
                });
            } else {
                element.classList.add(elClasses);
            }
        }
        if (elAttributes) {
            Object.entries(elAttributes).forEach((attribute) => {
                element.setAttribute(attribute[0], attribute[1]);
            });
        }
        return element;
    }

    /**
     * Creates an HTML element and sets the given CSS classes and attributes.
     * @param {string} selector is a string that specifies the selector of the requested element.
     * @returns {HTMLElement} The fetched element.
     */
    getElement(selector) {
        return this.element.querySelector(selector);
    }

    /**
     * Creates an HTML element and sets the given CSS classes and attributes.
     * @param {string} selector is a string that specifies the selector of the requested element.
     * @returns {[HTMLElement]} The fetched elements.
     */
    getElements(selector) {
        return this.element.querySelectorAll(selector);
    }

    /**
     * Returns the HTMLElement of component.
     */
    get element() {
        return this.fdsElement;
    }
}
