export const FETCH_DEALER_BY_NAME_SUCCESS = 'FETCH_DEALER_BY_NAME_SUCCESS';
export const FETCH_DEALER_BY_CODE_SUCCESS = 'FETCH_DEALER_BY_CODE_SUCCESS';
export const FETCH_DEALER_DETAILS_SUCCESS = 'FETCH_DEALER_DETAILS_SUCCESS';
export const FETCH_DEALER_FILTER_SUCCESS = 'FETCH_DEALER_FILTER_SUCCESS';
export const FETCH_DEALER_BY_NAME_ERROR = 'FETCH_DEALER_BY_NAME_ERROR';
export const PAGE_CHANGE = 'PAGE_CHANGE';
export const CHANGE_SEARCH_PROPS = 'CHANGE_SEARCH_PROPS';
export const SET_CURRENT_DEALER = 'SET_CURRENT_DEALER';
export const APPLY_FILTERS = 'APPLY_FILTERS';
export const ADD_FILTER = 'ADD_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const RESET_FILTERS = 'RESET_FILTERS';
export const FETCH_IP_INFO_SUCCESS = 'FETCH_IP_INFO_SUCCESS';