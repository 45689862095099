import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { Row, Col, Tag } from '../';
import { isDefined } from '../../utils/Helpers';

interface BillboardProps {
    className?: string;
    contentClassNames?: string;
    tag?: string;
    backgroundImageUrl?: string;
    backgroundImageAttributes?: object;
    style?: object;
    justify?: 'start' | 'center' | 'end';
    align?: 'start' | 'center' | 'end';
    scrim?: 'dark' | 'black' | 'white';
    scrimProps?: 'top' | 'bottom' | 'left' | 'right' | 'full';
    backgroundImageSplit?: 'top' | 'bottom' | 'left' | 'right' | 'none';
    children?: ReactNode | JSX.Element | JSX.Element[] | string | string[];
}

const Billboard: FC<BillboardProps> = (props) => {
    const {
        className = '',
        contentClassNames = '',
        tag = 'div',
        backgroundImageUrl,
        justify = 'start',
        align = 'start',
        scrim = 'dark',
        scrimProps = 'top',
        backgroundImageSplit = 'none',
        children,
        backgroundImageAttributes,
        ...attributes
    } = props;

    const classes = [
        'fmc-billboard',
        { 'fmc-billboard--justify-start': justify === 'start' },
        { 'fmc-billboard--justify-center': justify === 'center' },
        { 'fmc-billboard--justify-end': justify === 'end' },
        { 'fmc-billboard--align-start': align === 'start' },
        { 'fmc-billboard--align-center': align === 'center' },
        { 'fmc-billboard--align-end': align === 'end' },
    ];

    const scrimClasses = [
        'fmc-billboard__scrim',
        { 'fmc-billboard--scrim-top': scrimProps === 'top' },
        { 'fmc-billboard--scrim-bottom': scrimProps === 'bottom' },
        { 'fmc-billboard--scrim-left': scrimProps === 'left' },
        { 'fmc-billboard--scrim-right': scrimProps === 'right' },
        { 'fmc-billboard--scrim-full': scrimProps === 'full' },
        { 'fmc-billboard--scrim-black': scrim === 'black' },
        { 'fmc-billboard--scrim-dark': scrim === 'dark' },
        { 'fmc-billboard--scrim-white': scrim === 'white' },
    ];

    const backgroundImageClasses = [
        'fmc-billboard__image',
        { 'fmc-billboard--split-image-top': backgroundImageSplit === 'top' },
        { 'fmc-billboard--split-image-bottom': backgroundImageSplit === 'bottom' },
        { 'fmc-billboard--split-image-left': backgroundImageSplit === 'left' },
        { 'fmc-billboard--split-image-right': backgroundImageSplit === 'right' },
    ];

    const ClassName = clsx(classes, className);

    const _backgroundImageAttributes =
        isDefined(backgroundImageAttributes) && backgroundImageAttributes?.hasOwnProperty('style')
            ? {
                  ...backgroundImageAttributes,
                  ...{
                      style: {
                          backgroundImage: `url(${backgroundImageUrl})`,
                          ...(backgroundImageAttributes as any)?.style,
                      },
                  },
              }
            : backgroundImageAttributes;

    return (
        <Row tag={tag} className={ClassName} {...attributes}>
            {isDefined(backgroundImageUrl) && (
                <Tag
                    className={clsx(backgroundImageClasses)}
                    style={{ backgroundImage: `url(${backgroundImageUrl})` }}
                    {..._backgroundImageAttributes}
                />
            )}
            <Tag className={clsx(scrimClasses)} />
            <Col
                xs={12}
                lg={12}
                xl={10}
                className={clsx('fmc-billboard__content', contentClassNames)}
            >
                {children}
            </Col>
        </Row>
    );
};

export default Billboard;
