import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollHandler = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const hash = location.hash?.split('#')?.[1] ?? '#';
            const element = document.getElementById(hash);
            setTimeout(() => {
                window.scrollTo({
                    behavior: element ? "smooth" : "auto",
                    top: element ? element.offsetTop : 0
                });
            }, 100);
        }
    }, [location]);

    return null;
};

export default ScrollHandler;
