import React from 'react';
import {
    createBrowserRouter,
    RouterProvider,
  } from "react-router-dom"
  
import Home from './pages/index'
import FindADealer from './pages/find-a-dealer'
import { Layout } from './components/components';
import DealerProvider from './provider/DealerProvider';
import DealerStateView from './pages/DealerStateView';
import DealerDetailView from './pages/DealerDetailView';
import StateList from './pages/DealerStateView/StatesList';
import DealerListByStateView from './pages/DealerStateView/DealerListByStateView';

const LayoutWithProvider = () => (
    <DealerProvider>
        <Layout />
    </DealerProvider>
)

export const ROUTE_DEALER_DETAIL_VIEW = '/dealers/:dealerId'
export const ROUTE_FIND_DEALER = '/find-a-dealer'
export const ROUTE_DEALERS_STATE_LIST = '/dealers/states'
export const ROUTE_DEALERS_BY_STATE = '/dealers/states/:stateCode';

const router = createBrowserRouter([
    {
      path: "/",
      element: <LayoutWithProvider />,
      children: [
        {
            index: true,
            element: <Home />,
            handle: { isHome: true }
        },
        {
            path: ROUTE_FIND_DEALER,
            element: <FindADealer />
        },
        {
            path: ROUTE_DEALERS_STATE_LIST,
            element: <DealerStateView />,
            children: [
                {
                    index: true,
                    element: <StateList />
                },
                {
                
                    path: ROUTE_DEALERS_BY_STATE,
                    element: <DealerListByStateView />
                }
            ]
        },
        {
            path: '/dealer-list',
            /* @ts-ignore */
            element: <DealerStateView isDetail />,
        },
        {
            path: ROUTE_DEALER_DETAIL_VIEW,
            element: <DealerDetailView  />,
        }
      ]
    }
])

export default router;