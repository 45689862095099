import React, { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DealerMiddlewareProps } from './types';

const DealerMiddleware = (props: DealerMiddlewareProps) => {
    const {
        searchProps,
        setSearchProps,
        fetchDealerFilters,
        fetchDealerDetailsByZip,
        fetchDealersByState,
        fetchDealerDetailsByLocation,
        fetchDealerDetailsByName,
        filterDealers,
    } = props;

    const nameRef = useRef('');

    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const lat = searchParams.get('lat');
    const lng = searchParams.get('lng');
    const zip = searchParams.get('zip');
    const name = searchParams.get('name');
    const maxDistance = searchParams.get('maxDistance');
    const state = searchParams.get('state');
    const page = searchParams.get('page') || 1;
    const defLocationData = lat ? { lat, lng } : null;

    const { lat: latQuery, lng: lngQuery, maxDistance: distanceQuery, name: nameQuery, zip: zipQuery, page: pageQuery, filters, searchType } = searchProps || {};

    const mLat = latQuery || lat;
    const mLng = lngQuery || lng;
    const mPage = pageQuery || page;
    const mName = nameQuery || name;
    const mZip = zipQuery || zip;
    const mDistance = distanceQuery || maxDistance;

    // useEffect(() => {
    //     if (mName) {
    //         console.log('on change', mName, mPage)
    //         const _page  = (mName === nameRef.current) ? mPage : 1;
    //         // const url = `/find-a-dealer?name=${mName}&page=${_page}`;
    //         // nameRef.current = mName;
    //         // navigate(url);
    //     }
    // }, [mName, mPage]);

    // useEffect(() => {
    //     if (mZip) {
    //         // const url = `/find-a-dealer?zip=${mZip}&maxDistance=${mDistance}&page=${mPage}`;
    //         // navigate(url);
    //     }
    // }, [mZip, mDistance, mPage]);

    // useEffect(() => {
    //     if (mLat) {
    //         // const url = `/find-a-dealer?lat=${mLat}&lng=${mLng}&page=${mPage}`;
    //         // navigate(url);
    //     }
    // }, [mLat, mPage]);

    useEffect(() => {
        if (name) setSearchProps({ ...searchProps, name })
    }, [name])

    useEffect(() => {
        if (zip) setSearchProps({ ...searchProps, zip })
    }, [zip])
    
    // useEffect(() => {
    //     if (maxDistance) setSearchProps({ ...searchProps, maxDistance: maxDistance as any })
    // }, [maxDistance])

    useEffect(() => {
        if (lat) setSearchProps({ ...searchProps, lat, lng })
    }, [lat, lng])


    useEffect(() => {
        fetchDealerFilters();
    }, []);

    useEffect(() => {
        if (name && (!filters || filters?.length === 0)) {
            const searchterm = name || '';
            fetchDealerDetailsByName(searchterm, Number(page));
        }
    }, [filters, name, page]);

    useEffect(() => {
        if (lat && lng && (!filters || filters?.length === 0)) {
            fetchDealerDetailsByLocation(lat, lng, Number(page));
        }
    }, [filters, lat, lng, page]);

    useEffect(() => {
        if (zip && (!filters || filters?.length === 0)) {
            console.log("Zip change ", zip)
            fetchDealerDetailsByZip(zip, Number(page));
        }
    }, [filters, zip, maxDistance, page]);
    
    useEffect(() => {
        if (state) {
            fetchDealersByState(state);
        }
    }, [state, page]);
    
    useEffect(() => {
        if (Array.isArray(filters) && filters?.length > 0) {
            let filterConfig: any = null;

            if (name) {
                filterConfig = { type: 'dealername', dealerName: name }
            };
            if (zip) {
                filterConfig = { type: 'zipcode', zipCode: zip }
            }
            if (lat && lng) {
                filterConfig = { type: 'location', latitude: lat, longitude: lng }
            }
            
            if (filterConfig) {
                console.log("Applying Filters", filterConfig)
                filterDealers(filters, filterConfig, Number(page));
            }
        }
    }, [filters, page])

    return (
        <>
        </>
    )
}

export default DealerMiddleware;
