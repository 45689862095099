import React from 'react';
import { Col, Row, Tag } from '../../components/components';
import DealerBaseInfoView from './DealerBaseInfoView';
import DealerPhotoGallery from './DealerPhotoGallery';

const DealerSalesDetails = (props: any) => {
    const { salesWorkHours, dealerEmail, salesEmail, phone } = props;

    return (
        <Row>
            <DealerBaseInfoView workHours={salesWorkHours} dealerEmail={dealerEmail} section='Sales' sectionEmail={salesEmail} phone={phone} />
        </Row>
    )
}

export default DealerSalesDetails;