import React, { Fragment, useState, useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Col, Map, Marker, Pagination, Row } from '../../components/components';
import { useDealerInfo } from '../../provider/DealerProvider';
import { _dealerSampleData } from './constant';
import DealerCard from './DealerCard';
import { DealerListProps } from './types';
import { WindowWidthContext } from '../../contexts/WindowWidthContext';
import { Accordion, AccordionPanel } from '@avllc/cxc-components-web';
import { objToQueryString } from '../../components/utils/FetchUtils';

const DealerList = (props: DealerListProps) => {
    const [activeDealer, setActiveDealer] = useState(null);
    const [activeLocationInfo, setActiveLocationInfo] = useState(null);
    const [params, setParams] = useSearchParams();

    const { dealersCount, currentPage: defaultPage, dealer: selectedDealer, setPage, setDealer, searchProps, setSearchProps, currentLocation } = useDealerInfo();
    const { windowWidth, setWindowWidth } = useContext(WindowWidthContext);
    const { dealers } = props;

    const navigate = useNavigate();
    const currentPage = params.get('page') ?? defaultPage;

    const handlePageChange = (newPage: number) => {
        setSearchProps({ ...searchProps, page: newPage })
        const { zip, name, maxDistance, lat, lng } = searchProps || {};
        const queryParams =  {
            zip,
            name,
            maxDistance: zip ? maxDistance : '',
            lat,
            lng,
            page: newPage,
        };
        const url = `/find-a-dealer?${objToQueryString(queryParams)}`;
        navigate(url);
    }

    const onDealerSelect = (dealer: any, idx: number) => {
        setActiveDealer(dealer?.id);
        setDealer(dealer);
    }

    const setActiveDealerInfo = (dealer) => {
        setActiveDealer(dealer?.id);
        const description = dealer?.distance != null && dealer?.distance !== undefined ? `${Math.round(dealer?.distance)} miles away` : '';
        setActiveLocationInfo({ title: dealer?.name, description, lat: dealer?.latitude, lng: dealer?.longitude })
        setDealer(dealer);
    }

    const handleDealerChange = (dealer) => {
        setActiveDealerInfo(dealer)
    }

    useEffect(() => {
        if (dealers?.length > 0) {
            setActiveDealerInfo(dealers?.[0])
        }
    }, [dealers]);

    const markers = dealers?.map(dealer => {
        return {
            lat: dealer?.latitude,
            lng: dealer?.longitude,
            extras: {
                ...dealer,
            }
        }
    });

    return (
        <>
            <Col xs={12} lg={5} padding={[{ dir: 'l', amt: 1 }, { dir: 'r', amt: 0 }]}>
                <Row style={{ maxHeight: '80vh', overflowY: 'scroll' }} hide={['xs', 'sm']}>
                    <Col xs={12} padding={0}>
                        {
                            dealers.map((dealer, i) => {
                                return (
                                    <Fragment key={i}>
                                        <DealerCard
                                            // @ts-ignore
                                            active={activeDealer === dealer?.id}
                                            onClickHandler={() => onDealerSelect(dealer, i)}
                                            onDealerFocusChange={() => handleDealerChange(dealer)}
                                            {...dealer}
                                        />
                                    </Fragment>
                                )
                            })
                        }
                    </Col>
                </Row>
                <Row className={'fds-app'} hide={['md', 'lg', 'xl']}>
                    <Accordion background="dark" foreground="light">
                        <>
                            <Col xs={12} padding={0}>
                                {dealers.map((dealer: any, i) => {
                                    const distanceStr = dealer?.distance != null && dealer?.distance !== undefined ? `${Math.round(Number(dealer?.distance))} Miles Away` : '';
                                    return (
                                        <Fragment key={Math.random()}>
                                            <AccordionPanel
                                                index={i}
                                                initiallyOpen={i === 0 ? true : false}
                                                titleText={`${i + 1}. ${dealer.name}`}
                                                descriptionText={distanceStr}
                                                className={
                                                    'cvc-accordion-panel accordion-panel-color-fill'
                                                }
                                            >
                                                <DealerCard
                                                    // @ts-ignore
                                                    active={activeDealer === dealer?.id}
                                                    onClickHandler={() => onDealerSelect(dealer, i)}
                                                    onDealerFocusChange={() => handleDealerChange(dealer)}
                                                    {...dealer}
                                                />
                                            </AccordionPanel>
                                        </Fragment>
                                    );
                                })}
                            </Col>
                        </>
                    </Accordion>
                </Row>

                {windowWidth >= 1024 && (
                    <Row padding={{ dir: 't', amt: 1 }}>
                        <Col xs={12} padding={0}>
                            <Pagination
                                isMobile
                                itemsPerPage={10}
                                itemsCount={dealersCount}
                                currrentPage={Number(currentPage)}
                                onPageChange={handlePageChange}
                                ariaLabel={'Dealer Results Pagination'}
                            />
                        </Col>
                    </Row>
                )}
            </Col>

            <Col xs={12} lg={7} style={{ minHeight: 500 }} hide={['xs', 'sm']}>
                <Map markers={markers} activeLocation={currentLocation} activeLocationInfo={activeLocationInfo} />
            </Col>

            {windowWidth < 1024 && (
                <Col
                    xs={12}
                    lg={5}
                    padding={[
                        { dir: 'l', amt: 1 },
                        { dir: 'r', amt: 0 },
                    ]}
                >
                    <Row padding={{ dir: 't', amt: 1 }}>
                        <Col xs={12} padding={0}>
                            <Pagination
                                isMobile
                                itemsPerPage={10}
                                itemsCount={dealersCount}
                                currrentPage={Number(currentPage)}
                                onPageChange={handlePageChange}
                                ariaLabel={'Dealer Results Pagination'}
                            />
                        </Col>
                    </Row>
                </Col>
            )}
        </>
    )
}

export default DealerList;
