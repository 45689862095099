
export const FORD_UPFITS_URL = `//fordupfits.com/commercial`
export const FORD_CREDIT_URL = `//ford.com/finance/commercial-financing/`
export const FORD_FLEET_CARE_URL = `//www.fleetcare.ford.com/`
export const FORD_COMMERCIAL_SOLUTIONS_URL = `//commercialsolutions.ford.com/`

const commercialResources = [
    {
        label: 'Ford Upfits',
        copy: 'See FordUpfits.com for upfit incentives and solutions that work for your business',
        cta: {
            label: 'Ford Upfits',
            href: FORD_UPFITS_URL,
        },
    },
    {
        label: 'Ford Pro FinSimple',
        copy: 'Proven tools to help you keep your business moving',
        cta: {
            label: 'Ford Pro FinSimple',
            href: FORD_CREDIT_URL,
        },
    },
    {
        label: 'Ford Fleet Care',
        copy: 'The reliable source to consolidate fleet parts and service',
        cta: {
            label: 'Ford Fleet',
            href: FORD_FLEET_CARE_URL,
        },
    },
    {
        label: 'Ford Commercial Solutions',
        copy: 'Solutions designed to address the daily challenges faced by fleet professionals like you',
        cta: {
            label: 'Ford Commercial Solutions',
            href: FORD_COMMERCIAL_SOLUTIONS_URL,
        },
    },
];

export { commercialResources }