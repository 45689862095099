import React, { useState, useContext, useMemo } from 'react';
import { DealerViewProps } from './types';
import {
    Section,
    Tag,
    Row,
    Col,
    FilterChips,
} from '../../components/components/'
import { _dealerSampleData } from './constant';
import DealerNoResultView from './DealerNoResultView';
import DealerList from './DealerList';
import DealerFilter from './DealerFilter';
import { useDealerInfo } from '../../provider/DealerProvider';
import { WindowWidthContext } from '../../contexts/WindowWidthContext';

const DealerView: React.FC<DealerViewProps> = (props) => {
    const { dealers } = props;
    const { windowWidth, setWindowWidth } = useContext(WindowWidthContext);
    const { searchProps, setSearchProps, dealersCount, filters, dealerFilters, dealerFilterDirMap, dealerFilterCodeMap, addFilter, removeFilter, filterDealers } = useDealerInfo();
    const [filtersOpen, setFiltersOpen] = useState(false);

    const { filters: appliedFilters } = searchProps || {};

    const handleFilterChange = (e) => {
        const filter: string = typeof e === 'object' ? e?.target?.value : e;
        const action = filters?.includes(filter) ? removeFilter : addFilter;
        action(filter);
    }

    const handleRemoveFilter = (filterKey: string) => {
        removeFilter(filterKey);
        setSearchProps({ ...searchProps, filters: filters?.filter(key => key !== filterKey), page: 1 });
    }

    const handleApplyFilter = () => {
        setSearchProps({ ...searchProps, filters, page: 1 });
        setFiltersOpen(false);
    }

    const filterOpenHandler = () => {
        setFiltersOpen(true);
    };

    const filterCloseHandler = () => {
        setFiltersOpen(false);
    };

    const filterChips = useMemo(() => {
        return appliedFilters?.map(key => ({ label: dealerFilterDirMap?.[key], value: key  }));
    }, [appliedFilters])

    return (
        <>
            {windowWidth >= 1024 ? (
                <Section style={{ minHeight: '80vh' }} padding={{ dir: 't', amt: 3 }}>
                    <Row
                        className={'fds-xs:fds-flex-direction--row-reverse fds-md:fds-flex-direction--row'}
                    >
                        <Col xs={12} lg={3} padding={{ dir: 'b', amt: 2 }}>
                            <Row padding={{ dir: 'b', amt: 1 }}>
                                <Row
                                    xs={12}
                                    padding={0}
                                    className={'fmc-type--content3 fds-color--gray3'}
                                >
                                    <Tag
                                        tag={'span'}
                                        className={
                                            'fds-icon--offset-left om-fds-icon--16 fds-font--ford-icons-filter'
                                        }
                                        padding={{ dir: 'r', amt: 1 }}
                                    />
                                    <p>FILTERS</p>
                                </Row>
                            </Row>
                            <DealerFilter filters={dealerFilters as any} selectedFilters={filters} filterCodeMap={dealerFilterCodeMap} onFilterChange={handleFilterChange} onFilterApply={handleApplyFilter} />
                        </Col>
                        <Col xs={12} lg={9}>
                            <Row padding={{ dir: 'b', amt: 1 }}>
                                <Col xs={12} lg={'variable'}>
                                    <Tag tag={'h6'} className={'fmc-type--heading3 fds-type--primary'}>{dealersCount > 0 ? `Results - ${dealersCount}` : `No Results`}</Tag><br />
                                    <Tag className={'fmc-type--subtitle1 fds-type--primary'}>Sorted by Distance</Tag>
                                </Col>
                                <Col xs={12} lg={'auto'} padding={{ dir: 'b', amt: 1 }}>
                                    <FilterChips
                                        chips={filterChips}
                                        onClick={handleRemoveFilter}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                {dealers && dealers.length > 0
                                    ? <DealerList dealers={dealers} />
                                    : <DealerNoResultView />
                                }
                            </Row>
                        </Col>
                    </Row>
                </Section>
            ) : (
                <Section style={{ minHeight: '80vh' }} padding={{ dir: 't', amt: 3 }}>
                    <Row
                        className={
                            'fds-xs:fds-flex-direction--row-reverse fds-md:fds-flex-direction--row'
                        }
                    >
                        <Col xs={12} lg={9}>
                            <Row padding={{ dir: 'b', amt: 1 }}>
                                {/* THIS COL ONLY SHOWS WHEN ADD FILTERS IS ON SCREEN NOT FILTERS  */}

                                {!filtersOpen && (
                                    <Col xs={12} lg={'variable'}>
                                        <Tag
                                            tag={'h6'}
                                            className={'fmc-type--heading5 fds-type--primary'}
                                        >
                                            {dealers.length > 0 ? `Results - ${dealers?.length}` : `No Results`}
                                        </Tag>
                                        <Tag className={'fmc-type--subtitle1 fds-type--primary'}>
                                            Sorted by Distance
                                        </Tag>
                                    </Col>
                                )}
                                {!filtersOpen && (
                                    <Col xs={12} hide={['md', 'lg', 'xl']}>
                                        <Row
                                            padding={{ dir: 'b', amt: 1 }}
                                            className={
                                                'fmc-type--content3 fds-color--gray3 fds-m--t-2'
                                            }
                                            onClick={filterOpenHandler}
                                        >
                                            <Tag
                                                tag={'span'}
                                                className={
                                                    'fds-icon--offset-left om-fds-icon--16 fds-font--ford-icons-filter'
                                                }
                                                padding={{ dir: 'r', amt: 1 }}
                                            />
                                            <p>ADD FILTERS</p>
                                        </Row>
                                    </Col>
                                )}

                                {filtersOpen && (
                                    <Col xs={12} lg={3} padding={{ dir: 'b', amt: 2 }}>
                                        <Row padding={{ dir: 'b', amt: 1 }}>
                                            <Row
                                                xs={12}
                                                padding={0}
                                                className={'fmc-type--content3 fds-color--gray3'}
                                            >
                                                <Tag
                                                    tag={'span'}
                                                    className={
                                                        'fds-icon--offset-left om-fds-icon--16 fds-font--ford-icons-filter'
                                                    }
                                                    padding={{ dir: 'r', amt: 1 }}
                                                />
                                                <p>FILTERS</p>
                                                <Tag
                                                    tag={'span'}
                                                    className={
                                                        'fds-icon--offset-right fds-font--ford-icons__clear'
                                                    }
                                                    style={{
                                                        position: 'absolute',
                                                        right: '5.5rem',
                                                    }}
                                                    onClick={filterCloseHandler}
                                                />
                                            </Row>
                                        </Row>
                                    </Col>
                                )}
                                
                                {filtersOpen && (
                                    <Col xs={12} lg={'auto'} padding={{ dir: 'b', amt: 1 }}>
                                        <FilterChips
                                            chips={filterChips}
                                            onClick={handleRemoveFilter}
                                        />
                                    </Col>
                                )}

                                {filtersOpen && (
                                    <DealerFilter filters={dealerFilters as any} selectedFilters={filters} filterCodeMap={dealerFilterCodeMap} onFilterChange={handleFilterChange} onFilterApply={handleApplyFilter} />
                                )}

                                {!filtersOpen && (
                                    <Col xs={12} lg={'auto'} padding={{ dir: 'b', amt: 1 }}>
                                        <FilterChips
                                            chips={filterChips}
                                            onClick={handleRemoveFilter}
                                        />
                                    </Col>
                                )}
                            </Row>

                            {!filtersOpen && (
                                <Row>
                                    {dealers && dealers.length > 0 ? (
                                        <DealerList dealers={dealers} />
                                    ) : (
                                        <DealerNoResultView />
                                    )}
                                </Row>
                            )}
                        </Col>
                    </Row>
                </Section>
            )}
        </>
    )
}

export default DealerView;
