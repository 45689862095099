import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Tag, Row, Col } from '../../components/components/';
import Breadcrumb from '../../components/components/Breadcrumb';
import { US_STATES_LIST } from '../../constants/appConstants';
import { useDealerInfo } from '../../provider/DealerProvider';
import { ROUTE_DEALERS_STATE_LIST } from '../../router';
import DealerCard from '../DealerView/DealerCard';

const DealerListByStateView = (props) => {
    const { dealers } = useDealerInfo();
    const { stateCode } = useParams();
    const navigate = useNavigate();

    const { dealer, fetchDealerDetailsByState } = useDealerInfo();

    useEffect(() => {
        if (stateCode) {
            fetchDealerDetailsByState(stateCode)
        }
    }, [stateCode])
    
    const onBCHandle = () => {
        navigate(ROUTE_DEALERS_STATE_LIST)
    }

    const state = US_STATES_LIST?.[stateCode] ?? '';

    return (
        <>
            <Breadcrumb onBCHandle={onBCHandle} title={state} />
            <Row padding={{ dir: 'b', amt: 4 }}>
                <Col xs={12} lg={'variable'}>
                    <Tag tag={'h6'} className={'fmc-type--heading3 fds-type--primary'}>
                        {state}
                    </Tag>
                    <Tag className={'fmc-type--subtitle1 fds-type--primary'}>
                        Ford Pro Commercial Vehicle Centers by state
                    </Tag>
                </Col>
            </Row>
            <Row tag={'ul'} className={'fmc-cards'}>
                {dealers?.map((dealer: any, idx) => (
                    <React.Fragment key={idx}>
                        <Col xs={12} md={6} lg={4} padding={{ dir: 'l', amt: 0 }}>
                            <DealerCard {...dealer} tag={'li'} className={'fmc-cards__card fds-flex__justify--center'} customStyle={{ minHeight: 380 }} />
                        </Col>
                    </React.Fragment>
                ))}
            </Row>
        </>
    )
}

export default DealerListByStateView;
