import React, { FC, Fragment } from 'react';
import clsx from 'clsx';
import { Row, Col, Tag } from '../';
import { isDefined } from '../../utils/Helpers';

type SpacingType = string | number | boolean | object | object[];

interface Option {
    value: string;
    label: string;
}

interface InputProps {
    id: string;
    name: string;
    placeholder?: string;
    label?: string;
    required?: boolean;
    options: Option[];
    className?: string;
    value?: any;
    onChange?: any;
}

const Select: FC<InputProps> = (props) => {
    const { options, placeholder, name, label, id, className, ...attributes } = props;

    const classes = [{ 'fmc-select': true }];

    const ClassName: string = clsx(className, classes);

    return (
        <Row className={'fmc-input--wrapper'}>
            {isDefined(label) && (
                <Col
                    xs={12}
                    tag={'label'}
                    padding={[
                        { dir: 'b', amt: 1 },
                        { dir: 'l', amt: 0 },
                    ]}
                    className={'fmc-type--content2 fds-color__text--gray2'}
                    aria-labelledby={id}
                >
                    {attributes?.required && (
                        <Tag tag={'span'} className={'fds-color--gray3'}>
                            *
                        </Tag>
                    )}
                    {label}
                </Col>
            )}
            <Col xs={12} tag={'select'} className={ClassName} id={id} {...attributes}>
                {isDefined(placeholder) && (
                    <Tag tag={'option'} defaultValue={''}>
                        {placeholder}
                    </Tag>
                )}
                {options.map(({ value, label }) => {
                    return (
                        <Fragment key={Math.random()}>
                            <Tag tag={'option'} value={value} defaultValue={value}>
                                {label}
                            </Tag>
                        </Fragment>
                    );
                })}
            </Col>
        </Row>
    );
};

export default Select;
