import React, { useContext, useReducer, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { handleFetchError, handleFetchResponse } from '../../components/utils/FetchUtils';
import { getFromToByPage } from '../../components/utils/Helpers';
import { dealerDetailInfoSample } from '../../pages/DealerView/constant';
import { ADD_FILTER, APPLY_FILTERS, CHANGE_SEARCH_PROPS, FETCH_DEALER_BY_CODE_SUCCESS, FETCH_DEALER_BY_NAME_SUCCESS, FETCH_DEALER_DETAILS_SUCCESS, FETCH_DEALER_FILTER_SUCCESS, FETCH_IP_INFO_SUCCESS, PAGE_CHANGE, REMOVE_FILTER, RESET_FILTERS, SET_CURRENT_DEALER } from './actions';
import DealerMiddleware from './middleware';
import dealerReducer from './reducer';
import { DealerContextInterface, DealerProviderProps, DealerContextState, Dealer, DealerSearchProps, DealerFilter, DealerDetailProps, FilterApiConfig, IPInfo } from './types';
import humps from 'humps';
import { CAP_WEEK_DAYS, WEEK_DAYS } from '../../constants/appConstants';

export const defaultDealerSearchProps: DealerSearchProps = {
    searchTerm: '',
    searchType: 'name',
    name: '',
    zip: '',
    maxDistance: 10,
    lat: '',
    lng: '',
    stateCode: '',
    filters: null,
};

const initialValue: DealerContextState = {
    searchProps: { ...defaultDealerSearchProps },
    dealers: [],
    dealer: null,
    dealersCount: 0,
    dealerFilters: [],
    dealerFilterCodeMap: null,
    dealerFilterDirMap: null,
    stateList: [],
    currentPage: 1,
    isPristine: true,
    filters: [],
    timeZone: '',
    currentLocation: { lat: null, lng: null },
    ipInfo: null
}

export const DealerContext = React.createContext<DealerContextInterface | null>(null);
type GenericObject = { [key: string]: string }

const DealerProvider = (props: DealerProviderProps) => {
    const { children } = props;
    const [ state, dispatch ] = useReducer(dealerReducer, initialValue);

    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = searchParams.get('page') ?? state?.currentPage;

    const actions = {
        dealersByNameFetched: (data: any) => {
            dispatch({ type: FETCH_DEALER_BY_NAME_SUCCESS, data })
        },
        dealersByCodeFetched: (data: any) => {
            dispatch({ type: FETCH_DEALER_BY_CODE_SUCCESS, dealer: data })
        },
        dealersDetailsFetched: (data: DealerDetailProps, page: number) => {
            dispatch({ type: FETCH_DEALER_DETAILS_SUCCESS, dealers: data?.dealersDetailList, total: data?.total, page })
        },
        dealerFiltersFetched: (dealerFilters: DealerFilter[], dealerFilerMap: { codeMap: GenericObject, dirMap: GenericObject }) => {
            const { codeMap, dirMap } = dealerFilerMap || {};
            dispatch({ type: FETCH_DEALER_FILTER_SUCCESS, dealerFilters, codeMap, dirMap })
        },
        changePage: (page: number) => {
            dispatch({ type: PAGE_CHANGE, page })
        },
        changeSearch: (searchProps: DealerSearchProps) => {
            dispatch({ type: CHANGE_SEARCH_PROPS, searchProps })
        },
        setCurrentDealer: (dealer: Dealer) => {
            dispatch({ type: SET_CURRENT_DEALER, dealer });
        },
        applyFilters: (filters: Array<string>) => {
            dispatch({ type: APPLY_FILTERS, filters });
        },
        addFilter: (filter: string) => {
            dispatch({ type: ADD_FILTER, filter });
        },
        removeFilter: (filter: string) => {
            dispatch({ type: REMOVE_FILTER, filter });
        },
        resetFilters: () => {
            dispatch({ type: RESET_FILTERS });
        },
        ipInfoFetched: (ipInfo) => {
            dispatch({ type: FETCH_IP_INFO_SUCCESS, ipInfo, timeZone: _selectTimezone(ipInfo), location: _selectLocation(ipInfo) })
        }
    }

    const fetchDealerByCode = (dealerCode: string, countryCode = 'USA') => {
        return fetch(`${process.env.REACT_APP_API_HOST}/cvc/dd/dealers/list/dealercode/${dealerCode}/country/${countryCode}/from/1/to/10`)
            .then(handleFetchResponse)
            .then(data => data?.dealersDetailList)
            .then(data => data?.[0])
            .then(data => actions.dealersByCodeFetched(data))
            .catch(error => handleFetchError(error))
    }

    const fetchDealersByName = (dealerName: string, countryCode = 'USA') => {
        return fetch(`${process.env.REACT_APP_API_HOST}/cvc/dd/dealers/infos/dealername/${dealerName}/country/${countryCode}?dealername=${dealerName}&sk=test`)
            .then(handleFetchResponse)
            .then(data => data?.filter((dealer: Dealer) => dealer?.code))
            .then(dealers => dealers?.map((dealer: Dealer) => ({ ...dealer, ...dealerDetailInfoSample })))
            .then(data => actions.dealersByNameFetched({ data, countryCode, count: data?.length ?? 0 }))
            .catch(error => handleFetchError(error))
    }

    const fetchDealersByLocation = (lat: string, lon: string, countryCode: string = 'USA') => {
        return fetch(`${process.env.REACT_APP_API_HOST}/cvc/dd/dealers/detail/contact/lat/${lat}/lng/${lon}/from/1/to/10/country/${countryCode}`)
            .then(handleFetchResponse)
            .then(data => data?.filter((dealer: Dealer) => dealer?.code))
            .then(dealers => dealers?.map((dealer: Dealer) => ({ ...dealer, ...dealerDetailInfoSample })))
            .then(data => actions.dealersByNameFetched({ data, countryCode }))
            .catch(error => handleFetchError(error))
    }

    const fetchDealersByZip = (zipCode: string, countryCode: string = 'USA') => {
        return fetch(`${process.env.REACT_APP_API_HOST}/cvc/dd/dealers/infos/zipcode/${zipCode}/country/${countryCode}?zipcode=${zipCode}&sk=test`)
            .then(handleFetchResponse)
            .then(data => data?.filter((dealer: Dealer) => dealer?.code))
            .then(dealers => dealers?.map((dealer: Dealer) => ({ ...dealer, ...dealerDetailInfoSample })))
            .then(data => actions.dealersByNameFetched({ data, countryCode }))
            .catch(error => handleFetchError(error))
    }

    const fetchDealersByState = (stateCode: string, countryCode: string = 'USA') => {
        return fetch(`${process.env.REACT_APP_API_HOST}/cvc/dd/dealers/infos/state/${stateCode}/country/${countryCode}`)
            .then(handleFetchResponse)
            .then(data => data?.filter((dealer: Dealer) => dealer?.code))
            .then(dealers => dealers?.map((dealer: Dealer) => ({ ...dealer, ...dealerDetailInfoSample })))
            .then(data => actions.dealersByNameFetched({ data, countryCode }))
            .catch(error => handleFetchError(error))
    }

    const fetchDealerDetailsByName = (dealerName: string, page: number = 1, countryCode = 'USA') => {
        const [from, to] = getFromToByPage(page);
        return fetch(`${process.env.REACT_APP_API_HOST}/cvc/dd/dealers/list/dealername/${dealerName}/country/${countryCode}/from/${from}/to/${to}`)
            .then(handleFetchResponse)
            .then(data => actions.dealersDetailsFetched(data, page))
            .catch(error => handleFetchError(error))
    }

    const fetchDealerDetailsByLocation = (lat: string, lon: string, page: number = 1, countryCode: string = 'USA') => {
        const [from, to] = getFromToByPage(page);
        return fetch(`${process.env.REACT_APP_API_HOST}/cvc/dd/dealers/detail/lat/${lat}/lng/${lon}/from/${from}/to/${to}/country/${countryCode}`)
            .then(handleFetchResponse)
            .then(data => actions.dealersDetailsFetched(data, page))
            .catch(error => handleFetchError(error))
    }

    const fetchDealerDetailsByZip = (zipCode: string, page: number = 1, countryCode: string = 'USA') => {
        const [from, to] = getFromToByPage(page);
        return fetch(`${process.env.REACT_APP_API_HOST}/cvc/dd/dealers/list/zipcode/${zipCode}/country/${countryCode}/from/${from}/to/${to}`)
            .then(handleFetchResponse)
            .then(data => actions.dealersDetailsFetched(data, page))
            .catch(error => handleFetchError(error))
    }

    const fetchDealerDetailsByState = (stateCode: string, page: number = 1, countryCode: string = 'USA') => {
        const [from, to] = getFromToByPage(page);
        return fetch(`${process.env.REACT_APP_API_HOST}/cvc/dd/dealers/list/state/${stateCode}/country/${countryCode}/from/1/to/30`)
            .then(handleFetchResponse)
            .then(data => actions.dealersDetailsFetched(data, page))
            .catch(error => handleFetchError(error))
    }

    const fetchDealerFilters = () => {
        return fetch(`${process.env.REACT_APP_API_HOST}/cvc/dd/filters`, { cache: 'default' })
            .then(handleFetchResponse)
            .then(data => actions.dealerFiltersFetched(data, _selectDealerCodeMap(data)))
            .catch(error => handleFetchError(error))
    }

    const fetchIPInfo = () => {
        return fetch(`https://ipinfo.io/json?token=${process.env.REACT_APP_IP_INFO_TOKEN}`)
            .then(handleFetchResponse)
            .then(data => actions.ipInfoFetched(data))
            .catch(error => handleFetchError(error))
    }

    const filterDealers = (filters: Array<string>, filterConfig: FilterApiConfig, page: number = 1, countryCode: string = 'USA') => {
        const [from, to] = getFromToByPage(page);
        const { type, ...extras } = filterConfig;
        const body = {
            "latitude": state?.currentLocation?.lat, 
            "longitude": state?.currentLocation?.lng, 
            "fromCount": from, 
            "toCount": to, 
            "zoneId": state?.timeZone,
            "filterList": filters,
            "country": "USA",
            "dealerName" : "",
            zipCode: '',
            ...extras
        }
        const headers = {
            'Content-Type': 'application/json',
        }
        return fetch(`${process.env.REACT_APP_API_HOST}/cvc/dd/filterDealers/${type}`, { method: 'POST', headers, body: JSON.stringify(body) })
            .then(handleFetchResponse)
            .then(data => actions.dealersDetailsFetched(data, page))
            .catch(error => handleFetchError(error))
    }

    const _selectDealerCodeMap = (dealerFilters: DealerFilter[]) => {
        return dealerFilters?.reduce((acc, dealerFilter: DealerFilter) => {
            return {
                ...acc,
                codeMap: {
                    ...acc.codeMap,
                    [dealerFilter.groupCode]: dealerFilter.groupName,
                },
                dirMap: {
                    ...acc.dirMap,
                    [dealerFilter.dlrDirCode]: dealerFilter.dlrDirname,
                }
            }
        }, { codeMap: {}, dirMap: {} })
    }

    const setPage = (page: number = 1) => {
        return actions.changePage(page);
    }

    const setSearchProps = (searchProps: DealerSearchProps = defaultDealerSearchProps) => {
        return actions.changeSearch(searchProps)
    }

    const setDealer = (dealer: Dealer) => {
        return actions.setCurrentDealer(dealer);
    }

    const applyFilters = (filters: Array<string>) => {
        return actions.applyFilters(filters);
    }

    const selectDealerFilter = (dirCode: string) => {
        return state?.dealerFilters?.filter((filter: DealerFilter) => filter.dlrDirCode === dirCode);
    }

    const removeFilter = (filterId: string) => {
        return actions.removeFilter(filterId);
    }

    const addFilter = (filterId: string) => {
        return actions.addFilter(filterId);
    }

    const resetFilters = () => {
        return actions.resetFilters();
    }

    const _selectDealers = (dealers: Array<Dealer>, page: number, itemsCount: number, dealerFilters: DealerFilter[]): Array<Dealer> => {
        const startIndex = (page - 1) * itemsCount;
        const endIndex = startIndex + itemsCount;
        // return dealers?.slice(startIndex, endIndex);
        return dealers;
    }

    const _selectWorkHours = (dealer: any) => {
        return WEEK_DAYS?.reduce((acc, day) => {
            const is24Hrs = dealer?.[`${day}24Hrs`] === 'Y';
            const isClosed = dealer?.[`${day}Close`] === 'Y';
            const closeTime = dealer?.[`${day}CloseTime`];
            const closePeriod = dealer?.[`${day}ClosePeriod`];
            const openTime = dealer?.[`${day}OpenTime`];
            const openPeriod = dealer?.[`${day}OpenPeriod`];
            
            let str;
            if (isClosed) str = 'Closed';
            else if (is24Hrs) str = '24 Hours';
            else str = `${openTime ?? ''}${openPeriod ?? ''} - ${closeTime ?? ''}${closePeriod ?? ''}`

            return {
                ...acc,
                [day]: str,
            }
        }, {})
    }

    const _selectGroupWorkHours = (dealer: any, group: string) => {
        return CAP_WEEK_DAYS?.reduce((acc, day) => {
            const is24Hrs = dealer?.[`${group}${day}24Hrs`] === 'Y';
            const isClosed = dealer?.[`${group}${day}Close`] === 'Y';
            const closeTime = dealer?.[`${group}${day}CloseTime`];
            const closePeriod = dealer?.[`${group}${day}ClosePeriod`];
            const openTime = dealer?.[`${group}${day}OpenTime`];
            const openPeriod = dealer?.[`${group}${day}OpenPeriod`];
            
            let str;
            if (isClosed) str = 'Closed';
            else if (is24Hrs) str = '24 Hours';
            else str = `${openTime ?? ''}${openPeriod ?? ''} - ${closeTime ?? ''}${closePeriod ?? ''}`

            return {
                ...acc,
                [day]: str,
            }
        }, {})
    }

    const _selectDimension = (val1, val2) => {
        if (!val1 && !val2) return '';
        if (!val2) return `${val1}'`;
        if (!val1) return `0' ${val2}"`
        return `${val1}' ${val2}"`
    }

    const _selectBayDimensions = (dealer: any) => {
        return {
            openingHeight: _selectDimension(dealer?.ddVs27, dealer?.ddVs28),
            interiorBayHeight: _selectDimension(dealer?.ddVs29, dealer?.ddVs30),
            bayLength: _selectDimension(dealer?.ddVs31, dealer?.ddVs32),
            liftCapacity: dealer?.ddVs33,
        }
    }

    const _selectDealer = (dealer: Dealer, dealerFilters: DealerFilter[]) => {
        if (dealer) {
            const filerCodeMap = dealerFilters?.reduce((acc, cur) => {
                return {
                    ...acc,
                    [cur?.dlrDirCode]: cur?.dlrDirname
                }
            }, {});
            const dealerFilterKeys = Object.keys(dealer)
                ?.filter(filterKey => filterKey?.startsWith('cdd') && dealer?.[filterKey] === 'Y')
                ?.filter(filterKey => !filterKey?.startsWith('cddSls'))
                ?.reduce((acc, key) => {
                    const k = humps.decamelize(key)?.toUpperCase();
                    const arrKey = key?.startsWith('cddS') || key?.startsWith('cddA') ? 'vehiclesServiced' : 'otherServices';
                    return {
                        ...acc,
                        [arrKey]: [
                            ...acc?.[arrKey],
                            filerCodeMap?.[k] ?? '',
                        ]
                    }
                }, { vehiclesServiced: [], otherServices: [] });
            
            return {
                ...dealer,
                ...dealerFilterKeys,
                workHours: _selectWorkHours(dealer),
                salesWorkHours: _selectGroupWorkHours(dealer, 'sales'),
                serviceWorkHours: _selectGroupWorkHours(dealer, 'service'),
                partsWorkHours: _selectGroupWorkHours(dealer, 'parts'),
                bayDimensions: _selectBayDimensions(dealer),
            }
        }
        return dealer;
    }

    const _selectLocation = (ipInfo: IPInfo) => {
        const { loc } = ipInfo;
        const [lat, lng] = loc?.split(",")
        return { lat: Number(lat), lng: Number(lng) }
    }

    const _selectTimezone = (ipInfo: IPInfo) => {
        return ipInfo.timezone;
    }

    const dealerCtx: DealerContextInterface = {
        ...state,
        dealer: _selectDealer(state?.dealer, state?.dealerFilters),
        dealers: _selectDealers(state?.dealers, state?.currentPage, 10, state?.dealerFilters),
        setPage,
        setDealer,
        selectDealerFilter,
        setSearchProps,
        applyFilters,
        addFilter,
        removeFilter,
        resetFilters,
        filterDealers,
        fetchDealerByCode,
        fetchDealerFilters,
        fetchDealersByName,
        fetchDealersByLocation,
        fetchDealersByZip,
        fetchDealersByState,
        fetchDealerDetailsByName,
        fetchDealerDetailsByState,
        fetchDealerDetailsByLocation,
        fetchDealerDetailsByZip,
        fetchIPInfo
    }

    useEffect(() => {
        setPage(Number(currentPage));
    }, [currentPage]);

    return (
        <DealerContext.Provider value={dealerCtx}>
            <DealerMiddleware {...dealerCtx} />
            {children}
        </DealerContext.Provider>
    )
}

const useDealerInfo = (): DealerContextInterface => useContext(DealerContext) as DealerContextInterface;

export default DealerProvider;
export { useDealerInfo }