import { DEALER_ITEMS_PER_PAGE } from "../../pages/DealerView/constant";

export const isMobile = typeof navigator !== 'undefined' && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

// @ts-ignore
Array.prototype.unique = function () {
    var a = this.concat();
    for (var i = 0; i < a.length; ++i) {
        for (var j = i + 1; j < a.length; ++j) {
            if (a[i] === a[j])
                a.splice(j--, 1);
        }
    }

    return a;
}

export const isDefined = (value) => {
    return value !== undefined && value !== null
}

export function isObject(value) {
    const type = typeof value;
    return value != null && (type === 'object' || type === 'function')
}
export const ArraySplit = (arr, limit) => {
    const arrays = [[], []]
    arr.forEach((item, index) => {
        if (index < limit) {
            arrays[0].push(item)
        } else {
            arrays[1].push(item)
        }
    })
    return arrays
}

export const currencyFormat = (currency) => new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
});

export const formatPhoneNumber = (phoneNumber) => {
    var cleaned = ('' + phoneNumber).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
}

export const splitArrIntoChunk = (arr, chunkSize) => {
    return Array(
        Math
            .ceil(arr.length / chunkSize))
        .fill('')
        .map((_, i) => {
            return arr
                .slice(i * chunkSize, i * chunkSize + chunkSize)
        })
}


export const camelCaseToTitleCase = (camelCase) => camelCase
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim()

export const kebabCaseToCamelCase = (str) => {
    const result = str.replace(/-./g, x => x[1].toUpperCase())
    return result.charAt(0).toUpperCase() + result.slice(1)
}

export const camelCaseToKebabCase = (camelCase) => {
    return camelCase.split('').map((letter, idx) => {
        return letter.toUpperCase() === letter
            ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
            : letter
    }).join('')
}

export const kebabCaseToTitleCase = (kebabCase) => {
    return kebabCase
        .split('-')
        .map(word => {
            return word.slice(0, 1).toUpperCase() + word.slice(1)
        })
        .join(' ')
}

export const getWindowDimensions = () => {
    if (typeof window !== "undefined") {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        }
    } else {
        return {
            width: 1280,
            height: 1280
        }
    }
}

export const TimeHours = [
    {
        label: '1:00',
        value: '01:00:00'
    },
    {
        label: '2:00',
        value: '02:00:00'
    },
    {
        label: '3:00',
        value: '03:00:00'
    },
    {
        label: '4:00',
        value: '04:00:00'
    },
    {
        label: '5:00',
        value: '05:00:00'
    },
    {
        label: '6:00',
        value: '06:00:00'
    },
    {
        label: '7:00',
        value: '07:00:00'
    },
    {
        label: '8:00',
        value: '08:00:00'
    },
    {
        label: '9:00',
        value: '09:00:00'
    },
    {
        label: '10:00',
        value: '10:00:00'
    },
    {
        label: '11:00',
        value: '11:00:00'
    },
    {
        label: '12:00',
        value: '12:00:00'
    }
]

export const USStates = [
    {
        value: 'Alabama',
        label: 'AL'
    },
    {
        value: 'Alaska',
        label: 'AK'
    },
    {
        value: 'Arizona',
        label: 'AZ'
    },
    {
        value: 'Arkansas',
        label: 'AR'
    },
    {
        value: 'California',
        label: 'CA'
    },
    {
        value: 'Colorado',
        label: 'CO'
    },
    {
        value: 'Connecticut',
        label: 'CT'
    },
    {
        value: 'Delaware',
        label: 'DE'
    },
    {
        value: 'Florida',
        label: 'FL'
    },
    {
        value: 'Georgia',
        label: 'GA'
    },
    {
        value: 'Hawaii',
        label: 'HI'
    },
    {
        value: 'Idaho',
        label: 'ID'
    },
    {
        value: 'Illinois',
        label: 'IL'
    },
    {
        value: 'Indiana',
        label: 'IN'
    },
    {
        value: 'Iowa',
        label: 'IA'
    },
    {
        value: 'Kansas',
        label: 'KS'
    },
    {
        value: 'Kentucky',
        label: 'KY'
    },
    {
        value: 'Louisiana',
        label: 'LA'
    },
    {
        value: 'Maine',
        label: 'ME'
    },
    {
        value: 'Maryland',
        label: 'MD'
    },
    {
        value: 'Massachusetts',
        label: 'MA'
    },
    {
        value: 'Michigan',
        label: 'MI'
    },
    {
        value: 'Minnesota',
        label: 'MN'
    },
    {
        value: 'Mississippi',
        label: 'MS'
    },
    {
        value: 'Missouri',
        label: 'MO'
    },
    {
        value: 'Montana',
        label: 'MT'
    },
    {
        value: 'Nebraska',
        label: 'NE'
    },
    {
        value: 'Nevada',
        label: 'NV'
    },
    {
        value: 'New Hampshire',
        label: 'NH'
    },
    {
        value: 'New Jersey',
        label: 'NJ'
    },
    {
        value: 'New Mexico',
        label: 'NM'
    },
    {
        value: 'New York',
        label: 'NY'
    },
    {
        value: 'North Carolina',
        label: 'NC'
    },
    {
        value: 'North Dakota',
        label: 'ND'
    },
    {
        value: 'Ohio',
        label: 'OH'
    },
    {
        value: 'Oklahoma',
        label: 'OK'
    },
    {
        value: 'Oregon',
        label: 'OR'
    },
    {
        value: 'Pennsylvania',
        label: 'PA'
    },
    {
        value: 'Rhode Island',
        label: 'RI'
    },
    {
        value: 'South Carolina',
        label: 'SC'
    },
    {
        value: 'South Dakota',
        label: 'SD'
    },
    {
        value: 'Tennessee',
        label: 'TN'
    },
    {
        value: 'Texas',
        label: 'TX'
    },
    {
        value: 'Utah',
        label: 'UT'
    },
    {
        value: 'Vermont',
        label: 'VT'
    },
    {
        value: 'Virginia',
        label: 'VA'
    },
    {
        value: 'Washington',
        label: 'WA'
    },
    {
        value: 'West Virginia',
        label: 'WV'
    },
    {
        value: 'Wisconsin',
        label: 'WI'
    },
    {
        value: 'Wyoming',
        label: 'WY'
    }
];

export const getGeoLocation = async () => {
    return new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
            const errorMsg = `Your browser doensn't support GeoLocation`;
            console.warn(errorMsg);
            reject({ geoLocation: null, message: errorMsg });
        }

        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords || {};
                console.info(`Current Loation | Lat:${latitude}, Lon: ${longitude}`);
                resolve({ geoLocation: position.coords, timestamp: position.timestamp, message: '' })
            },
            () => {
                reject({ geoLocation: null, message: 'Unable to fetch GeoLocation' });
            }
        );
    })
}

export const getFromToByPage = (page: number) => {
    const from = (DEALER_ITEMS_PER_PAGE * (page - 1)) + page;
    const to = from + (DEALER_ITEMS_PER_PAGE - 1);
    return [from, to];
}

export const capitalize = (string: string) => {
    if (!string) return string;
    return string[0]?.toUpperCase() + string?.slice(1)?.toLowerCase()
};

export const capitalizeWords = (str: string) => {
    if (!str) return;
    // const arr = str?.split(/[\s/.-_]/);
    // if (!arr) return;
    // for (var i = 0; i < arr?.length; i++) { 
    //     arr[i] = capitalize(arr[i]);
    // }
    // return arr.join(" ");

    return str?.toLowerCase().replace(/(^|\s|-|\/)[a-z]/g, function(letter) {
        return letter.toUpperCase();
    })
}


