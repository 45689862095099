import React from 'react';
import { Col, Row, Tag } from '../../components/components';
import DealerBaseInfoView from './DealerBaseInfoView';
import DealerPhotoGallery from './DealerPhotoGallery';

const DealerPartDetails = (props: any) => {
    const { partsWorkHours, dealerEmail, partsEmail, phone } = props;

    return (
        <Row>
            <DealerBaseInfoView workHours={partsWorkHours} dealerEmail={dealerEmail} section='Parts' sectionEmail={partsEmail} phone={phone} />
        </Row>
    )
}

export default DealerPartDetails;