import React, { ReactNode, FC } from 'react';
import clsx from 'clsx';
import Tag from '../Tag/Tag';

interface ContainerShape {
    size?: boolean;
}

interface ContainerProps {
    tag?: string;
    fluid?: boolean;
    className?: string;
    style?: object;
    xs?: ContainerProps | boolean;
    sm?: ContainerProps | boolean;
    md?: ContainerProps | boolean;
    lg?: ContainerProps | boolean;
    xl?: ContainerProps | boolean;
    children?: ReactNode | JSX.Element | JSX.Element[] | string | string[];
}

const Container: FC<ContainerProps> = (props) => {
    const { tag = 'div', className, xs, sm, md, lg, xl, fluid, ...attributes } = props;

    const classes = [
        { 'fds-flex__container': true },
        { 'fds-flex__container--xs': xs },
        { 'fds-flex__container--sm': sm },
        { 'fds-flex__container--md': md },
        { 'fds-flex__container--lg': lg },
        { 'fds-flex__container--xl': xl },
    ];

    const ContainerClassName = clsx(className, classes);

    return <Tag tag={tag} {...attributes} className={ContainerClassName} />;
};

export default Container;
