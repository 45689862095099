const fdsBaseMultiplierRem =
    typeof getComputedStyle !== 'undefined' &&
    getComputedStyle(document.documentElement).getPropertyValue('--fds-base-multiplier-rem');

// Function to get rem values
function fdsRem(value, fontBase = fdsBaseMultiplierRem) {
    return value / parseInt(fontBase, 10);
}

export default fdsRem;
