import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { isDefined, isObject } from '../../utils/Helpers';
import Tag from '../Tag/Tag';

const cellWidths = ['xs', 'sm', 'md', 'lg', 'xl'];

interface CellObject {
    size: string | number;
    order?: string | number;
}

type SpacingType = string | number | boolean | object | object[];

interface CellProps {
    tag?: string;
    size?: boolean | number | string | CellObject;
    xs?: boolean | number | string | CellObject;
    sm?: boolean | number | string | CellObject;
    md?: boolean | number | string | CellObject;
    lg?: boolean | number | string | CellObject;
    xl?: boolean | number | string | CellObject;
    className?: string;
    padding?: SpacingType;
    margin?: SpacingType;
    widths?: string[];
    flexbox?: boolean;
    align?: 'top' | 'middle' | 'bottom';
    children?: ReactNode | JSX.Element | JSX.Element[] | string | string[];
}
const getCellSize = (cellWidth: string | boolean, cellSize: string | number, flexbox: boolean) => {
    const _cellWidth = isDefined(cellWidth) ? `-${cellWidth}` : '';
    const cellClass = flexbox ? 'fds-layout-flexbox__cell--span-' : 'fds-layout-grid__cell--span-';
    return `${cellClass}${cellSize}${_cellWidth}`;
};

const Cell: FC<CellProps> = (props) => {
    const {
        tag = 'div',
        widths = cellWidths,
        flexbox = false,
        className,
        size,
        align,
        ...attributes
    } = props;

    const cellClasses = [
        { 'fds-layout-grid__cell--align-top': align === 'top' },
        { 'fds-layout-grid__cell--align-middle': align === 'middle' },
        { 'fds-layout-grid__cell--align-bottom': align === 'bottom' },
        { [`fds-layout-grid__cell--span-${size}`]: Boolean(size) },
        { [`fds-layout-grid__cell`]: !Boolean(size) },
    ];

    widths.forEach((cellWidth: string) => {
        const cellProp: CellObject = props[cellWidth];
        delete attributes[cellWidth];

        if (!isDefined(cellProp)) {
            return;
        }

        if (isObject(cellProp)) {
            const cellClass: string = getCellSize(cellWidth, cellProp.size, flexbox);
            if (isDefined(cellProp.size)) {
                cellClasses.push({
                    [`${cellClass}`]: true,
                });
            }
            if (isDefined(cellProp.order)) {
                cellClasses.push({
                    [`fds-layout-grid__cell--order-${cellProp.order}`]: true,
                });
            }
        }
    });

    const CellClassName: string = clsx(className, cellClasses);

    return <Tag tag={tag} {...attributes} className={CellClassName} />;
};

export default Cell;
