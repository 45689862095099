import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { Row, Col, Tag } from '../';

type SpacingType = string | number | boolean | object | object[];

interface InputProps {
    id: string;
    tag?: 'input' | 'textarea';
    aria: string;
    noClass?: boolean;
    editable?: boolean;
    defaultValue?: string;
    className?: string;
    style?: any;
    labelClassName?: string;
    regex?: string;
    required?: boolean;
    type?: string;
    placeholder?: string;
    label?: string | JSX.Element | ReactNode;
    labelExt?: string | JSX.Element | ReactNode;
    margin?: SpacingType;
    padding?: SpacingType;
    fileUploadLabel?: string;
    media?: boolean;
    fileUpload?: boolean;
    hideInput?: boolean;
    disabled?: boolean;
    rows?: number;
    onFocusHandler?: () => void;
    wrapperAttributes?: object;
    cols?: number;
    value?: any;
    onChange?: any;
}

const Input: FC<InputProps> = (props) => {
    const {
        tag = 'input',
        type = 'text',
        media = false,
        editable = true,
        fileUpload = false,
        noClass = false,
        hideInput = false,
        fileUploadLabel,
        id,
        aria,
        label,
        labelExt,
        className = '',
        labelClassName = '',
        onFocusHandler = () => {},
        wrapperAttributes = {},
        ...attributes
    } = props;

    const classes = [
        { 'fmc-input': !noClass && type !== 'checkbox' },
        { 'fmc-check': type === 'checkbox' },
    ];

    const InputLabelClasses = [
        { 'fmc-type--content2 fds-color__text--gray2': type !== 'checkbox' },
        { 'fds-type--body1': type === 'checkbox' && labelClassName.indexOf('fmc-type--') < 0 },
    ];

    const InputClassName: string = clsx(className, classes);

    const InputLabelClassName: string = clsx(labelClassName, InputLabelClasses);

    const typeTagAttributes: object =
        tag === 'textarea'
            ? Object.assign(
                  {},
                  { tag, style: { minHeight: 180, lineHeight: '2.4rem', padding: '1.6rem' } },
                  attributes,
              )
            : Object.assign({}, { type, tag }, attributes);

    return (
        <Row
            tag={type !== 'checkbox' ? 'div' : 'ul'}
            className={'fmc-input--wrapper'}
            reverse={type === 'checkbox'}
            {...wrapperAttributes}
        >
            {media && (
                <Col
                    tag={type !== 'checkbox' ? 'div' : 'li'}
                    xs={12}
                    className={'fmc-cards__image'}
                    margin={{ dir: 'b', amt: 2 }}
                >
                    <Tag
                        tag={'img'}
                        className={'fds-img'}
                        src={'https://via.placeholder.com/1280x720'}
                        alt={'a placeholder'}
                    />
                </Col>
            )}
            {label && type !== 'checkbox' && (
                <Col
                    xs={12}
                    tag={'label'}
                    padding={[
                        { dir: 'b', amt: 1 },
                        { dir: 'l', amt: 0 },
                    ]}
                    className={InputLabelClassName}
                    aria-labelledby={id}
                >
                    {label}
                    {attributes?.required && (
                        <Tag tag={'span'} className={'fds-color--gray3'}>
                            *
                        </Tag>
                    )}
                    {labelExt}
                </Col>
            )}
            {label && type === 'checkbox' && editable && (
                <Col
                    xs={'auto'}
                    tag={'label'}
                    padding={[
                        { dir: 'b', amt: 1 },
                        { dir: 'l', amt: 0 },
                    ]}
                    className={InputLabelClassName}
                    aria-labelledby={id}
                >
                    {label}
                </Col>
            )}
            {editable && !hideInput && (
                <Col
                    xs={type !== 'checkbox' ? 12 : 1}
                    margin={{ dir: 'b', amt: 1 }}
                    id={id}
                    className={InputClassName}
                    aria-label={aria}
                    onFocus={() => {
                        onFocusHandler();
                    }}
                    {...typeTagAttributes}
                />
            )}
            {!editable && !hideInput && (
                <Col
                    tag={type !== 'checkbox' ? 'p' : 'li'}
                    xs={12}
                    margin={{ dir: 'b', amt: 2 }}
                    id={id}
                    className={
                        type === 'checkbox' && media
                            ? 'fds-type--body1 om-list-style--none'
                            : 'fds-type--body1'
                    }
                    aria-label={aria}
                    {...attributes}
                >
                    {type !== 'checkbox' ? props.defaultValue : label}
                </Col>
            )}
            {fileUpload && editable && (
                <Col xs={12} className={'fmc-file-upload'} padding={{ dir: 't', amt: 2 }}>
                    <Row>
                        <Col xs={12} className={'fmc-file-upload__label'}>
                            {fileUploadLabel}
                        </Col>
                        <Col xs={12} tag={'label'}>
                            <Tag tag={'input'} type="file" />
                            <Tag
                                className={'fmc-file-upload__text'}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexFlow: 'column',
                                }}
                            >
                                <Tag
                                    tag={'div'}
                                    className={'fmc-file-upload__drag-drop fds-align--center'}
                                >
                                    Drag and drop files here or
                                </Tag>
                                <Tag tag={'div'} className={'fmc-file-upload__select'}>
                                    Select Files
                                </Tag>
                            </Tag>
                        </Col>
                        <Col xs={12}>
                            <Row className={'fmc-file-upload__caption'}>
                                <Tag tag={'span'}> Document type: </Tag>
                                <Tag tag={'span'}>.jpg or .png (max 8MB)</Tag>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            )}
        </Row>
    );
};

export default Input;
