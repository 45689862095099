import React, { FC, Fragment, useRef, useContext, useEffect } from 'react';
import clsx from 'clsx';
import { Section, Row, Col, Tag, Button, Billboard } from '../';
import NavBar from '../Navigation/NavBar';
import Footer from '../Navigation/Footer';
import '../../scss/styles.scss';
import { Outlet, useNavigate } from 'react-router-dom';
import CommercialResourceSection from './Sections/CommercialResourceSection';
import { useMatches } from 'react-router';
import ScrollHandler from '../../../provider/ScrollHandler';
import { useDealerInfo } from '../../../provider/DealerProvider';
import DealerSearchForm from '../../../pages/DealerView/DealerSearchForm';
import { objToQueryString } from '../../utils/FetchUtils';
import { DO_NOT_SELL_INFO_URL, FOOTER_NAV_ITEMS, HEADER_NAV_ITEMS } from '../../../constants/appConstants';
import { WindowWidthContext } from '../../../contexts/WindowWidthContext';

interface LayoutProps {
    hideNav?: boolean;
    minimizedLayout?: boolean;
    footerBackground?: 'light' | 'dark' | 'none';
    children?: JSX.Element | JSX.Element[];
    onDealerSearch?: any;
}

const Layout: FC<LayoutProps> = (props) => {
    const { footerBackground = 'none' } = props;

    const { windowWidth, setWindowWidth } = useContext(WindowWidthContext);


    const nameRef = useRef('');
    const { setSearchProps, resetFilters, fetchIPInfo } = useDealerInfo();

    const matches = useMatches();
    const navigate = useNavigate();

    const isHomePage = matches?.filter((match: any) => Boolean(match?.handle?.isHome))
        ?.map((match: any) => match.handle?.isHome)?.[0];

    const minimizedLayout = !isHomePage;

    useEffect(() => {
        fetchIPInfo();
    }, [])
    
    // const debouncedSearchTerm = useDebounce(searchTerm, 500);
    // useEffect(() => {
    //     if (debouncedSearchTerm && fetchDealersByName) {
    //         console.log('Fetching API...', { search: debouncedSearchTerm });
    //         fetchDealersByName(debouncedSearchTerm);
    //     }
    // }, [debouncedSearchTerm]);

    const handleFormSubmit = (formData: any) => {
        const { zipCode: zip, dealerName: name, fromDistance, locationData, searchType } = formData || {};
        const lat = locationData?.lat ?? '';
        const lng = locationData?.lng ?? '';
        const maxDistance = zip ? fromDistance: '';

        resetFilters();
        const queryParams =  {
            zip,
            name,
            maxDistance,
            lat,
            lng,
            page: 1,
        };
        setSearchProps({ zip, name, maxDistance, searchType, lat, lng, page: 1, filters: [] })
        const url = `/find-a-dealer?${objToQueryString(queryParams)}`;
        navigate(url);
    }

    return (
        <Fragment>
            <ScrollHandler />
            <div className={''}>
                <NavBar navItems={HEADER_NAV_ITEMS} />
                <Billboard
                    scrim={'dark'}
                    backgroundImageUrl={
                        windowWidth >= 1024
                            ? '/assets/images/cvc-commercial-banner.png'
                            : '/assets/images/22_FRD_XBD_56688_PRO_no-guy.jpg'
                    }
                    scrimProps={'bottom'}
                    align={'end'}
                    justify={'center'}
                    style={{
                        maxHeight:
                            windowWidth >= 1024
                                ? minimizedLayout
                                    ? 570
                                    : 920
                                : minimizedLayout
                                ? 570
                                : 850,
                    }}
                    backgroundImageAttributes={{
                        style: {
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: `center ${minimizedLayout ? '0%' : '5%'}`,
                        },
                    }}
                >
                    {!minimizedLayout && (
                        <Fragment>
                            <Row justify={'center'} padding={{ dir: 'b', amt: 3 }}>
                                <Col xs={12} md={10} lg={8}>
                                    <Tag
                                        tag={'h1'}
                                        className={
                                            windowWidth >= 1024
                                                ? 'fmc-type--heading2 fds-color--white fds-align--center'
                                                : 'fmc-type--heading1 fds-color--white fds-align--center'

                                        }
                                    >
                                        Delivering what your business needs to succeed
                                    </Tag>
                                </Col>
                            </Row>
                            <Row justify={'center'} padding={{ dir: 'b', amt: 4 }}>
                                <Col xs={11} md={10} lg={8}>
                                    <Tag
                                        tag={'p'}
                                        className={
                                            'fmc-type--body1 fds-color--white fds-align--center fds-stretch--normal'
                                        }
                                    >
                                        With more than 650 dealers nationwide, you're sure to find a
                                        Ford Pro™ Commercial Vehicle Center nearby that’s staffed
                                        with experts who can deliver the vehicle sales, service and
                                        financing your business needs.
                                    </Tag>
                                </Col>
                            </Row>
                        </Fragment>
                    )}
                </Billboard>
                
                <DealerSearchForm minimizedLayout={minimizedLayout} onFormSubmit={handleFormSubmit} />

                <Outlet />

                <CommercialResourceSection windowWidth={windowWidth}  />
                
                <Section className={'fds-color__bg--gray1'}>
                    <Row padding={{ dir: 'y', amt: 2 }} justify={'center'}>
                        <Col xs={10} className={'fds-align--center'} padding={{ dir: 'y', amt: 1 }}>
                            <Tag
                                tag={'h6'}
                                className={
                                    'fmc-type--heading6 fds-weight--medium fds-color--primary'
                                }
                            >
                                California Residents
                            </Tag>
                        </Col>
                        <Col xs={10} className={'fds-align--center'} padding={{ dir: 'y', amt: 1 }}>
                            <Tag tag={'p'} className={'fmc-type--body1 fds-color--primary'}>
                                Exercise your rights under the California Consumer Privacy Act
                            </Tag>
                        </Col>
                        <Col
                            xs={12}
                            sm={10}
                            className={'fds-align--center'}
                            padding={[{ dir: 'y', amt: 2 }]}
                        >
                            <Button tag={'a'} href={DO_NOT_SELL_INFO_URL} target='_blank' style={{ padding: '2rem' }}>
                                Do Not Sell My Personal Information
                            </Button>
                        </Col>
                    </Row>
                </Section>

                <Footer background={footerBackground} siteNavItems={FOOTER_NAV_ITEMS} />
            </div>
        </Fragment>
    );
};

export default Layout;
