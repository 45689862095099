import React, { FC, Fragment } from "react"
import { Helmet } from "react-helmet"
import { Outlet } from "react-router-dom"
import {
    Section
} from '../../components/components/'
import { _dealerSampleData } from "./../DealerView/constant"

interface DealerStateViewProps {
    isDetail?: boolean;
}

const DealerStateView: FC = (props: DealerStateViewProps) => {

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: 'en', 'data-brand': 'ford' }}></Helmet>
            
            <Section style={{ minHeight: '80vh' }} className={'fds-overflow--auto'}>
                <Outlet />
            </Section>

            <Section />
        </Fragment>
    )
}

export default DealerStateView;