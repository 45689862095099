import React, { useState, useEffect } from 'react';
import { Button, Section, Select, Row, Col, Tag, Input } from '../../components/components';
import { useNavigate, Form, useSearchParams } from 'react-router-dom';
import { DelerMilesFromOptions } from './../DealerView/constant';
import { getGeoLocation } from '../../components/utils/Helpers';
import { DealerSearchFormProps, DealerSearchType } from './types';
import AsyncSelect from '../../components/components/Input/AsyncSelect';
import { ROUTE_DEALERS_STATE_LIST } from '../../router';

const DealerSearchForm = (props: DealerSearchFormProps) => {
    const { minimizedLayout, onFormSubmit } = props;

    // const { name, zip, lat, lon, from } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const lat = searchParams.get('lat');
    const lng = searchParams.get('lng');
    const zip = searchParams.get('zip');
    const name = searchParams.get('name');
    const maxDistance = searchParams.get('maxDistance');
    const page = searchParams.get('page') || 1;
    const defLocationData = lat ? { lat, lng } : null;

    const [searchType, setSearchType] = useState(DealerSearchType.CurrentLocation);
    const [locationData, setLocationData] = useState(defLocationData);
    const [zipCode, setZipcode] = useState(zip ?? '');
    const [dealerName, setDealerName] = useState(name ?? '');
    const [fromDistance, setFromDistance] = useState(maxDistance ?? DelerMilesFromOptions?.[0]?.value);

    const navigate = useNavigate();

    const handleGetLocation = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
        // console.log('Get Current Location');
        setSearchType(DealerSearchType.CurrentLocation);
        setDealerName('');
        setZipcode('');                                 
        setFromDistance(DelerMilesFromOptions?.[0]?.value)
        if (locationData) {
            handleFormSubmit(null, locationData);
            return;
        }
        getGeoLocation().then((location: any) => {
            const loc = { lat: location?.geoLocation?.latitude, lng: location?.geoLocation?.longitude};
            handleFormSubmit(null, loc);
            setLocationData(loc);
        }).catch(e => console.log(e));
    }

    const handleDealerNameSearch = (e: React.MouseEvent<HTMLInputElement>) => {
        // console.log('On Name Search');
        const dealerName = e.currentTarget.value;
        setDealerName(dealerName);
        setSearchType(DealerSearchType.Name);
        setZipcode('');
        setLocationData(null);
        setFromDistance(DelerMilesFromOptions?.[0]?.value)
    }

    const handleZipDistanceChange = (e) => {
        // console.log(e.currentTarget.value);
        const dstFrom = e.currentTarget.value;
        setFromDistance(dstFrom);
        setDealerName('');
    }

    const handleDealerZipSearch = (e: React.MouseEvent<HTMLInputElement>) => {
        // console.log('On Location Search');
        const zipcode = e.currentTarget.value;
        if (zipcode?.length > 5) return;
        setZipcode(zipcode);
        setDealerName('');
        setLocationData(null);
        setSearchType(DealerSearchType.ZipCode);
    }

    const handleOnStateSearch = (e) => {
        e.preventDefault();
        e.stopPropagation();
        navigate(ROUTE_DEALERS_STATE_LIST);
    }

    const handleFormSubmit = (e, locationData: any = null) => {
        // console.log("On Form Submit", e);
        e?.preventDefault();
        if (locationData) {
            onFormSubmit({ searchType: '', zipCode: '', dealerName: '', fromDistance: '', locationData });
            return;
        }
        onFormSubmit({ searchType, zipCode, dealerName, fromDistance, locationData: null })
    }

    return (
        <>
            <Section light id="find-a-dealer">
                <Form id="form-dealer-search" onSubmit={handleFormSubmit}>
                    <Row
                        justify={!minimizedLayout ? 'center' : 'start'}
                        padding={{ dir: 'y', amt: !minimizedLayout ? 3 : 2 }}
                    >
                        {!minimizedLayout && (
                            <Col xs={12} padding={{ dir: 'b', amt: 3 }}>
                                <Tag
                                    tag={'h2'}
                                    className={
                                        'fds-align--center fmc-type--heading3 fds-color--primary'
                                    }
                                >
                                    Find a Ford Pro&trade; dealer that specializes in serving businesses like yours
                                </Tag>
                            </Col>
                        )}
                        <Col xs={12} lg={!minimizedLayout ? 'auto' : 4} padding={{ dir: 't', amt: 1 }}>
                            <Row>
                                <Col
                                    xs={12}
                                    padding={[
                                        { dir: 'x', amt: 0 },
                                        { dir: 'b', amt: 1 },
                                    ]}
                                >
                                    <Tag tag={'h6'} className={'fmc-type--heading6 fds-color--primary'}>
                                        Search by Dealer Name
                                    </Tag>
                                </Col>
                                <Col xs={12} padding={0}>
                                    <Input
                                        label={'Dealer Name:'}
                                        id={'CVCConsumerSiteSearchByDealerName'}
                                        aria={'Search by Dealer Name'}
                                        labelClassName={'fds-color--primary'}
                                        style={{ backgroundColor: 'white' }}
                                        value={dealerName}
                                        onChange={handleDealerNameSearch}
                                    />
                                    {/* <AsyncSelect className='cvc-dealer__select' label={'Dealer Name:'} labelClassName={'fds-color--primary'} /> */}
                                </Col>
                            </Row>
                        </Col>
                        {!minimizedLayout && (
                            <Col
                                xs={12}
                                lg={1}
                                padding={{ dir: 'y', amt: 2 }}
                                className={
                                    'fds-align--center fds-flex fds-flex__justify--center fds-flex__items--center'
                                }
                            >
                                <Tag tag={'h6'} className={'fmc-type--heading6 fds-color--primary'}>
                                    or
                                </Tag>
                            </Col>
                        )}
                        <Col xs={12} lg={!minimizedLayout ? 'auto' : 4} padding={[{ dir: 't', amt: 1 }]}>
                            <Row>
                                <Col
                                    xs={12}
                                    padding={[
                                        { dir: 'x', amt: 0 },
                                        { dir: 'b', amt: 1 },
                                    ]}
                                >
                                    <Tag tag={'h6'} className={'fmc-type--heading6 fds-color--primary'}>
                                        Search by Zip
                                    </Tag>
                                </Col>
                                <Col xs={12} padding={0}>
                                    <Row>
                                        <Col xs={8} lg={4} padding={{ dir: 'l', amt: 0 }}>
                                            <Select
                                                id='CVCConsumerSiteSearchByDealerFromDistance'
                                                name='CVCConsumerSiteSearchByDealerFromDistance'
                                                className="input-no-control"
                                                placeholder={'Miles from'}
                                                label="Distance from:"
                                                options={DelerMilesFromOptions}
                                                value={fromDistance}
                                                onChange={handleZipDistanceChange}
                                            />
                                        </Col>
                                        <Col xs={4} lg={4} padding={[
                                            { dir: 'r', amt: 0 },
                                            { dir: 'l', amt: 2 },
                                        ]}>
                                            <Input
                                                id={'CVCConsumerSiteSearchByDealerZipCode'}
                                                className='input-no-control'
                                                value={zipCode}
                                                label={'Zip:'}
                                                type={'number'}
                                                labelClassName={'fds-color--primary'}
                                                style={{ backgroundColor: 'white' }}
                                                aria={'Search by Dealer ZipCode'}
                                                onChange={handleDealerZipSearch}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} padding={0} className={'fds-flex'}>
                                    <Tag
                                        tag={'a'}
                                        href={'#'}
                                        target={'_blank'}
                                        padding={{ dir: 't', amt: 1 }}
                                        className={'fds-decoration--underline fds-color--primary'}
                                        onClick={handleGetLocation}
                                    >
                                        <Tag
                                            tag={'span'}
                                            className={'om-fds-icon--24 fds-font--ford-icons-location'}
                                        />
                                        <Tag tag={'span'} padding={{ dir: 'l', amt: 3 }}>
                                            Use current location
                                        </Tag>
                                    </Tag>
                                </Col>
                            </Row>
                        </Col>
                        {true && (
                            <Col xs={12} padding={[{ dir: 't', amt: 3 }]} className='fds-flex fds-flex__justify--center' hide={['lg', 'xl']}>
                                <Button rightIcon onClick={handleFormSubmit} disabled={!dealerName && !zipCode}>
                                    Search Dealers
                                </Button>
                            </Col>
                        )}
                        {!minimizedLayout && (
                            <Col
                                xs={12}
                                padding={[
                                    { dir: 't', amt: 4 },
                                    { dir: 'b', amt: 2 },
                                ]}
                                className={'fds-align--center'}
                            >
                                <Tag
                                    tag={'a'}
                                    href={'/dealers/states'}
                                    className={
                                        'fds-weight--medium fds-decoration--underline fds-color--primary'
                                    }
                                    onClick={handleOnStateSearch}
                                >
                                    <Tag tag={'span'} margin={{ dir: 'l', amt: 1 }}>
                                        See a list of all Commercial Vehicle Centers by state.
                                    </Tag>
                                </Tag>
                                <Tag
                                    tag={'p'}
                                    padding={{ dir: 't', amt: 3 }}
                                    className={'fmc-type--content3 fds-weight--medium fds-color--primary'}
                                >
                                    Need assistance finding a dealer? Contact the Ford Pro™ Commercial
                                    Vehicle Center Program Headquarters at{' '}
                                    <Tag
                                        tag={'a'}
                                        href={'tel:18882764088'}
                                        className={'fds-color--gray2 fds-decoration--none'}
                                    >
                                        1-888-276-4088
                                    </Tag>{' '}
                                    or{' '}
                                    <Tag
                                        tag={'a'}
                                        href={'mailto:CVCHQ@FordProgramHQ.com'}
                                        className={'fds-color--gray2 fds-decoration--none'}
                                    >
                                        CVCHQ@FordProgramHQ.com
                                    </Tag>
                                </Tag>
                            </Col>
                        )}
                        <button className='fds-hide' type='submit'>Submit</button>
                    </Row>
                </Form>
            </Section>
        </>
    )
}

export default DealerSearchForm;
