import React from 'react';
import { Col, Row, Tag } from '../../components/components';
import ImageCarousel from '../../components/components/ImageCarousel';
import InfoList from './InfoList';
import DealerBaseInfoView from './DealerBaseInfoView';
import DealerPhotoGallery from './DealerPhotoGallery';


const BayDimensions = [
    { key: 'openingHeight', label: 'Opening Height', unit: '' },
    { key: 'interiorBayHeight', label: 'Interior Bay Height', unit: '' },
    { key: 'bayLength', label: 'Bay Length', unit: '' },
    { key: 'liftCapacity', label: 'Lift Capacity (in Tons)', unit: ' Tons' },
]

const SERVICE_EXCLUDE_LIST = [
    "ON-SITE LOANER/RENTAL VEHICLE",
]

const DealerServiceDetails = (props: any) => {
    const { serviceWorkHours, dealerEmail, serviceEmail, otherServices, phone, bayDimensions, vehiclesServiced, courtesyDeliveryName, courtesyDeliveryPhone } = props;
    const dimensions = BayDimensions?.map((dimension) => {
        const unit = dimension?.key === 'liftCapacity' ? ' Tons' : '`';
        const val = bayDimensions?.[dimension?.key] ? `${bayDimensions?.[dimension?.key]}${dimension?.unit}` : '-';
        return `${dimension?.label}: ${val}`;
    });
    const filteredOtherServices = otherServices?.filter(otSrvc => !SERVICE_EXCLUDE_LIST?.includes(otSrvc));
    let tempProp = []
    if (filteredOtherServices && filteredOtherServices.length) {
        tempProp = [...filteredOtherServices]
        tempProp.sort()
    }

    return (
        <Row>
            <DealerBaseInfoView workHours={serviceWorkHours} dealerEmail={dealerEmail} section='Service' sectionEmail={serviceEmail} phone={phone} />
            <Col xs={12} lg={6}>
                <Row>
                    <Col xs={12}>
                        <InfoList title="Vehicles Serviced" list={vehiclesServiced} splitColumn />
                    </Col>
                </Row>
                <Row padding={{ dir: 't', amt: 3 }}>
                    <Col xs={12}>
                        <InfoList title="Dealership Service Bay Dimensions" list={dimensions} />
                    </Col>
                </Row>
            </Col>
            <Col xs={12} lg={3} className='cvc-dealer-det-oth-services'>
                <Row>
                    <Col xs={12}>
                        <InfoList title="Other Services" list={tempProp} extras={{ courtesyDeliveryName, courtesyDeliveryPhone }} />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default DealerServiceDetails;