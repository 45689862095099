import React, { FC, Fragment, ReactNode } from 'react';
import { Tag, Grid, Inner, Cell, Button, Row, Col, Container } from '../';

import { isDefined } from '../../utils/Helpers';

interface CTAProps {
    aria?: string;
    label?: string;
    href?: string;
}

interface NavItem {
    href?: string;
    label: string;
    aria?: string;
    rightIcon?: string;
    childNavItem?: boolean;
}
interface SubMenuItemProps {
    cta?: CTAProps[];
    menuOpened?: boolean;
}

interface MenuItemProps {
    aria?: string;
    label?: string;
    href?: string;
    dropdown?: boolean;
    last?: boolean;
    cta?: CTAProps[];
    menuOpened?: boolean;
    setMenuOpenCallBack: () => void;
}

type HideType = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

interface NavBarProps {
    navItems: NavItem[];
    tag?: string;
    menuOpened: boolean;
    setMenuOpenCallBack: (toggle: boolean) => void;
    hide?: HideType[];
    children?: ReactNode | JSX.Element | JSX.Element[] | string | string[];
}

const MobileSubNavMenuItem: FC<SubMenuItemProps> = (props) => {
    const { cta, menuOpened, ...attributes } = props;

    return (
        <Fragment>
            {isDefined(cta) && (
                <Row
                    className={`om-fmc__navbar--mobile__subnav-menu${menuOpened ? ' opened' : ''}`}
                >
                    {Array.isArray(cta) &&
                        cta.map(({ href, aria, label }) => {
                            return (
                                <Fragment key={Math.random()}>
                                    <Col
                                        xs={12}
                                        padding={[
                                            { dir: 'y', amt: 1 },
                                            { dir: 'x', amt: 3 },
                                        ]}
                                        className={
                                            'fmc-divider--bottom om-fmc-divider--primary fds-color__bg--gray1'
                                        }
                                    >
                                        <Button
                                            tag={'a'}
                                            href={href}
                                            aria-label={aria}
                                            buttonClassName={'fds-align--center'}
                                            padding={[
                                                { dir: 'y', amt: 2 },
                                                { dir: 'x', amt: 2 },
                                            ]}
                                            textButton
                                            {...attributes}
                                        >
                                            {label}
                                        </Button>
                                    </Col>
                                </Fragment>
                            );
                        })}
                </Row>
            )}
        </Fragment>
    );
};

const MobileNavBarMenuItem: FC<MenuItemProps> = (props) => {
    const {
        label,
        href,
        dropdown,
        aria,
        cta,
        last = false,
        setMenuOpenCallBack,
        menuOpened,
    } = props;
    const _attributes = Object.assign(
        {},
        dropdown ? { rightIcon: 'fds-font--ford-icons__chevron-down' } : {},
        !isDefined(cta) ? { href, tag: 'a' } : {},
    );
    return (
        <Fragment>
            <Col xs={12} className={'fds-flex--center fds-flex-direction--column'} padding={0}>
                <Row className={last ? '' : 'fmc-divider--bottom'} margin={{ dir: 'x', amt: 2 }}>
                    <Col
                        xs={12}
                        padding={[
                            { dir: 'y', amt: 1 },
                            { dir: 'x', amt: 3 },
                        ]}
                    >
                        <Button
                            aria-label={aria}
                            buttonClassName={'fds-align--left om-fmc__navbar--mobile__nav-dropdown'}
                            padding={{ dir: 'y', amt: 2 }}
                            textButton
                            onClick={() => {
                                setMenuOpenCallBack();
                            }}
                            {..._attributes}
                        >
                            {label}
                        </Button>
                    </Col>
                </Row>
                <MobileSubNavMenuItem menuOpened={menuOpened} cta={cta} />
            </Col>
        </Fragment>
    );
};

const MobileNavBar: FC<NavBarProps> = (props) => {
    const { tag = 'header', navItems, menuOpened, setMenuOpenCallBack } = props;

    return (
        <Fragment>
            <Grid
                tag={'nav'}
                className={'om-fmc__navbar--mobile-grid'}
                padding={{ dir: 'y', amt: 1 }}
            >
                <Inner>
                    <Cell size={12}>
                        <Container
                            className={'om-fmc__navbar--mobile-container'}
                            style={{ maxHeight: 80 }}
                        >
                            <Row justify={'center'}>
                                <Col xs={12} lg={12} xl={8}>
                                    <Row>
                                        <Col xs={12}>
                                            <Row>
                                                <Col
                                                    xs={6}
                                                    md={4}
                                                    tag={'a'}
                                                    href={'/'}
                                                    aria-label={'Link to Home Page'}
                                                >
                                                    <Tag
                                                        tag={'img'}
                                                        className={'fds-img'}
                                                        src={'/assets/images/ford_cvc-logo.png'}
                                                        alt={'Logo'}
                                                        style={{ maxHeight: 80 }}
                                                    />
                                                </Col>
                                                <Col xs={'auto'} />
                                                <Col
                                                    xs={2}
                                                    className={
                                                        'fds-flex--center fds-flex-direction--column'
                                                    }
                                                    padding={{ dir: 'x', amt: 0 }}
                                                >
                                                    <Tag
                                                        tag={'button'}
                                                        onClick={() => {
                                                            setMenuOpenCallBack(true);
                                                        }}
                                                        aria-label={'Close Mobile Navigation'}
                                                        className={
                                                            'onemag-fds__navbar--mobile__nav-button onemag-fds__navbar--mobile-nav-button--closed'
                                                        }
                                                    >
                                                        <Tag
                                                            style={{ lineHeight: '1.2rem' }}
                                                            aria-hidden={true}
                                                            className={
                                                                'fds-icon fds-icon--48 fds-align--center fds-font--ford-icons__minus'
                                                            }
                                                        />
                                                        <Tag
                                                            style={{ lineHeight: '1.2rem' }}
                                                            aria-hidden={true}
                                                            className={
                                                                'fds-icon fds-icon--48 fds-align--center fds-font--ford-icons__minus'
                                                            }
                                                        />
                                                        <Tag
                                                            style={{ lineHeight: '1.2rem' }}
                                                            aria-hidden={true}
                                                            className={
                                                                'fds-icon fds-icon--48 fds-align--center fds-font--ford-icons__minus'
                                                            }
                                                        />
                                                    </Tag>
                                                    <Tag
                                                        tag={'button'}
                                                        onClick={() => {
                                                            setMenuOpenCallBack(false);
                                                        }}
                                                        aria-label={'Open Mobile Navigation'}
                                                        className={
                                                            'onemag-fds__navbar--mobile__nav-button onemag-fds__navbar--mobile-nav-button--opened'
                                                        }
                                                    >
                                                        <Tag
                                                            aria-hidden={true}
                                                            className={
                                                                'fds-icon fds-icon--32 fds-align--center fds-font--ford-icons__clear'
                                                            }
                                                        />
                                                    </Tag>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col
                                            xs={12}
                                            className={'om-fmc__navbar--mobile__nav-wrapper'}
                                            padding={[
                                                { dir: 'x', amt: 0 },
                                                { dir: 'b', amt: 4 },
                                            ]}
                                        >
                                            <Row>
                                                {navItems.map((menuItem, idx, { length }) => {
                                                    return (
                                                        <Fragment key={Math.random()}>
                                                            <MobileNavBarMenuItem
                                                                menuOpened={menuOpened}
                                                                setMenuOpenCallBack={() => {
                                                                    setMenuOpenCallBack(
                                                                        !menuOpened,
                                                                    );
                                                                }}
                                                                last={idx + 1 === length}
                                                                {...menuItem}
                                                            />
                                                        </Fragment>
                                                    );
                                                })}
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </Cell>
                </Inner>
            </Grid>
        </Fragment>
    );
};

export default MobileNavBar;
