import React, { useEffect, useState } from 'react';
import { RouterProvider } from "react-router-dom";
import appRouter from './router';

import { WindowWidthContext } from './contexts/WindowWidthContext';
import { useMemo } from 'react';
import { getWindowDimensions } from './components/utils/Helpers';


const App = () => {
    const [windowWidth, setWindowWidth] = useState(getWindowDimensions().width);

    useEffect(() => {
        const handleResize = function handleResize() {
            const { width } = getWindowDimensions();
            setWindowWidth(width);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [windowWidth]);

    const windowWidthValue = useMemo(
        () => ({ windowWidth, setWindowWidth }),
        [windowWidth, setWindowWidth],
    );

    return (
        <>
            <WindowWidthContext.Provider value={windowWidthValue}>
                <RouterProvider router={appRouter} />
            </WindowWidthContext.Provider>
        </>
    )
}

export default App;