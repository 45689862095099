import React from 'react';
import { Col, Row, Tag } from '../../components/components';
import { formatPhoneNumber } from '../../components/utils/Helpers';
import { CAP_WEEK_DAYS } from '../../constants/appConstants';
import humps from 'humps';

const DealerBaseInfoView = (props) => {
    const { workHours, dealerEmail, phone, section, sectionEmail } = props;
    return (
        <>
            <Col xs={12} lg={3}>
                <Row>
                    <Col xs={12} padding={{ dir: 'b', amt: 3 }}>
                        <Tag
                            tag={'h6'}
                            padding={{ dir: 'b', amt: 1 }}
                            className={'fds-color--primary fmc-type--heading6'}
                        >
                            Phone:
                        </Tag>
                        <Tag
                            tag={'p'}
                            className={'fds-type--body1 fds-color--gray3'}
                            href={`mailto:${phone}`}
                        >
                            {formatPhoneNumber(phone)}{' '}
                        </Tag>
                    </Col>
                    <Col xs={12} padding={{ dir: 'b', amt: 3 }}>
                        <Tag
                            tag={'h6'}
                            padding={{ dir: 'b', amt: 1 }}
                            className={'fds-color--primary fmc-type--heading6'}
                        >
                            Email:
                        </Tag>
                        <Tag
                            tag={'p'}
                            className={
                                'fds-color--gray3 fmc-type--body1 fds-weight--bold'
                            }
                        >
                            Main:
                        </Tag>
                        <Tag
                            tag={'a'}
                            className={'fds-type--body1 fds-color--gray3 cvc-word-wrap-break'}
                            href={`mailto:${dealerEmail}`}
                        >
                            {dealerEmail}
                        </Tag>
                        <Tag
                            tag={'p'}
                            padding={{ dir: 't', amt: 1 }}
                            className={
                                'fds-color--gray3 fmc-type--body1 fds-weight--bold'
                            }
                        >
                            {section}:
                        </Tag>
                        <Tag
                            tag={'a'}
                            className={'fds-type--body1 fds-color--gray3 cvc-word-wrap-break'}
                            href={`mailto:${sectionEmail}`}
                        >
                            {sectionEmail ?? '-'}
                        </Tag>
                        <br />
                    </Col>
                    <Col xs={12} padding={{ dir: 'b', amt: 3 }}>
                        <Tag
                            tag={'h6'}
                            padding={{ dir: 'b', amt: 1 }}
                            className={'fds-color--primary fmc-type--heading6'}
                        >
                            Hours:
                        </Tag>
                        {CAP_WEEK_DAYS?.map((day, _idx) => (
                            <React.Fragment key={_idx}>
                                <Tag
                                    tag={'p'}
                                    className={
                                        'fds-type--body1 fds-color--gray3 fds-weight--bold'
                                    }
                                >
                                    {' '}
                                    {humps.pascalize(day)}{' '}
                                </Tag>
                                <Tag
                                    tag={'p'}
                                    padding={{ dir: 'b', amt: 1 }}
                                    className={'fds-type--body1 fds-color--gray3'}
                                >
                                    {' '}
                                    {workHours?.[day]}{' '}
                                </Tag>
                            </React.Fragment>
                        ))}
                    </Col>
                </Row>
            </Col>
        </>
    )
};

export default DealerBaseInfoView;