import React, { forwardRef, FC, ReactNode, HTMLAttributes, ElementType } from 'react';
import clsx from 'clsx';
import { isObject, isDefined } from '../../utils/Helpers';

enum FlexHideClasses {
    xs = 'fds-hide--xs',
    sm = 'fds-hide--sm',
    md = 'fds-hide--md',
    lg = 'fds-hide--lg',
    xl = 'fds-hide--xl',
    mobile = 'fds-hide--mobile',
    desktop = 'fds-hide--desktop',
    hide = 'fds-hide',
    hidden = 'fds-hidden',
    visually = 'fds-hide--visually',
}

enum SpacingTypeEnum {
    padding = 'p',
    margin = 'm',
}

type SpacingType = string | number | boolean | object | object[];

interface TagCompProps extends HTMLAttributes<HTMLOrSVGElement> {
    tag?: ElementType | string;
    type?: string;
    className?: string;
    checked?: boolean;
    value?: any;
    rel?: string;
    href?: string;
    name?: string;
    regex?: string;
    required?: boolean;
    disabled?: boolean;
    target?: string;
    margin?: SpacingType;
    padding?: SpacingType;
    hide?: any;
    src?: string;
    alt?: string;
    tabIndex?: any;
    ref?: any; //define later
    children?: ReactNode | JSX.Element | JSX.Element[] | string | string[];
}

const getSpacingClass = (props, type) => {
    const spacingType: string = SpacingTypeEnum[type];

    const result: any = [];

    const formatPropData = (prop: object, type: string) => {
        const direction = prop.hasOwnProperty('dir') ? prop['dir'] : null;
        const amount = prop['amt'];
        const breakpoint = prop.hasOwnProperty('size') ? `fds-${prop['size']}:` : '';

        if (!isDefined(direction)) {
            return `${breakpoint}fds-${type}--x-${amount} ${breakpoint}fds-${type}--y-${amount}`;
        } else {
            return `${breakpoint}fds-${type}--${direction}-${amount}`;
        }
    };

    if (Array.isArray(props) && props.length > 0) {
        props.forEach((prop) => {
            result.push(formatPropData(prop, spacingType));
        });
    } else if (isObject(props)) {
        result.push(formatPropData(props, spacingType));
    } else {
        result.push(`fds-${spacingType}--x-${props} fds-${spacingType}--y-${props}`);
    }

    return result;
};

const Tag: FC<TagCompProps> = forwardRef((props, ref) => {
    const {
        tag: TagComponent = 'div',
        children,
        className,
        margin = false,
        padding = false,
        hide = false,
        ...attributes
    } = props;
    const classes: string[] = [];

    if (typeof margin !== 'boolean') {
        classes.push(getSpacingClass(margin, 'margin')); //not correct typing
    }

    if (typeof padding !== 'boolean') {
        classes.push(getSpacingClass(padding, 'padding')); //not correct typing
    }

    if (isDefined(hide)) {
        if (Array.isArray(hide)) {
            hide.forEach((prop) => {
                classes.push(FlexHideClasses[prop]);
            });
        } else {
            classes.push(FlexHideClasses[hide]);
        }
    }

    const TagComponentClassName = clsx(classes, className);

    const newAttributes = Object.assign(
        {},
        TagComponentClassName.length > 0 ? { className: TagComponentClassName } : {},
        attributes,
    );

    return (
        <TagComponent ref={ref} {...newAttributes}>
            {children}
        </TagComponent>
    );
});

export default Tag;
