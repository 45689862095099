import React, { FC, Fragment } from "react"
import { Helmet } from "react-helmet"
import {
    Section,
} from '../components/components/'
import { useDealerInfo } from "../provider/DealerProvider"
import { _dealerSampleData } from "./DealerView/constant"

import DealerView from "./DealerView/index"

const FindADealer: FC = () => {
    const { fetchDealersByName, dealers } = useDealerInfo();

    const onDealerSearch = (query: string) => {
        fetchDealersByName(query);
    }

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: 'en', 'data-brand': 'ford' }}>
            </Helmet>
            <DealerView dealers={dealers} />
            <Section />
        </Fragment>
    )
}

export default FindADealer