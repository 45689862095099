import React, { FC, Fragment } from 'react';
import clsx from 'clsx';
import { Tag } from '../';

interface CTA {
    href: string;
    label: string;
    disabled?: boolean;
}

interface PaginationProps {
    cta?: CTA[];
    currentCTA?: number;
    ariaLabel: string;
    previousLabel?: string;
    nextLabel?: string;
    className?: string;
    isMobile?: boolean;
    limit?: number | boolean;

    // totalPages?: number;
    itemsCount?: number;
    itemsPerPage?: number;
    currrentPage: number;
    onPageChange?: (newPage: number) => void;
}

const Pagination: FC<PaginationProps> = (props) => {
    const {
        cta,
        className,
        currentCTA,
        ariaLabel,
        isMobile = false,
        previousLabel = 'Prev',
        nextLabel = 'Next',
        limit = false,

        // totalPages = 1,
        itemsCount = 10,
        currrentPage,
        itemsPerPage = 10,
        onPageChange = () => {},

        ...attributes
    } = props;
    
    const totalPage = Math.ceil(itemsCount / itemsPerPage);
    const classes = ['fmc-pagination'];

    const ClassName = clsx(classes, className);

    const ctaPrevClasses = [
        { 'fmc-pagination__disabled': currrentPage === 1 },
        'fmc-pagination__prev cvc-cursor--pointer',
    ];

    const ctaNextClasses = [
        { 'fmc-pagination__disabled': currrentPage === totalPage },
        'fmc-pagination__next cvc-cursor--pointer',
    ];


    const moveToNext = () => {
        if (currrentPage <= totalPage) {
            onPageChange(currrentPage + 1);
        }
    }

    const moveToPrev = () => {
        if (currrentPage !== 1) {
            onPageChange(currrentPage - 1);
        }
    }

    if (totalPage <= 1) {
        return <></>
    }

    return (
        <Tag tag={'nav'} aria-label={ariaLabel} {...attributes}>
            <Tag tag={'ul'} className={ClassName}>
                <Tag tag={'li'} className={'fmc-pagination__item'}>
                    <Tag
                        tag={'span'}
                        rel={'prev'}
                        href={'#'}
                        title={'Previous Page'}
                        className={clsx(ctaPrevClasses)}
                        disabled={currrentPage === 1}
                        onClick={moveToPrev}
                    >
                        <Tag tag={'span'} aria-hidden={true}>
                            {previousLabel}
                        </Tag>
                    </Tag>
                </Tag>
                {isMobile && (
                    <Tag tag={'li'} className={'fmc-pagination__item'}>
                        <Tag tag={'span'} className={'fmc-pagination__link'}>
                            {currrentPage} of {totalPage}
                        </Tag>
                    </Tag>
                )}
                <Tag tag={'li'} className={'fmc-pagination__item'}>
                    <Tag
                        tag={'span'}
                        rel={'next'}
                        href={'#'}
                        title={'Next Page'}
                        className={clsx(ctaNextClasses)}
                        disabled={currrentPage === totalPage}
                        onClick={moveToNext}
                    >
                        <Tag tag={'span'} aria-hidden={true}>
                            {nextLabel}
                        </Tag>
                    </Tag>
                </Tag>
            </Tag>
        </Tag>
    );
};

export default Pagination;
