import React, { useContext } from 'react';
import { Row, Tag } from '../../components/components';
import { WindowWidthContext } from '../../contexts/WindowWidthContext';

interface InfoListProps {
    title?: string;
    list: Array<string>;
    splitColumn?: boolean;
    extras?: any
}

const InfoList = (props: InfoListProps) => {
    const { windowWidth, setWindowWidth } = useContext(WindowWidthContext);
    const { list, title, splitColumn = false, extras = {} } = props;
    const { courtesyDeliveryName, courtesyDeliveryPhone } = extras ;
    const columns = windowWidth >= 1024 ? '1fr 1fr' : '1fr';
    const styles = splitColumn ? { display: 'grid', gridTemplateColumns: columns, paddingLeft: '2rem' } : { paddingLeft: '2rem' };
    
    return (
        <>
            {title && (
                <Row padding={{ dir: 'b', amt: 2 }}>
                    <Tag
                        tag={'h6'}
                        className={'fds-color--primary fmc-type--heading6'}
                    >
                        {title}:
                    </Tag>
                </Row>
            )}
            <div style={styles}>
                {list?.map((info, idx) => {
                    if (!info) return <></>
                    return (
                        <React.Fragment key={idx}>
                            <Row>
                                <Tag
                                    tag={'p'}
                                    className={'fds-type--body1 fds-color--gray3 fds-list-square'}
                                >
                                    {info ?? 'NA'}
                                </Tag>
                            </Row>
                            {info === 'COURTESY DELIVERY SERVICE' && (
                                <Row>
                                    <Tag
                                        tag={'span'}
                                        className={'fds-type--body1 fds-color--gray3'}
                                    >
                                        {courtesyDeliveryName}{' - '}
                                    </Tag>
                                    <Tag
                                        tag={'a'}
                                        className={'fds-type--body1 fds-color--gray3'}
                                        href={`mailto:${courtesyDeliveryPhone}`}
                                    >
                                        {' '}{courtesyDeliveryPhone}
                                    </Tag>
                                </Row>
                            )}
                        </React.Fragment>
                    )
                })}
            </div>
        </>
    )
}

export default InfoList;