import React, { FC, ReactNode } from 'react';
import { Tag } from '../';
import clsx from 'clsx';

interface ChipProp {
    label: string;
    value: string;
    disabled?: boolean;
    scrollable?: boolean;
    dot?: string;
    className?: string;
    callback?: () => void;
}

interface ChipsProps {
    chips: ChipProp[];
    onClick?: (label: string) => void;
    children?: ReactNode | JSX.Element | JSX.Element[] | string | string[];
}
const FilterChips: FC<ChipsProps> = (props) => {
    const { chips, children, onClick } = props;

    return (
        <Tag className={'fmc-filter-chips'}>
            {chips &&
                chips.map(
                    ({ label, value, disabled = false, scrollable = false, dot, callback, className }) => {
                        const classes = [
                            'fmc-filter-chips__chip',
                            { 'fmc-filter-chips__chip--scrollable ': scrollable },
                            { 'fmc-filter-chips__chip--disabled': disabled },
                        ];
                        const cb = onClick || callback || (() => {})
                        return (
                            <Tag className={clsx(classes, className)} key={Math.random()}>
                                {dot && <Tag className={clsx(['fmc-filter-chips__dot ', dot])} />}
                                {label}
                                <Tag
                                    tag={'button'}
                                    aria-label={'clear'}
                                    className={
                                        'fds-icon fds-icon--24 fds-icon--offset-right-sm fds-font--ford-icons__clear'
                                    }
                                    disabled={disabled}
                                    onClick={() => cb(value)}
                                />
                            </Tag>
                        );
                    },
                )}
            {children}
        </Tag>
    );
};

export default FilterChips;
