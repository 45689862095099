import React, { FC, ReactNode, forwardRef } from 'react';
import clsx from 'clsx';
import { isObject, isDefined } from '../../utils/Helpers';
import Tag from '../Tag/Tag';

const columnWidths = ['xs', 'sm', 'md', 'lg', 'xl'];

interface ColumnSize {
    size: boolean | number | string;
}

interface ColumnShape {
    size: string;
    offset?: string | number;
}

type SpacingType = string | number | boolean | object | object[];
interface ColumnProps {
    tag?: string;
    type?: string;
    src?: string;
    href?: string;
    alt?: string;
    id?: string;
    className?: string;
    style?: object;
    margin?: SpacingType;
    padding?: SpacingType;
    hide?: any;
    xs?: ColumnSize | number | string | boolean;
    sm?: ColumnSize | number | string | boolean;
    md?: ColumnSize | number | string | boolean;
    lg?: ColumnSize | number | string | boolean;
    xl?: ColumnSize | number | string | boolean;
    auto?: boolean;
    variable?: boolean;
    widths?: string[];
    children?: ReactNode | JSX.Element | JSX.Element[] | string | string[];
    onFocus?: any;
}

const getColumnSize = (colWidth, colSize) => {
    const _colWidth =
        isDefined(colWidth) && typeof colWidth !== 'boolean' ? `fds-${colWidth}:` : '';
    if (colSize === true || colSize === '') {
        return `fds-flex__col-${colSize}`;
    } else if (colSize === 'variable') {
        return `${_colWidth}fds-flex__col-variable`;
    } else if (colSize === 'auto') {
        return `${_colWidth}fds-flex__col-auto`;
    }
    return `${_colWidth}fds-flex__col-${colSize}`;
};

const getColumnOffsetSize = (offsetWidth, offsetSize) => {
    const _offsetWidth = isDefined(offsetWidth) ? `fds-${offsetWidth}:` : '';
    if (!Number.isInteger(offsetSize)) {
        return '';
    }
    return `${_offsetWidth}fds-flex__col-offset-${offsetSize}`;
};

const Col: FC<ColumnProps> = forwardRef((props, ref) => {
    const {
        tag = 'div',
        auto = false,
        variable = false,
        widths = columnWidths,
        className,
        ...attributes
    } = props;

    const colClasses: object[] = [
        { 'fds-flex__col-variable': variable },
        { 'fds-flex__col-auto': auto },
    ];

    widths.forEach((colWidth, idx) => {
        const columnProp: ColumnShape = isDefined(attributes[colWidth]) ? attributes[colWidth] : null;

        if (!isDefined(columnProp) || (typeof columnProp === 'boolean' && !columnProp)) {
            return;
        }

        delete attributes[colWidth];

        if (isObject(columnProp)) {
            const colClass = getColumnSize(colWidth, columnProp.size);
            const colOffset = getColumnOffsetSize(colWidth, columnProp.offset);

            colClasses.push({
                [`${colClass}`]: isDefined(colWidth),
                [`${colOffset}`]: isDefined(colWidth) && isDefined(columnProp.offset),
            });
        } else {
            const colClass = getColumnSize(colWidth, columnProp);
            colClasses.push({
                [`${colClass}`]: true,
            });
        }
    });

    if (!colClasses.length) {
        colClasses.push({
            'fds-flex__col': true,
        });
    }

    const ColumnClassName = clsx(className, colClasses);

    return <Tag tag={tag} ref={ref} {...attributes} className={ColumnClassName} />;
});

export default Col;
