import React, { FC, Fragment, ReactNode } from 'react';
import clsx from 'clsx';
import { Tag, Grid, Inner, Cell, Button, Row, Container, Col } from '../';
import { Link } from 'react-router-dom';

interface NavItem {
	href?: string;
	label: string;
	rightIcon?: string;
	childNavItem?: boolean;
}

interface FooterProps {
	tag?: string;
	className?: string;
	background?: 'light' | 'dark' | 'none';
	siteNavItems: NavItem[];
	children?: ReactNode | JSX.Element | JSX.Element[] | string | string[];
}

const Footer: FC<FooterProps> = (props) => {
	const {
		tag = 'footer',
		className,
		siteNavItems,
		background = 'none',
		...attributes
	} = props;

	const classes = [
		{ 'fds-color__bg--primary': background === 'none' },
		{ 'fds-bg--dark': background === 'dark' },
		{ 'fds-bg--light': background === 'light' },
	];

	const ClassName = clsx(classes, className);

	const CopyClasses = [
		'fds-wrap--nowrap',
		'fmc-type--body2',
		'fds-stretch--normal',
		'fds-weight--normal',
		{ 'fds-color--primary': background !== 'dark' },
		{ 'fds-color--white': background === 'dark' || background === 'none' },
	];

	const handleCookieSetttings = (e, label) => {
		// if (label === 'Cookie Settings') {
		//     e.preventDefault();
		//     e.stopPropagation();
		//     console.log('On Click');
		//     (window as any)?.OptanonWrapper();
		// }
	};

	return (
		<Grid tag={tag} className={ClassName}>
			<Inner
				padding={[
					{ dir: 't', amt: 1 },
					{ dir: 'b', amt: 3 },
				]}
			>
				<Cell size={12}>
					<Container>
						<Row justify={'center'}>
							<Col xs={12} lg={12} xl={10}>
								<Row
									justify={'center'}
									padding={{ dir: 'y', amt: 1 }}
								>
									<Col xs={12} md={10} lg={10} xl={'auto'}>
										<Row space={'evenly'}>
											<Col xs={12} lg={2} padding={0}>
												<Link
													to={'/'}
													aria-label={
														'Link to Home Page'
													}
												>
													<Tag
														tag={'img'}
														className={'fds-img'}
														src={
															background ===
																'dark' ||
															background ===
																'none'
																? '/assets/images/ford_cvc-logo-white.png'
																: '/assets/images/ford_cvc-logo.png'
														}
														alt={'Logo'}
														style={{
															maxWidth: 240,
														}}
													/>
												</Link>
											</Col>
											{siteNavItems.map(
												(
													{ label, href },
													idx,
													{ length }
												) => {
													const btnClassName =
														label ===
														'Cookie Settings'
															? 'optanon-toggle-display'
															: '';
													return (
														<Fragment
															key={Math.random()}
														>
															<Col
																xs={12}
																md={'auto'}
																lg={'auto'}
																className="fds-xs:fds-p--b-3 fds-xs:fds-p--x-0 fds-md:fds-p--y-0"
															>
																<Row
																	className={
																		'h-100 fds-flex__items--center'
																	}
																>
																	<Col
																		xs={
																			'auto'
																		}
																		className={
																			idx +
																				1 !==
																			length
																				? 'fmc-divider--vertical fmc-divider--right--hide-lg fmc-divider--white fds-flex fds-flex__justify--center'
																				: 'fds-flex fds-flex__justify--center'
																		}
																	>
																		<Button
																			tag={
																				'a'
																			}
																			target="_blank"
																			href={
																				href
																			}
																			textButton
																			disableHref={
																				label ===
																				'Cookie Settings'
																			}
																			dark={
																				background ===
																				'dark'
																			}
																			className={clsx(
																				CopyClasses
																			)}
																			buttonClassName={
																				btnClassName
																			}
																			aria-haspopup={
																				btnClassName &&
																				'true'
																			}
																		>
																			{
																				label
																			}
																		</Button>
																	</Col>
																</Row>
															</Col>
														</Fragment>
													);
												}
											)}
										</Row>
										<Row
											justify={'center'}
											padding={{ dir: 'y', amt: 1 }}
										>
											<Col
												xs={12}
												className={'fds-align--center'}
											>
												<Button
													tag={'a'}
													href={'//www.ford.com'}
													textButton
													className={clsx(
														CopyClasses
													)}
												>
													© 2024 Ford Motor Company
												</Button>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</Cell>
			</Inner>
		</Grid>
	);
};

export default Footer;
