import { DealerDataProps, FilterCategoryType } from "./types";

export const filterCategories: FilterCategoryType[] = [
    'vehiclesServiced',
    'advancedFuelSystems',
    'dealershipAvailability',
    'otherServices'
]

const dealerSampleDataCore = {
    hoursOfOperations: [
        {
            dayOfWeek: [
                "Monday",
                "Tuesday",
                "Thursday",
                "Friday"
            ],
            opens: "07:00",
            closes: "20:00"
        },
        {
            dayOfWeek: [
                "Wednesday",
            ],
            opens: "09:00",
            closes: "20:00"
        },
        {
            dayOfWeek: [
                "Saturday"
            ],
            opens: "08:30",
            closes: "20:00"
        }
    ],
    bayDimensions: {
        openingHeight: "20",
        interiorBayHeight: "20",
        bayLength: "50",
        liftCapacity: "36"
    }
}

export const accordionButtons = [
    {
        label: 'Vehicles',
        id: 'CVCConsumerSiteDealerSearchFilterVehicles'
    },
    {
        label: 'Advanced Fuel Systems',
        id: 'CVCConsumerSiteDealerSearchFilterAdvancedFuelSystems'
    },
    {
        label: 'Dealership Availability',
        id: 'CVCConsumerSiteDealerSearchFilterDealershipAvailability'
    },
    {
        label: 'Other Dealership Services',
        id: 'CVCConsumerSiteDealerSearchFilterOtherDealershipServices'
    },

]

export const testFilterCategories = {
    vehiclesServiced: [
        "F-150 Pickup",
        "F-250/350/450 Pickup",
        "F-350 Chassis Cab",
        "F-450 Chassis Cab",
        "F-550 Chassis Cab",
        "F-650/F-750",
        "F-59"
    ],
    dealershipAvailability: [
        "F-150 Pickup",
        "F-250/350/450 Pickup",
        "F-350 Chassis Cab",
        "F-450 Chassis Cab"
    ],
    advancedFuelSystems: [
        "F-350 Chassis Cab",
        "F-450 Chassis Cab",
        "F-550 Chassis Cab",
        "E-Series Cutaway",
        "E-Series Stripped Chassis",
    ],
    otherServices: [
        "Bilingual",
        "Body Shop",
        "Courtesy Delivery Service",
        "DOT Inspection",
        "Ford Fleet Care",
        "Ford Telematics",
        "Shuttle Service",
        "Towing Service",
        "Wholesale parts sales",
        "WiFi"
    ]
};

export const dealerDetailInfoSample = {
    phone: "7349962300",
    website: "www.examplefordedealerwebsite.com",
    email: {
        main: "kbaranski@suburbancollection.com",
        service: "service@suburbancollection.com",
        parts: "parts@suburbancollection.com",
        sales: "sales@suburbancollection.com"
    },
    vehiclesServiced: [
        "F-150 Pickup",
        "F-250/350/450 Pickup",
        "F-350 Chassis Cab",
        "F-450 Chassis Cab",
        "F-550 Chassis Cab",
        "F-650/F-750",
        "F-59",
    ],
    dealershipAvailability: [
        "Transit Chassis Cab",
        "Transit Cutaway",
        "Transit Cargo Van",
        "F-450 Chassis Cab",
        "F-350 Chassis Cab",
        "E-Series Cutaway"
    ],
    otherServices: [
        "Bilingual",
        "Body Shop",
        "Courtesy Delivery Service",
        "DOT Inspection",
        "Ford Fleet Care",
        "Ford Telematics",
        "Fordparts.com Sponsor",
        "On-site loaner/rental vehicle",
        "Quicklane",
        "Shuttle Service",
        "Tires",
        "Towing Service",
        "Wholesale parts sales",
        "WiFi"
    ]
};

const vericleSeriesMap = {
    "F-150 Pickup": "F-Series",
    "F-250/350/450 Pickup": "F-Series",
    "F-350 Chassis Cab": "F-Series",
    "F-450 Chassis Cab": "F-Series",
    "F-550 Chassis Cab": "F-Series",
    "F-650/F-750": "F-Series",
    "F-59": "F-Series",
    "E-Series Cutaway": "E-Series Transit",
    "E-Series Stripped Chassis": "E-Series Transit",
}

export const cvcDealerFilters = {
    "vehiclesServiced": [
        "F-150 Pickup",
        "F-250/350/450 Pickup",
        "F-350 Chassis Cab",
        "F-450 Chassis Cab",
        "F-550 Chassis Cab",
        "F-650/F-750",
        "F-59",
        "Transit Chassis Cab",
        "Transit Cutaway",
        "Transit Cargo Van",
        "E-Series Cutaway",
        "E-Series Stripped Chassis",
        "Transit Connect",
        "Motorhome - CLASS A",
        "Motorhome - CLASS C",
        "Aerial Bucket",
        "Brush Truck",
        "Ambulance",
        "Fire Truck",
        "Tow Truck",
        "Dump Truck",
        "Bus",
        "Limousine",
        "Interceptor",
        "Battery Electric Vehicle",
        "Altech-Eco",
        "Roush Cleantech",
        "Westport",
        "IMPCO Automotive",
        "Landi/Rezno"
    ],
    "advancedFuelSystems": [
        "F-350 Chassis Cab",
        "F-450 Chassis Cab",
        "F-550 Chassis Cab",
        "E-Series Cutaway",
        "E-Series Stripped Chassis"
    ],
    "dealershipAvailability": [
        "F-150 Pickup",
        "F-250/350/450 Pickup",
        "F-350 Chassis Cab",
        "F-450 Chassis Cab",
        "Transit Chassis Cab",
        "Transit Cutaway",
        "Transit Cargo Van",
        "E-Series Cutaway"
    ],
    "otherServices": [
        "Bilingual",
        "Body Shop",
        "Courtesy Delivery Service",
        "DOT Inspection",
        "Ford Fleet Care",
        "Ford Telematics",
        "Shuttle Service",
        "Towing Service",
        "Wholesale parts sales",
        "WiFi",
        "Fordparts.com Sponsor",
        "On-site loaner/rental vehicle",
        "Quicklane",
        "Tires"
    ]
};

const dealerSampleDataItems = [
    {
        dealerID: "123452",
        name: "Suburban Ford of Sterling Heights",
        address: "40333 Van Dyke Ave",
        city: "Sterling Heights",
        state: "MI",
        zip: "48313",
        lat: 42.589880,
        lng: -83.031900,
        phone: "5862687500",
        website: "www.examplefordedealerwebsite.com",
        email: {
            main: "kbaranski@suburbancollection.com",
            service: "service@suburbancollection.com",
            parts: "parts@suburbancollection.com",
            sales: "sales@suburbancollection.com"
        },
        vehiclesServiced: [
            "F-150 Pickup",
            "F-250/350/450 Pickup",
            "F-350 Chassis Cab",
            "F-450 Chassis Cab",
            "F-550 Chassis Cab",
            "F-650/F-750",
            "F-59"
        ],
        dealershipAvailability: [
            "F-150 Pickup",
            "F-250/350/450 Pickup",
            "F-350 Chassis Cab",
            "F-450 Chassis Cab"
        ],
        advancedFuelSystems: [
            "F-350 Chassis Cab",
            "F-450 Chassis Cab",
            "F-550 Chassis Cab",
            "E-Series Cutaway",
            "E-Series Stripped Chassis",
        ],
        otherServices: [
            "Bilingual",
            "Body Shop",
            "Courtesy Delivery Service",
            "DOT Inspection",
            "Ford Fleet Care",
            "Ford Telematics",
            "Shuttle Service",
            "Towing Service",
            "Wholesale parts sales",
            "WiFi"
        ]

    },
    {
        dealerID: "123453",
        name: "Varsity Ford 1",
        address: "1000 Jackson Road",
        city: "Ann Arbor",
        state: "MI",
        zip: "48103",
        lat: 42.288530,
        lng: -83.837770,
        phone: "7349962300",
        website: "www.examplefordedealerwebsite.com",
        email: {
            main: "kbaranski@suburbancollection.com",
            service: "service@suburbancollection.com",
            parts: "parts@suburbancollection.com",
            sales: "sales@suburbancollection.com"
        },
        vehiclesServiced: [
            "F-150 Pickup",
            "F-250/350/450 Pickup",
            "F-350 Chassis Cab",
            "F-450 Chassis Cab",
            "F-550 Chassis Cab",
            "F-650/F-750",
            "F-59",
        ],
        dealershipAvailability: [
            "Transit Chassis Cab",
            "Transit Cutaway",
            "Transit Cargo Van",
            "F-450 Chassis Cab",
            "F-350 Chassis Cab",
            "E-Series Cutaway"
        ],
        otherServices: [
            "Bilingual",
            "Body Shop",
            "Courtesy Delivery Service",
            "DOT Inspection",
            "Ford Fleet Care",
            "Ford Telematics",
            "Fordparts.com Sponsor",
            "On-site loaner/rental vehicle",
            "Quicklane",
            "Shuttle Service",
            "Tires",
            "Towing Service",
            "Wholesale parts sales",
            "WiFi"
        ]
    },
    {
        dealerID: "123454",
        name: "Varsity Ford 2",
        address: "2000 Kalamazoo Road",
        city: "Ann Arbor",
        state: "MI",
        zip: "48103",
        lat: 42.277250,
        lng: -84.304990,
        phone: "7349962300",
        website: "www.examplefordedealerwebsite.com",
        email: {
            main: "kbaranski@suburbancollection.com",
            service: "service@suburbancollection.com",
            parts: "parts@suburbancollection.com",
            sales: "sales@suburbancollection.com"
        },
        vehiclesServiced: [
            "F-150 Pickup",
            "F-250/350/450 Pickup",
            "F-350 Chassis Cab",
            "F-450 Chassis Cab",
            "F-550 Chassis Cab",
            "F-650/F-750",
            "F-59",
            "Transit Chassis Cab",
            "Transit Cutaway",
            "Transit Cargo Van",
            "E-Series Cutaway",
            "E-Series Stripped Chassis",
            "Transit Connect",
            "Motorhome - CLASS A",
            "Motorhome - CLASS C",
            "Aerial Bucket",
            "Brush Truck",
            "Ambulance",
            "Fire Truck",
            "Tow Truck"
        ],
        dealershipAvailability: [
            "Transit Chassis Cab",
            "F-450 Chassis Cab",
            "F-350 Chassis Cab",
            "E-Series Cutaway"
        ],
        otherServices: [
            "Bilingual",
            "Body Shop",
            "Courtesy Delivery Service",
            "DOT Inspection",
            "Ford Fleet Care",
            "Ford Telematics",
            "Fordparts.com Sponsor",
            "On-site loaner/rental vehicle",
            "Quicklane",
            "Shuttle Service",
            "Tires",
            "Towing Service",
            "Wholesale parts sales",
            "WiFi"
        ]
    },
    {
        dealerID: "123455",
        name: "Varsity Ford 3",
        address: "3000 Fuller Road",
        city: "Ann Arbor",
        state: "MI",
        zip: "48103",
        lat: 42.276920,
        lng: -83.700090,
        phone: "7349962300",
        website: "www.examplefordedealerwebsite.com",
        email: {
            main: "kbaranski@suburbancollection.com",
            service: "service@suburbancollection.com",
            parts: "parts@suburbancollection.com",
            sales: "sales@suburbancollection.com"
        },
        vehiclesServiced: [
            "F-150 Pickup",
            "F-250/350/450 Pickup",
            "F-350 Chassis Cab",
            "F-450 Chassis Cab",
            "F-550 Chassis Cab",
            "F-650/F-750",
            "F-59",
            "Transit Chassis Cab",
            "Transit Cutaway",
            "Transit Cargo Van",
            "E-Series Cutaway",
            "E-Series Stripped Chassis",
            "Transit Connect",
            "Motorhome - CLASS A",
            "Motorhome - CLASS C",
            "Aerial Bucket",
            "Brush Truck",
            "Ambulance",
            "Fire Truck",
            "Tow Truck",
            "Dump Truck",
            "Bus",
            "Limousine",
            "Interceptor",
            "Battery Electric Vehicle"
        ],
        otherServices: [
            "Bilingual",
            "Body Shop",
            "Courtesy Delivery Service",
            "DOT Inspection",
            "Ford Fleet Care",
            "Ford Telematics",
            "Fordparts.com Sponsor",
            "On-site loaner/rental vehicle",
            "Quicklane",
            "Shuttle Service",
            "Tires",
            "Towing Service",
            "Wholesale parts sales",
            "WiFi"
        ]
    },
    {
        dealerID: "123456",
        name: "Varsity Ford 4",
        address: "4000 Washtenaw Ave",
        city: "Ann Arbor",
        state: "MI",
        zip: "48103",
        lat: 42.2536714,
        lng: -83.6802354,
        phone: "7349962300",
        website: "www.examplefordedealerwebsite.com",
        email: {
            main: "kbaranski@suburbancollection.com",
            service: "service@suburbancollection.com",
            parts: "parts@suburbancollection.com",
            sales: "sales@suburbancollection.com"
        },
        vehiclesServiced: [
            "F-150 Pickup",
            "F-250/350/450 Pickup",
            "F-350 Chassis Cab",
            "F-450 Chassis Cab",
            "F-550 Chassis Cab",
            "F-650/F-750",
            "F-59",
            "Transit Chassis Cab",
            "Transit Cutaway",
            "Transit Cargo Van",
            "E-Series Cutaway",
            "E-Series Stripped Chassis",
            "Transit Connect",
            "Motorhome - CLASS A",
            "Motorhome - CLASS C",
            "Aerial Bucket",
            "Brush Truck",
            "Ambulance",
            "Fire Truck",
            "Tow Truck",
            "Dump Truck",
            "Bus",
            "Limousine",
            "Interceptor",
            "Battery Electric Vehicle",
            "Altech-Eco",
            "Roush Cleantech",
            "Westport",
            "IMPCO Automotive",
            "Landi/Rezno"
        ],
        otherServices: [
            "Bilingual",
            "Body Shop",
            "Courtesy Delivery Service",
            "DOT Inspection",
            "Ford Fleet Care",
            "Ford Telematics",
            "Fordparts.com Sponsor",
            "On-site loaner/rental vehicle",
            "Quicklane",
            "Shuttle Service",
            "Tires",
            "Towing Service",
            "Wholesale parts sales",
            "WiFi"
        ]
    }
]

export const _dealerSampleData: DealerDataProps[] = dealerSampleDataItems.map((item) => ({ ...dealerSampleDataCore, ...item }))
export const _dealerSampleDetailedData = _dealerSampleData[0];

// export const _mergeFilterResults = (array: Dealer[], filter: FilterCategoryType) => {
//     return array
//         .filter((result: Dealer) => isDefined(result[filter]))
//         .map((result) => result[filter])
//         .reduce((previous, current) => {
//             return [...new Set([...(previous ? previous : []), ...(current ? current : [])])]
//         }, [])
// }

export const DelerMilesFromOptions = [
    { label: "10 miles", value: "10"},
    { label: "25 miles", value: "25"},
    { label: "50 miles", value: "50"},
];

export const DEALER_ITEMS_PER_PAGE = 10;