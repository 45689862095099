import { Dealer, DealerFilter } from "../../provider/DealerProvider/types";

export interface DealerViewProps {
    activeView?: string;
    dealers: Dealer[];
}

export interface HourOfOperationProp {
    closes: string,
    dayOfWeek: string[]
    opens: string
}

export interface DealerDataProps {
    vehiclesServiced?: string[],
    advancedFuelSystems?: string[],
    dealershipAvailability?: string[],
    otherServices?: string[],
    hoursOfOperations: HourOfOperationProp[],
    bayDimensions: object,
    dealerID: string,
    name: string,
    address: string,
    city: string,
    state: string,
    zip: string,
    phone: string,
    website: string,
    email: object
}

export interface DealerSearchFormProps {
    minimizedLayout?: boolean;
    onFormSubmit: (formData: any) => void;
}

export interface DealerListProps {
    dealers: Dealer[];
}

export interface DealerCardProps extends Dealer {

}

export interface DealerFilterProps {
    filters: DealerFilter[];
    filterCodeMap: {[key: string]: string} | null;
    onFilterChange: any;
    selectedFilters: Array<string>;
    onFilterApply: any;
}

export type FilterCategoryType = 'vehiclesServiced' | 'advancedFuelSystems' | 'dealershipAvailability' | 'otherServices'

export enum DealerSearchType {
    Name = 'name',
    ZipCode = 'zip',
    CurrentLocation = 'location',
    State = 'state',
};
