import React, { FC, ReactNode, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Tag, Grid, Inner, Cell, Row, Col, Container } from '..';
import { getWindowDimensions } from '../../utils/Helpers';

type SpacingType = string | boolean | object | object[];

interface SectionProps {
    tag?: string;
    className?: string;
    id?: string;
    dark?: boolean;
    light?: boolean;
    padding?: SpacingType;
    margin?: SpacingType;
    innerAttributes?: object;
    style?: object;
    children?: ReactNode | JSX.Element | JSX.Element[] | string | string[];
}

const Section: FC<SectionProps> = (props) => {
    const {
        tag = 'section',
        light = false,
        dark = false,
        className,
        children,
        innerAttributes,
        ...attributes
    } = props;

    const classes = [
        'om-fmc__section',
        { 'om-fmc__section--light': light && !dark },
        { 'om-fmc__section--dark': !light && dark },
    ];

    const [windowWidth, setWindowWidth] = useState(getWindowDimensions().width);

    useEffect(() => {
        const handleResize = function handleResize() {
            const { width } = getWindowDimensions();
            setWindowWidth(width);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [windowWidth]);


    const ClassName = clsx(className, classes);
    return (
        <Grid tag={tag} className={ClassName} {...attributes}>
            <Inner padding={{ dir: 'y', amt: 3 }} {...innerAttributes}>
                <Cell size={12}>
                    <Container className={windowWidth < 1024 ? 'fds-p--x-0' : ''}>
                        <Row justify={'center'}>
                            <Col xs={12} lg={12} xl={10}>
                                {children}
                            </Col>
                        </Row>
                    </Container>
                </Cell>
            </Inner>
        </Grid>
    );
};

export default Section;
