import React, { FC, Fragment, useContext } from 'react';

import { Accordion, AccordionPanel, Checkbox } from '@avllc/cxc-components-web';
import { generatePath, useNavigate } from 'react-router-dom';
import { Button, Tag, Row, Col } from '../../components/components/';
import { USStatesChunked } from '../../constants/appConstants';
import { ROUTE_DEALERS_BY_STATE } from '../../router';
import { WindowWidthContext } from '../../contexts/WindowWidthContext';

interface StateListProps {
}
const StateList: FC = (props: StateListProps) => {
    const navigate = useNavigate();
    const { windowWidth, setWindowWidth } = useContext(WindowWidthContext);

    const handleStateSelect = (state) => {
        console.log(state);
        navigate(generatePath(ROUTE_DEALERS_BY_STATE, { stateCode: state?.code }))
    }

    const getAccordionSubtitle = (idx) => {
        if (idx === 0) return 'A-I';
        if (idx === 1) return 'K-M';
        if (idx === 2) return 'N-R';
        if (idx === 3) return 'S-W';
        return;
    };

    return (
        <Fragment>
            <Row
                padding={[
                    { dir: 'b', amt: 2 },
                    { dir: 'b', amt: 3 },
                ]}
            >
                <Col xs={12} lg={'variable'}>
                    <Tag tag={'h6'} className={'fmc-type--heading3 fds-type--primary'}>
                        Ford Pro Commercial Vehicle Centers by state
                    </Tag>
                </Col>
            </Row>
            <Row tag={'ul'} className={'fmc-cards'}>
                {windowWidth >= 1024 && (
                    <>
                        {Object.keys(USStatesChunked)?.map((stateKey, idx) => (
                            <Col xs={12} sm={6} md={3} key={idx}>
                                <Row>
                                    <Col xs={12}>
                                        <Tag tag={'span'} className={'fmc-type--heading6'}>
                                            {stateKey}
                                        </Tag>
                                    </Col>
                                </Row>
                                <Row>
                                    {USStatesChunked &&
                                        USStatesChunked[stateKey].map((state, _idx) => {
                                            return (
                                                <Fragment key={_idx}>
                                                    <Col xs={12} padding={{ dir: 't', amt: 1 }}>
                                                        <Button
                                                            textButton
                                                            className={'fds-decoration--underline'}
                                                            onClick={() => handleStateSelect(state)}
                                                            buttonClassName='fds-align--left'
                                                        >
                                                            {state?.name}
                                                        </Button>
                                                    </Col>
                                                </Fragment>
                                            );
                                        })}
                                </Row>
                            </Col>
                        ))}
                    </>
                )}
                {windowWidth < 1024 && (
                    <Col xs={12} padding={{ dir: 'b', amt: 2 }}>
                        <Row className={'fds-app'}>
                            <Accordion background="dark" foreground="light">
                                {Object.keys(USStatesChunked).map((stateKey, idx) => {
                                    const stateChunks = (USStatesChunked as any)?.[stateKey];
                                    return (
                                        <Fragment key={idx}>
                                            <AccordionPanel
                                                index={idx}
                                                titleText={stateKey}
                                                className={
                                                    'cvc-accordion-panel accordion-panel-color-fill'
                                                }
                                            >
                                                <>
                                                    {stateChunks?.map((state: any) => {
                                                        return (
                                                            <Col
                                                                xs={12}
                                                                key={Math.random()}
                                                                className={
                                                                    'fds-p--x-0 label-primary-color'
                                                                }
                                                            >
                                                                <Tag
                                                                    tag={'p'}
                                                                    onClick={() => handleStateSelect(state)}
                                                                    className={
                                                                        'fmc-type--heading4 fds-color--primary cvc-accordion-panel fds-p--b-2 fds-m--b-4'
                                                                    }
                                                                >
                                                                    {state?.name}
                                                                </Tag>
                                                            </Col>
                                                        );
                                                    })}
                                                </>
                                            </AccordionPanel>
                                        </Fragment>
                                    );
                                })}
                            </Accordion>
                        </Row>
                    </Col>
                )}
                <Col xs={12} padding={[{ dir: 't', amt: 5 }]} style={{ paddingTop: '8rem'}}>
                    <Row>
                        <Tag tag={'span'} style={{ flex:1, color: '#4d4d4d', alignItems: 'center', textAlign: 'center' }} className={'fmc-type--content3 fds-weight--medium'}>
                            Need assistance finding a dealer? Contact the Ford Pro
                            Commercial Vehicle Center Program <br />
                            Headquarters at 1-888-276-4088 or{' '}
                            <Tag tag={'a'} href={'mailto:CVCHQ@FordProgramHQ.com'}>
                                CVCHQ@FordProgramHQ.com
                            </Tag>
                        </Tag>
                    </Row>
                </Col>
            </Row>
        </Fragment>
    );
};

export default StateList;
