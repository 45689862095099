import React, { FC, forwardRef, Fragment, ReactNode } from 'react';
import clsx from 'clsx';
import ScrollContainer from 'react-indiana-drag-scroll';
import { isDefined } from '../../utils/Helpers';
import Tag from '../Tag/Tag';

const rowWidths = ['xs', 'sm', 'md', 'lg', 'xl'];

interface RowShape {
    gutter?: boolean;
    gapless?: boolean;
    narrow?: boolean;
    wide?: boolean;
    wrap?: 'nowrap' | 'wrap';
    justify?: 'start' | 'center' | 'end' | 'none';
    space?: 'around' | 'between' | 'evenly' | 'none';
}

type SpacingType = string | number | boolean | object | object[];
interface RowProps {
    tag?: string;
    className?: string;
    style?: object;
    margin?: SpacingType;
    padding?: SpacingType;
    xs?: RowShape | string | boolean | number;
    sm?: RowShape | string | boolean;
    md?: RowShape | string | boolean;
    lg?: RowShape | string | boolean;
    xl?: RowShape | string | boolean;
    justify?: 'start' | 'center' | 'end' | 'none';
    space?: 'around' | 'between' | 'evenly' | 'none';
    wrap?: any | 'nowrap' | 'wrap';
    wide?: boolean;
    narrow?: boolean;
    gapless?: boolean;
    reverse?: boolean;
    gutter?: boolean;
    widths?: string[];
    hide?: any;
    children?: ReactNode | JSX.Element | JSX.Element[] | string | string[];
    onClick?: any;
    onFocus?: any;
}

const JustifyClasses = {
    start: 'fds-flex__justify--start',
    center: 'fds-flex__justify--center',
    end: 'fds-flex__justify--end',
    none: false,
};

const SpacingClasses = {
    around: 'fds-flex__justify--around',
    between: 'fds-flex__justify--between',
    evenly: 'fds-flex__justify--evenly',
    none: false,
};

const WrapClasses = {
    wrap: 'fds-flex-wrap--wrap',
    nowrap: 'fds-flex-wrap--nowrap',
    none: false,
};

const getRowSizes = (rowProp, rowSize) => {
    const { gutter, wide, narrow, gapless, wrap, justify } = rowProp;
    const _rowSize = isDefined(rowSize) ? `fds-${rowSize}:` : '';
    if (gutter && !gapless && !wide && !narrow && !wrap) {
        return `${_rowSize}fds-gutter--default`;
    } else if (gapless && gutter) {
        return `${_rowSize}fds-gutter--gapless`;
    } else if (wide && gutter) {
        return `${_rowSize}fds-gutter--wide`;
    } else if (narrow && gutter) {
        return `${_rowSize}fds-gutter--narrow`;
    } else if (justify) {
        return `${_rowSize}fds-flex__justify--${justify}`;
    } else if (wrap) {
        return `${_rowSize}fds-flex-wrap--${wrap}`;
    }

    return;
};

const Row: FC<RowProps> = forwardRef((props, ref) => {
    const {
        tag = 'div',
        widths = rowWidths,
        space = 'none',
        justify = 'none',
        reverse = false,
        className,
        gutter,
        narrow,
        wide,
        wrap,
        gapless,
        ...attributes
    } = props;

    const rowClasses = [
        { 'fds-gutter--default': gutter && !narrow && !wide && !gapless },
        { 'fds-gutter--gapless': gutter && gapless },
        { 'fds-gutter--narrow': gutter && narrow },
        { 'fds-gutter--wide': gutter && wide },
        { 'fds-flex-direction--row-reverse': reverse },
        SpacingClasses[space],
        JustifyClasses[justify],
        WrapClasses[wrap],
    ];

    widths.forEach((rowWidth) => {
        const rowProp = attributes[rowWidth];

        if (!rowProp) {
            return;
        }

        delete attributes[rowWidth];

        rowClasses.push(getRowSizes(rowProp, rowWidth));
    });

    const RowClassName = clsx('fds-flex__row', className, rowClasses);

    return (
        <Fragment>
            {RowClassName.indexOf('nowrap') > 0 && tag === 'div' ? (
                <ScrollContainer
                    tag={tag}
                    // @ts-ignore
                    ref={ref}
                    {...attributes}
                    vertical={false}
                    horizontal={true}
                    nativeMobileScroll={true}
                    className={RowClassName}
                />
            ) : (
                <Tag tag={tag} ref={ref} {...attributes} className={RowClassName} />
            )}
        </Fragment>
    );
});

export default Row;
