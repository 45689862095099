import React, { Fragment, useContext } from 'react';
import { Button, Col, Row, Tag } from '../../components/components';
import { Accordion, AccordionPanel, Checkbox } from '@avllc/cxc-components-web';
import { DealerFilterProps } from './types';
import { WindowWidthContext } from '../../contexts/WindowWidthContext';
import { capitalizeWords } from '../../components/utils/Helpers';
//import { DealerFilter } from '../../provider/DealerProvider/types';

const filterMainGroupMap = new Map([
	[
		'vehicles',
		{
			label: 'Vehicles',
			groupCodes: [
				['CDD_SLS_FS', 'CDD_SRVC_FS'],
				['CDD_SLS_ES', 'CDD_SRVC_ES'],
				['CDD_SLS_TC', 'CDD_SRVC_TC'],
				'CDD_SRVC_MH',
				'CDD_SRVC_SV',
			],
		},
	],
	[
		'advanced_fuel_systems',
		{ label: 'Advanced Fuel Systems', groupCodes: ['CDD_AFS'] },
	],
	[
		'dealership_availability',
		{ label: 'Dealership Availability', groupCodes: ['CDD_DA'] },
	],
	[
		'other_dealership_services',
		{ label: 'Other Dealership Services', groupCodes: ['CDD_ODS'] },
	],
]);

const FILTER_EXCLUDE_LIST = ['CDD_ODS9'];

const DealerFilter = (props: DealerFilterProps) => {
	const { windowWidth, setWindowWidth } = useContext(WindowWidthContext);
	const {
		filters,
		selectedFilters,
		onFilterChange,
		onFilterApply,
		filterCodeMap,
	} = props;

	return (
		<>
			{/* <Col xs={12} lg={3} padding={{ dir: 'b', amt: 2 }} hide={['sm', 'xs']}> */}
			<Col xs={12} padding={{ dir: 'b', amt: 2 }}>
				<Row className={'fds-app'}>
					<Accordion background={'dark'} foreground={'light'}>
						{Array.from(filterMainGroupMap.keys())?.map(
							(filterMGKey, idx) => {
								const {
									label = '',
									groupCodes: mainGroupCodes = [],
								} = filterMainGroupMap.get(filterMGKey) || {};
								return (
									<Fragment key={idx}>
										<AccordionPanel
											index={idx}
											titleText={label}
											className={
												'cvc-accordion-panel cvc-filter-accordion-panel accordion-panel-color-fill'
											}
										>
											<>
												{mainGroupCodes?.map(
													(
														mainGrpCode:
															| string
															| Array<string>,
														_idx: number
													) => {
														const grpCodeKey =
															Array.isArray(
																mainGrpCode
															)
																? mainGrpCode[1]
																: mainGrpCode;
														const groupName = (
															filterCodeMap as any
														)?.[grpCodeKey];
														const groupCodes =
															Array.isArray(
																mainGrpCode
															)
																? [
																		mainGrpCode[1],
																  ]
																: [mainGrpCode];
														const groupFilters =
															filters?.filter(
																(filter) =>
																	!FILTER_EXCLUDE_LIST?.includes(
																		filter?.dlrDirCode
																	) &&
																	groupCodes.includes(
																		filter.groupCode
																	)
															);
														groupFilters.sort(
															(a, b) => {
																if (
																	a.dlrDirname <
																	b.dlrDirname
																)
																	return -1;
																if (
																	b.dlrDirname <
																	a.dlrDirname
																)
																	return 1;
																return 0;
															}
														);
														return (
															<React.Fragment
																key={_idx}
															>
																{mainGroupCodes?.length >
																	1 && (
																	<Tag
																		tag={
																			'p'
																		}
																		className={
																			'fmc-type--heading4 fds-color--primary cvc-accordion-panel fmc-divider--bottom fds-p--b-2 fds-m--b-4'
																		}
																	>
																		{capitalizeWords(
																			groupName
																		)}
																	</Tag>
																)}
																{groupFilters?.map(
																	(
																		dlrFilter,
																		_nIdx
																	) => {
																		const {
																			dlrDirCode,
																			dlrDirname,
																		} =
																			dlrFilter ||
																			{};
																		const dirName =
																			capitalizeWords(
																				dlrDirname
																			);
																		return (
																			<React.Fragment
																				key={
																					_nIdx +
																					_idx
																				}
																			>
																				<Checkbox
																					value={
																						dlrDirCode
																					}
																					checked={selectedFilters?.includes(
																						dlrDirCode
																					)}
																					id={`CVCConsumerSiteDealerSearchFilter${dlrDirCode}`}
																					onChange={
																						onFilterChange
																					}
																				>
																					{
																						dirName
																					}
																				</Checkbox>
																			</React.Fragment>
																		);
																	}
																)}
															</React.Fragment>
														);
													}
												)}
											</>
										</AccordionPanel>
									</Fragment>
								);
							}
						)}
					</Accordion>
				</Row>
				<Row padding={{ dir: 't', amt: 3 }}>
					<Col xs={12} className="fds-flex fds-flex__justify--center">
						<Button rightIcon onClick={onFilterApply}>
							Apply Filters
						</Button>
					</Col>
				</Row>

				{windowWidth < 1024 && false && (
					<Row padding={{ dir: 't', amt: 3 }}>
						<Col
							xs={12}
							className="fds-flex fds-flex__justify--center"
						>
							<Tag
								tag="p"
								className={
									'fmc-type--body2 fds-color--gray3 cvc-crumb cvc-crumb-active'
								}
							>
								Show 12 Results
							</Tag>
						</Col>
					</Row>
				)}
			</Col>
		</>
	);
};

export default DealerFilter;
