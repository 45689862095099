import React, { useEffect, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Col, Row, Tag } from '../../components/components';
import clsx from 'clsx';

const ImageCarousel = (props) => {
    const { images, imagesPerSlide = 4 } = props;

    const [selectedIndex, setSelectedIndex] = useState(0);
    const pancakeCount = Math.ceil(images?.length / imagesPerSlide);

    const onChange = () => {

    }

    const onClickThumb = () => {

    }

    const onClickItem = () => {

    }

    const forwardSlide = () => {
        if (selectedIndex < pancakeCount - 1) setSelectedIndex(selectedIndex + 1)
    }

    const backwardSlide = () => {
        if (selectedIndex !== 0) setSelectedIndex(selectedIndex - 1)
    }
    
    return (
        <>
            <Carousel
                selectedItem={selectedIndex}
                autoPlay={false}
                showArrows={false}
                showStatus={false}
                showIndicators={false}
                onChange={onChange}
                onClickItem={onClickItem}
                onClickThumb={onClickThumb}
            >
                {Array.from(Array(pancakeCount)).map((_, idx) => {
                    const activeImages = images?.slice(idx * imagesPerSlide, imagesPerSlide * (idx + 1));
                    return (
                        <Row key={idx}>
                            {activeImages?.map((srcImg, _idx) => {
                                return (
                                    <Col key={_idx} xs={12 / imagesPerSlide}>
                                        <div>
                                            <Tag
                                                tag={'img'}
                                                className={'fds-img'}
                                                src={srcImg.data}
                                                alt={'Gallery'}
                                                style={{ height: 300, objectFit: 'fill' }}
                                            />
                                        </div>
                                    </Col>
                                )
                            })}
                        </Row>
                    )
                })}
            </Carousel>
            <div className="fds-carousel-indicator" id="FDSCarousel-indicator" data-slide-text="Slide" data-current-slide-text="You are currently on" data-last-slide-text="You are on the last" data-activate-slide-text="Click or press enter to view">
                <div className="fds-carousel-indicator-wrapper">
                    <button
                        onClick={backwardSlide}
                        className={clsx('fds-carousel-indicator__directional-button fds-carousel-indicator__directional-button--previous', { 'fds-carousel-indicator__button--disabled': selectedIndex === 0 })}
                        // aria-label="You are on the first Slide of 7"
                        // aria-disabled="true"
                    >
                        <span className="fds-carousel-indicator__directional-button__text"></span>
                    </button>
                    <div className="fds-carousel-indicator__pancake-pagination-wrapper">
                        {Array.from(Array(pancakeCount)).map((_, idx) => (
                            <div key={idx} className={clsx('fds-carousel-indicator-line', { 'fds-carousel-indicator-line__active': idx === selectedIndex })}></div>
                        ))}
                    </div>
                    <button
                        onClick={forwardSlide}
                        className={clsx('fds-carousel-indicator__directional-button fds-carousel-indicator__directional-button--next', { 'fds-carousel-indicator__button--disabled': selectedIndex === pancakeCount - 1 })}
                        // aria-label="Click or press enter to view Slide 2 of 7, Carousel slide: 02"
                    >
                        <span className="fds-carousel-indicator__directional-button__text"></span>
                    </button>
                </div>
            </div>
        </>
    )
}

export default ImageCarousel;
