import React, {
    FC,
    ReactNode,
    Fragment,
    useState,
    useCallback,
    memo,
    useEffect,
    useRef,
    useMemo
} from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import InfoWindow from './InfoWindow';

interface MarkerProps {
    lat: number;
    lng: number;
    extras?: any;
}

interface MapProps {
    markers: MarkerProps[];
    zoom?: number;
    activeLocation: MarkerProps;
    activeLocationInfo: { title: string, description: string, lat: number, lng: number }
    onDragStart?: (evt) => void;
    children?: ReactNode | JSX.Element | JSX.Element[] | string | string[];
}

// const markerIcon = {
//     path: "M41.9394 113.932C40.9954 113.932 40.472 113.311 40.275 113.077C40.1798 112.964 40.0517 112.806 39.8943 112.606C39.5726 112.199 39.1342 111.628 38.6267 110.956C37.3735 109.296 35.8225 107.181 34.2593 105.001C31.0595 100.538 26.1908 93.5534 21.1675 85.6235C15.6561 76.923 11.2527 69.1284 8.07963 62.4564C6.18558 58.4737 4.71608 54.8531 3.71192 51.6952C2.57597 48.1227 2 45.022 2 42.479C2 32.4474 6.25269 22.2525 13.6676 14.5084C17.3679 10.6438 21.6314 7.59219 26.3396 5.43817C31.3263 3.15679 36.5749 2.00002 41.9394 2.00002C47.304 2.00002 52.5526 3.15679 57.5392 5.43817C62.2475 7.59219 66.511 10.6438 70.2113 14.5084C77.6262 22.2525 81.8789 32.4474 81.8789 42.479C81.8789 45.022 81.3029 48.1227 80.167 51.6952C79.1628 54.8531 77.6933 58.4737 75.7993 62.4564C72.6262 69.1284 68.2228 76.923 62.7114 85.6235C57.6881 93.5534 52.8194 100.538 49.6196 105.001C48.0564 107.181 46.5054 109.296 45.2522 110.956C44.7447 111.628 44.3063 112.199 43.9846 112.606C43.8272 112.806 43.6991 112.964 43.6039 113.077C43.4068 113.311 42.8835 113.932 41.9394 113.932Z",
//     fillColor: "#0075B6",
//     fillOpacity: 0.75,
//     scale: 0.5,
//     strokeWeight: 2,
//     strokeColor: '#ffffff',
//     strokeOpacity: 1.0
// }

const Map: FC<MapProps> = (props) => {
    const { markers, children, zoom, activeLocation, activeLocationInfo, ...attributes } = props;

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    });

    const mapRef = useRef();

    const containerStyle = {
        width: '100%',
        height: '100%',
    };

    const [map, setMap] = useState(null);

    const onLoad = useCallback((map) => {
        setMap(map);
    }, null);

    const markBounds = () => {
        if (markers?.length === 0 || !map) return;
        const bounds = new window.google.maps.LatLngBounds();
        markers?.forEach(marker => {
            bounds.extend({
                lat: marker?.lat,
                lng: marker?.lng,
            });
        })
        map.fitBounds(bounds);
    }

    const handleZoomChange = () => {
        // console.log('Zoom Change')
    }

    useEffect(() => {
        markBounds()
    }, [map, markers])

    const mapOptions = useMemo(() => {
        if (!window.google) return {};
        const curLatLng = new window.google.maps.LatLng(activeLocation.lat, activeLocation.lng);
        return {
            zoom: zoom ?? 14,
            center: curLatLng,
            mapTypeId: window.google.maps.MapTypeId.ROADMAP
        }
    }, [window.google, zoom, activeLocation])

    const renderMap = () => {
        return (
            <GoogleMap
                options={mapOptions}
                onLoad={onLoad}
                onZoomChanged={handleZoomChange}
                mapContainerStyle={containerStyle}
            >
                <>
                    {markers?.map((marker, _idx) => (
                        <Marker
                            key={_idx}
                            icon={'/assets/images/pin-clear.png'}
                            position={marker}
                        />
                    ))}
                    <InfoWindow description={activeLocationInfo?.description} title={activeLocationInfo?.title} lat={activeLocationInfo?.lat} lng={activeLocationInfo?.lng} />
                </>
            </GoogleMap>
        )
    }

    return isLoaded ? renderMap() : <></>
};

export default memo(Map);
