import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import Tag from '../Tag/Tag';

type SpacingType = string | boolean | object | object[];

interface GridProps {
    tag?: string;
    className?: string;
    fixed?: boolean;
    padding?: SpacingType;
    margin?: SpacingType;
    align?: 'left' | 'right';
    widths?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    children: ReactNode | JSX.Element | JSX.Element[] | string | string[];
}

const defaultProps = {
    tag: 'div',
    fixed: false,
};

const Grid: FC<GridProps> = (props) => {
    const { tag, fixed, className, widths, align, children, ...attributes } = props;

    const classes = [
        'fds-layout-grid',
        { 'fds-layout-grid--fixed-column-width': fixed },
        { 'fds-layout-grid--align-left': align === 'left' },
        { 'fds-layout-grid--align-right': align === 'right' },
    ];

    const GridClassName = clsx(className, classes);

    return (
        <Tag tag={tag} {...attributes} className={GridClassName}>
            {children}
        </Tag>
    );
};

Grid.defaultProps = defaultProps;

export default Grid;
